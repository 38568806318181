import React from 'react'
import ReactModal from 'react-modal';
import { Modal } from 'react-bootstrap';
import { invisibleModalStyle, visibleModalStyle, modalStyles } from '../../styles/modalStyles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

let hideMe = false

const GlobalErrorModalView = (state) => {
    if (state.reqFailedModal) {
        let elems = document.getElementsByClassName("GlobalAPIErrorModal");
        
        if (elems.length > 0) {
           
           elems[0].style.display='visible'; 
        }
        
        return (
            <ReactModal
                
                isOpen={true}
                background={true}
                style={invisibleModalStyle}
                contentLabel="Verification"
            >
                <Modal.Body style={visibleModalStyle} className="GlobalAPIErrorModal"  onClick={(el) => {
                    el.currentTarget.style.display='none';
                }}>
                    <div className="modal-close-x"><HighlightOffIcon /></div>
                    <div style={modalStyles.invisibleClose} ></div>
                    <div style={modalStyles.levelMessage} className="modal-separator">Don't panic! Something went wrong. Please try again or refresh.</div>
                    <div style={{ textAlign: "center", marginTop: "15px" }}><InfoRoundedIcon style={{ color: "red" }} /></div>
                </Modal.Body>
            </ReactModal>
        )
    }
    return (null)
}

export default GlobalErrorModalView