import React from 'react';
import AriaModal from 'react-aria-modal';

export default class FailedContentModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
             show:false
        }
        this.handleClose = this.handleClose.bind(this);
     }

componentDidMount() {
    this.setState({show:true});
}

handleClose() {
    this.setState({show:false});
}

render(){
    return (
        <React.Fragment>
            {this.state.show && <AriaModal
                    titleText="Failed to save data"
                    onExit={this.handleClose}
                    verticallyCenter={true}
                    initialFocus="#yes-button"
                    getApplicationNode={this.getApplicationNode}
                    underlayStyle={{ paddingTop: '2em' }}>
                        <div className="aria-modal">
                            <header>
                                <h3 style={this.textDecoration}>
                                    Data not saved
                                </h3>
                            </header>
                            <div id="message-content">
                                <p tabIndex="0" id="message">Failed to save the data because of some offensive content.</p>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <div style={{display:"inline-block"}}>
                                    <button className="btn btn-secondary mt-3" onClick={this.handleClose} aria-label="Click button to close the dialog">Close</button>
                                </div>
                            </div>
                        </div>
                </AriaModal>}
        </React.Fragment>
    );
  }

}