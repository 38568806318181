import { connect } from 'react-redux'
import GlobalErrorModalView from './GlobalErrorModalView'
import PointsTotal from './PointsTotal'

const mapStateToProps = state => {
  if (state.populateStore && state.populateStore.reqFailedModal) {
    
    return ({
      reqFailedModal: (state.populateStore.reqFailedModal)
    })
  }
  // else {
  //   return {}
  // }
}

export default connect(mapStateToProps)(GlobalErrorModalView)


