import React from "react";
import { Modal } from "react-bootstrap";
import { FaFlag } from 'react-icons/fa';
import ApiClient from "../../api/ApiClient";
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class ReportButtonContainer extends React.Component {
    constructor(props){
        super(props);
        this.apiClient = new ApiClient();
        this.state={
            showPopup:false,
            showConfirmDialog:false,
            flagReason:'',
            flagType:'',
            userId:this.props.loggedInUserId,
            videoData:this.props.data
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    iconColor = {
        color:"black",
        cursor:"pointer"
    }
    paddingRadio = {
        paddingLeft:"8px"
    }

    onSubmit = async(event) => {
        event.preventDefault();
        // fyd post
        //await this.onFlagVideo();
    }
    onClose(){
        this.setState({showPopup:false});
    }
    onOpen(){
        this.setState({showPopup:true});
    }
    handleClose() {
        this.setState({ showConfirmDialog: false });
        this.props.skipToNewVideo();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
        [name]: value
        });
    }
    // onFlagVideo = async() => {
    //     var self = this;
    //     var sdata = ""
    //     var data = {
    //         userId: self.props.loggedInUserId, userVideoId: self.props.data._id,
    //         convertedUrl: self.props.data.convertedUrl, flagType: self.state.flagType, flagReason: self.state.flagReason 
    //         }; 
    //     try {
    //         sdata = data;
    //     }
    //     catch (e) {
    //     }
        //     TODO changes from post to postJSON
    //     var response = await this.apiClient.post("/flagVideo", sdata);
    //     if (response.status === 200){
    //         this.setState({showPopup:false, showConfirmDialog:true});
    //     }
    //     else {
    //         alert("Unable to flag video");
    //     }
    // };

    // disableButton = () => {
    //     if (this.props.data !== null){
    //     const userVideo = this.props.data;
    //     if (userVideo.user._id === this.props.loggedInUserId){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }
    // }
      
    textareaStyle = {
        width:"100%"
    };

    setFlagType = (event) => {
        var value = event.target.value;
        this.setState({flagType : value});
    }
    render() {
        return (null);
        //const showFlagIcon = this.disableButton() ? {display:'none'}:{};
        //const ContentHeader = this.props.data !== null ? this.props.data.modelVideo.name : "Text";
        return (
            <React.Fragment>
                <Modal show={this.state.showPopup} onHide={this.onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                           Report Content
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div onChange={this.setFlagType}>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="ChildEndangermentExploitation" />
                                    <span style={this.paddingRadio}>Child endangerment or exploitation</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="ContentInfringement" />
                                    <span style={this.paddingRadio}>Content infringement</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="Fraud" />
                                    <span style={this.paddingRadio}>Fraud</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="HateSpeech" />
                                    <span style={this.paddingRadio}>Hate speech</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="ImminentHarmToPersonsOrProperty" />
                                    <span style={this.paddingRadio}>Imminent harm To persons or property</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="OffensiveContent" />
                                    <span style={this.paddingRadio}>Offensive Content</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="Terrorism" />
                                    <span style={this.paddingRadio}>Terrorism</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="ThreatsCyberbullyingHarassment" />
                                    <span style={this.paddingRadio}>Threats, cyberbullying, or harassment</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="VirusSpywareMalware" />
                                    <span style={this.paddingRadio}>Virus, spyware, or malware</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="Other" />
                                    <span style={this.paddingRadio}>Other</span>
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" name="flagType" value="None" />
                                    <span style={this.paddingRadio}>No Category</span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <span>
                                Reason:
                            </span>
                            <div>
                                <textarea name="flagReason" value={this.state.flagReason} style={this.textareaStyle} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button disabled={!this.state.flagType} className="record-btn" onClick={this.onSubmit} aria-label="Click button to Send Reset link">Report</button>
                        <button className="record-btn" backgro onClick={this.onClose} aria-label="Click button to Send Reset link">Cancel</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmDialog} onHide={this.handleClose} backdrop="static" style={{top:"30%"}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Thanks for reporting!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Thank you for reporting your concern. The video will be reviewed.</Modal.Body>
                </Modal>
                <div onClick={this.onOpen} title="Flag/Report the video">
                    <FlagRoundedIcon style={{color: "#666666", cursor: "pointer"}}   />
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const ReportButton = connect(
    
    mapDispatchToProps
)(ReportButtonContainer);

export default ReportButton


