import MicrosoftAuthService from './MicrosoftAuthService';
import GoogleAuthService from './GoogleAuthService';
import FacebookAuthService from './FacebookAuthService';

export default class AuthService{
    constructor(){
        this.msAuthService = new MicrosoftAuthService();
        this.googleAuthService = new GoogleAuthService();
        this.fbAuthService = new FacebookAuthService();
    }
    isAuthenticatedUsingMS = () =>{
        var user = null;
        var msaccount = this.msAuthService.getMicrosoftAccount();
        if(msaccount !== null && msaccount !== undefined) {
            user = {
                    userName: msaccount.userName,
                    email:msaccount.userName,
                    name: msaccount.name,
                    userId:msaccount.accountIdentifier,
                    accountType: 'ms'
                }
        }
        return user;
    }
    isAuthenticated = async(apiClient) =>{
        var glAuth = this.googleAuthService;
        var bfAuth = this.fbAuthService;
        return new Promise( async function(resolve,reject){
            const glaccount = await glAuth.getGoogleAccount(apiClient);
            if(glaccount){
                resolve(glaccount);
                return;
            }
            bfAuth.getFacebookAccount().then((fbaccount) => {
                if(fbaccount){
                    var fbuser = {
                        userName: fbaccount.email,
                        email:fbaccount.email,
                        name: fbaccount.name,
                        userId:fbaccount.userID,
                        accountType: 'fb'
                    }
                    resolve(fbuser);
                    return;
                }
            });
            
        });
    }

    logOut = (accountType) =>{
        switch(accountType){
            case 'ms':{
                this.msAuthService.logOut();
                break;
            }
            case 'google':{
                this.googleAuthService.logOut();
                break;
            }
            case 'fb':{
                this.fbAuthService.logOut();
                break;
            }
        }
    }
}