import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Switch from "react-switch";
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Select from 'react-select';
import WikiRecorder from './WikiRecorder';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import ReactPlayer from 'react-player';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import RecordRTC from 'recordrtc';
import Loader from 'react-loader-spinner';
import "../../styles/video.css";
import coins from '../../resources/coins.png';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check, Play, ArrowLeft, ArrowRight, SignIn, SignOut, Thumbsdown } from '@githubprimer/octicons-react';
import { stopRecordingCallback, saveWikiRecording, blobToFile } from "../../services/video/saveRecording";
import { setPlayProgress, pausePlayProgress, stringTimeToSeconds } from "../../services/video/videoUtils";
import ApiClient from "../../api/ApiClient";
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { tempMirrorFirefox } from "../../utils/utilMethods";


class RecorderWikiLayerContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            upVotes: 0,
            player: null,
            mode: true,
            // Tracks when a video recording or playback ready
            isRecorderLoaded: false,
            videoPlayback: false,
            recordTransitionValue: false,
            selectedControlButtons: this.recordState,
            selectedControlsName: "record",
            downVotes: 0,
            currSentence: false,
            tempCount: 0,
            isPlaying: false,
            topicTextUserList: [],
            stayOpen: false,
            selectedOption: null,
            currentVideoTimeProgress: 0,
            videoDuration: null,
            isRecording: false
        }
        this.recorder = null;

        this.video = React.createRef();
        this.buttonSubmit = React.createRef();
    }


    playbackEnded() {
        this.props.playbackEnded(this.props.sentence);
    }

    componentDidMount() {
        this.props.defineRecorder(this.startStopRecording);
        this.cameraCleanup = this.props.history.listen((location, action) => {
            try {

                this.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });

            }
            catch (e) {

            }
        });

    }

    componentWillUnmount() {
        this.cameraCleanup();
    }

    selectStyles = {
        control: styles => ({ ...styles, width: "260px", minWidth: "260px" })
    }

    calculatePlayDuration(duration, currentTime) {
        const dur = (duration - currentTime) * 1000
        return dur;
    }

    findCorrectFlipIndex = () => {
        // Two modes for this index : Confirm Mode & Variants = 1, Record Mode = 0
        // Best way to disambiguate is number of controls
        let targetFlippableControlIndex = 0;
        if (this.state.selectedControlButtons.length > 1) {
            targetFlippableControlIndex = 1;
        }
        return targetFlippableControlIndex;
    }

    flipPlayPauseControls = (duration, currentTime) => {
        // Sometimes state changes rapidly, this is more robust
        try {
            let targetFlippableControlIndex = this.findCorrectFlipIndex();
            const rmb = [...this.state.selectedControlButtons];
            rmb[targetFlippableControlIndex].selectedIndex = 1;
            this.setState({ selectedControlButtons: rmb, isPlaying: true });
            const st = setTimeout(() => {
                try {
                    targetFlippableControlIndex = this.findCorrectFlipIndex();
                    const rmb = [...this.state.selectedControlButtons];
                    rmb[targetFlippableControlIndex].selectedIndex = 0;
                    this.setState({ selectedControlButtons: rmb, isPlaying: false });
                }
                catch (e) {

                }
            }, this.calculatePlayDuration(duration, currentTime));
            this.playPauseTimeout = st;
        }
        catch (e) {

        }
    }

    handleOverrideControls = async (duration, currentTime, isConfirmIncluded) => {
        this.overrideConfirmBtns(isConfirmIncluded);
        this.flipPlayPauseControls(duration, currentTime)

    }

    handleControls = async (duration, currentTime, isConfirmIncluded) => {
        this.tempSetRedoConfirmBtns(isConfirmIncluded);
        this.flipPlayPauseControls(duration, currentTime)
    }

    resetRecordState = async () => {
        // Reset default to play
        this.flipPauseButton(0, 0, false);
        await this.setState({
            upVotes: 0,
            player: null,
            currSentence: true,
            mode: true,
            // Tracks when a video recording or playback ready
            isRecorderLoaded: false,
            videoPlayback: false,
            recordTransitionValue: false,
            selectedControlButtons: this.recordState,
            selectedControlsName: "record",
            downVotes: 0,
            tempCount: 0,
            isPlaying: false,
            topicTextUserList: [],
            stayOpen: false,
            selectedOption: null,
            currentVideoTimeProgress: 0,
            preventAction: false,
            videoDuration: null,
            isRecording: false
        });
        // Prompt rapid state transition allowing downstream reset
        await this.setState({
            currSentence: false
        })
        await this.setState({ isRecording: false, recordingTime: 0 });
        clearInterval(this.rClock)
        this.setState({ currentVideoTimeProgress: 0 });
        clearTimeout(this.playPauseTimeout)
        clearInterval(this.progressBarInterval)
    }

    playPauseTimeout = null;
    progressBarInterval = null;

    recordMethods = {
        play: async () => {
            // Rare edge cases break this TODO resolve without try/catch
            try {
                await this.setState({ pauseProgressOn: false});
                const self = this
                clearTimeout(self.playPauseTimeout)
                // Account for strange video duration bugs

                let vidDuration = self.video.duration;

                if (self.video.duration === Infinity || isNaN(vidDuration) || self.video.duration < 1 || self.video.duration == Infinity) {
                    if (!self.props.overwriteVideo) {
                        vidDuration = stringTimeToSeconds(self.state.recordingTime);
                    }
                    else {
                        vidDuration = self.props.overwriteVideo.durationMS
                    }
                }


                if (vidDuration === self.video.currentTime || self.video.currentTime > Math.floor(vidDuration)) {
                    this.video.currentTime = 0;
                    await this.setState({ currentVideoTimeProgress: 0 });
                }

                this.progressBarInterval = setPlayProgress(this, this.video.currentTime, vidDuration);

                this.video.play();

                if (!self.props.overwriteVideo) {
                    self.handleControls(vidDuration, self.video.currentTime, true);
                }
                else {
                    self.handleOverrideControls(vidDuration, self.video.currentTime, true);
                }
            }
            catch (e) {
            }
        },
        confirm: async () => {
            

            if (!this.state.preventAction) {

                await this.setState({ preventAction: true })
                let self = this;
                let vduration = this.video.duration;
                if (self.video.duration === "Infinity" || isNaN(self.video.duration) || self.video.duration == Infinity) {
                    vduration = stringTimeToSeconds(self.state.recordingTime);
                    let vidElem = document.getElementById("recorder-video");
                    vidElem.setAttribute("duration", vduration)
                }
                // Save Video to file
                const newRecording = await saveWikiRecording(this, this.apiClient, this.video, this.props.loggedInUser, blobToFile(this.recorder.getBlob()), this.props, vduration, this.props.articleTitle);
                
                // API Failure
                if (!newRecording) {
                    await this.setState({ preventAction: false })
                    return;
                }

                // Reincorporate data into new model
                this.props.appendNewVideoData(newRecording);

                // Section-based recordings
                await this.setState({ tempCount: this.state.tempCount + 1 });
                await this.setState({ preventAction: false })
                if (this.props.sentenceIndex >= this.props.sectionSentences.length - 1) {
                    // Article finish
                    if (this.props.sectionIndex >= this.props.finalSectionIndex) {
                        this.props.dispatch(this.props.updateScore(100, this.props.loggedInUser, this.apiClient));
                        this.moveToSectionFinishState(true);
                    }
                    // Section Finish
                    else {
                        this.props.dispatch(this.props.updateScore(25, this.props.loggedInUser, this.apiClient));
                        this.moveToSectionFinishState();
                    }

                    return;

                }
                else {
                    // Update user score if recording was submitted
                    this.props.dispatch(this.props.updateScore(5, this.props.loggedInUser, this.apiClient));
                }

                this.setState({
                    selectedControlButtons: [],
                    selectedControlsName: "recordNext",
                    videoTransitionOverlay: this.overlays.nextRecordingOverlay
                });

                // fyd
            }
        },
        pause: () => {
            this.video.pause();
            pausePlayProgress(this);
            const rmb = [...this.state.selectedControlButtons];
            rmb[1].selectedIndex = 0;
            this.setState({ selectedControlButtons: rmb });
        },
        rewind: () => {

        },
        playPrev: () => {

        },
        playNext: () => {

        },
        startRecording: () => {
            this.recordingStart()
        }
    }

    flipPauseButton(flipTarget, isPauseTarget, isPlaying) {
        // Sometimes this is invoked during state transitions on rapid clicking
        try {
            const rmb = [...this.state.selectedControlButtons];
            rmb[flipTarget].selectedIndex = isPauseTarget;
            this.setState({ selectedControlButtons: rmb, isPlaying: isPlaying });

        }
        catch (e) {
            // alert("bug!")
        }
    }

    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    rClock = null;

    initiateRecordingClock = () => {
        // Clears lingering state 
        clearInterval(this.rClock)
        this.setState({ recordTransitionState: false, recordingTime: "0:00" });
        const initTime = new Date();
        this.rClock = setInterval(() => {
            const currentTime = new Date();
            const diff = currentTime - initTime;
            let tm = this.millisToMinutesAndSeconds(diff);
            this.setState({ recordingTime: tm });
        }, 1000)
    }

    overrideButtons = [
        //Redo on Override Existing video: 
        {
            control: [
                {
                    ui: (<span>
                        <RedoRoundedIcon
                            onClick={() => this.redoRecordingOverride()}
                            style={{ ...playerRecorderControls, color: "red" }} />
                    </span>
                    )
                }
            ],
            selectedIndex: 0
        }
        //Confirm Override: 
        // {
        //     control: [
        //         {
        //             ui: (<span>
        //                         <CheckBoxRoundedIcon 
        //                         onClick={() => this.confirmRecordingOverride()} 
        //                         style={{...playerRecorderControls, color: "#0EBE0E" }} />
        //                     </span>
        //                     )
        //         }
        //     ],
        //     selectedIndex: 0
        // }
    ]

    // Regular old recording Refactor this insanity at some point
    tempSetRedoConfirmBtns = async (needConfirm) => {
        await this.setState({ redoBtn: this.confirmRecordingState[0], confirmBtn: this.confirmRecordingState[2] });
    }

    // Override Buttons
    overrideConfirmBtns = async () => {
        await this.setState({ redoBtn: this.overrideButtons[0], confirmBtn: null });
        // await this.setState({ confirmBtn: this.overrideButtons[1] });
    }

    recallPreviousUserRecording = async () => {
        let self = this;
        await this.setState({ isRecorderLoaded: false, isPlaying: true });
        this.progressBarInterval = setPlayProgress(this, 0, this.props.overwriteVideo.durationMS);
        const video = document.getElementById("recorder-video");
        this.moveToConfirmStateOverride();
        this.handleOverrideControls(this.props.overwriteVideo.durationMS, 0, false);
    }

    hideWindowControls = () => {
        this.setState({ isPlaying: true })
    }

    showWindowControls = () => {
        this.setState({ isPlaying: false })
    }


    recordingStart = async () => {

        try {
            await this.setState({ controlsVisible: false })
            setTimeout(async () => {
                await this.setState({ controlsVisible: true })
            }, 300)
        }
        catch (e) {

        }

        // Camera is sometimes not available, retry if so
        try {

            const self = this;
            const owv = this.props.overwriteVideo;
            if (this.props.overwriteVideo) {
                this.recallPreviousUserRecording();
                return
            }
            else {
                // Clear settings from recalled recording
                await this.setState({
                    redoBtn: null, confirmBtn: null,
                    isPlaying: false, recordTransitionState: false,
                    selectedControlButtons: this.recordState,
                    selectedControlsName: "record"
                })
            }

            const vid = this.video
            await this.setState({ isRecorderLoaded: true });
            tempMirrorFirefox("recorder-video", true);
            this.state.videoPlayback = false;

            this.video.muted = true;
            this.video.volume = 0;

            if (this.video.srcObject === null) {
                await this.setState({ recordTransitionState: true })
                setTimeout(() => {
                    this.setState({ recordTransitionState: false, isRecording: true });
                    this.initiateRecordingClock();
                }, 4500)
                var camera = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
                this.video.srcObject = camera;
                this.video.current.style.transform = 'scale(-1, 1)';
            }
            else {
                this.video.style.transform = 'scale(-1, 1)';
            }
            this.recorder = RecordRTC(this.video.srcObject, {
                type: 'video/mp4',
                mimeType: 'video/mp4'
            });
            // this.recorder = RecordRTC(this.video.srcObject, {
            //         type:'video/webm;codecs=vp8'
            // });
            this.video.play();
            setTimeout(() => {
                this.recorder.startRecording();
            }, 4000)
        }
        catch (e) {
            this.recordingStart()
        }
    }

    moveToConfirmStateOverride = async () => {
        // Going to throw exceptions here every time TODO remove this option because of re-recording overhaul
        try {
            await this.setState({
                    selectedControlButtons: this.confirmRecordingState,
                    selectedControlsName: "confirmOverride"
                });
            // this.flipPauseButton(1, 0, false)
            this.video.currentTime = 0;
            this.video.play();
        }
        catch (e) {

        }
    }

    moveToConfirmState = async () => {
        await this.setState({
            selectedControlButtons: this.confirmRecordingState, selectedControlsName: "confirm",
            videoTransitionOverlay: this.overlays.nextRecordingOverlay
        });
        this.flipPauseButton(1, 0, false)
        this.video.currentTime = 0;
        this.video.play();
    }

    stopRecording = async () => {
        tempMirrorFirefox("recorder-video", false)
        try {
            this.flipPauseButton(0, 0, false)
            //if (!this.state.isSaving) {
            clearInterval(this.rClock)
            await this.setState({ isRecording: false, isSaving: true })
            this.moveToConfirmState();
            this.recorder.stopRecording(async () => {
                const isSaveSuccessful = await stopRecordingCallback(this.video, this.recorder, this.props.loggedInUser, this.state.videoDownload);
                await this.setState({ isRecording: false, isSaving: false, confirmBtn: null, recordBtn: null })
            });
        }
        catch (e) {

        }
    }

    moveToSectionFinishState = (isArticleFinished) => {
        let sectionEndOverlay = this.overlays.finishSectionOverlay;
        if (isArticleFinished) {
            sectionEndOverlay = this.overlays.finishArticleOverlay;
        }
        this.setState({
            selectedControlButtons: this.readModeButtons, selectedControlsName: "finishSection",
            videoTransitionOverlay: sectionEndOverlay
        });
    }

    startStopRecording = async (startRecording, isReRecord) => {
        //this.setState({ recordTransitionState: true })
        if (startRecording) {
            await this.resetRecordState();
        }
        else {
            this.flipPauseButton(0, 0, false)
        }

        const rmb = [...this.state.selectedControlButtons];
        // Handle interrupted click transition
        if (rmb.length < 1) {
            this.loadNextRecordVideo();
            return;
        }
        if (startRecording && this.state.selectedControlsName === "record") {
            rmb[0].selectedIndex = 1;
        }
        else {
            rmb[0].selectedIndex = 0;
        }

        this.setState({ selectedControlButtons: rmb });

        if (!startRecording) {
            this.stopRecording();
        }
        else {
            this.recordingStart();
        }

        // if (this.props.overwriteVideo) {
        //     this.turnOffCamera();
        // }

    }


    redoRecordingOverride = async () => {

        this.flipPauseButton(0, 0, false)
        // Allows override of current
        // this.props.activateIsInitialOverrideSelection(true);
        await this.setState({ selectedControlsName: "record", selectedControlButtons: this.recordState, overwriteVideo: false, isRecording: false, recordingTime: 0 })
        // Reset recorder and cancel override
        this.props.selectSentence(this.props.sectionIndex, this.props.sentenceIndex, true)
    }

    // Do we still need this??
    // confirmRecordingOverride = async () => {

    //     const self = this;
    //     // Save Video to file
    //     const newRecording = await saveRecording(self.video, self.props.loggedInUser, blobToFile(self.recorder.getBlob()), self.props, self.video.duration);
    //     this.props.appendNewVideoData(newRecording)

    //     self.setState({
    //         videoTransitionOverlay: self.overlays.recordingUpdatedOverlay
    //     });

    //     self.props.recordNext();


    // }


    redoRecording = async () => {

        await this.setState({ selectedControlsName: "record", selectedControlButtons: this.recordState, isRecording: false, recordingTime: 0 })
        this.startStopRecording(true);
    }

    recordState = [
        //Record: 
        {
            control: [
                {
                    ui: (<StopRoundedIcon
                        onClick={() => this.startStopRecording(false)}
                        style={{ ...playerRecorderControls, color: "red" }} className="control" />)
                },
                {
                    ui: (<StopRoundedIcon
                        onClick={() => this.startStopRecording(false)}
                        style={{ ...playerRecorderControls, color: "red" }} className="control" />)
                }
            ],
            name: "recordStopButton",
            selectedIndex: 0
        }
    ]

    confirmRecordingState = [
        //Redo: 
        {
            control: [
                {
                    ui: (<span>
                        <RedoRoundedIcon
                            onClick={() => this.redoRecording()}
                            style={{ ...playerRecorderControls, color: "red" }} />
                    </span>
                    )
                }
            ],
            name: "redoButton",
            selectedIndex: 0
        },
        // Replay
        {
            control: [
                {
                    ui: (<PlayCircleFilledRoundedIcon onClick={this.recordMethods.play} style={playerRecorderControls} className="control" />)
                },
                {
                    ui: (<PauseCircleFilledRoundedIcon onClick={this.recordMethods.pause} style={playerRecorderControls} className="control" />)
                }
            ],
            name: "playPauseButtons",
            selectedIndex: 0
        },
        // Confirm
        {
            control: [
                {
                    ui: (<CheckBoxRoundedIcon onClick={this.recordMethods.confirm} style={{ ...playerRecorderControls, color: "#0EBE0E" }} />)
                }
            ],
            name: "confirmButton",
            selectedIndex: 0
        }
    ]

    loadNextRecordVideo = async () => {
        // this.props.recordNext();
        this.flipPauseButton(0, 0, false)
        // await this.props.activateIsInitialOverrideSelection(false);
        await this.setState({ selectedControlsName: "", selectedControlButtons: this.recordState, recordingTime: null, isRecording: false, isRecorderLoaded: false })
        this.startStopRecording(true);

    }

    finishRecording = async () => {
        this.props.deSelectLine();
        await this.setState({  controlsVisible: false, isRecorderLoaded: false, overwriteVideo: false, selectedControlsName: "record" })
    }

    overlays = {
        nextRecordingOverlay: (
            <div className="video-transition-overlay">
                <div className="overlay-row">
                    <ThumbUpAltRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "gold" }} />
                    <span className="money">+5 XP</span>
                </div>
                <div className="overlay-row">Recording Saved!</div>
                <br />
                <div className="overlay-row record-again-btn" style={{ background: "rgb(14, 190, 14)" }} onClick={() => {
                    this.props.recordNext();
                    this.loadNextRecordVideo();
                }
                }>
                    <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "white" }} />
                    Record next
                </div>
                <div className="overlay-row record-again-btn" onClick={() => this.finishRecording()}>
                    <button className="record-btn" style={{ height: "100%" }} >Finished</button>
                </div>
            </div>
        ),
        finishSectionOverlay: (
            <div className="video-transition-overlay" >
                <div className="overlay-row"><img src={coins} /><span className="money">+25 XP</span></div>
                <div className="overlay-row cntr">Congratulations, you've completed a section!</div>
                <br />
                <div className="overlay-row record-again-btn" onClick={() => {
                    this.props.recordNext(-1, 0);
                    this.loadNextRecordVideo();
                }}>
                    <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "white" }} />
                    Next Section
                </div>
                <div className="overlay-row record-again-btn" onClick={() => this.finishRecording()}>
                    <button className="record-btn" style={{ height: "100%" }} >Finished</button>
                </div>
            </div>
        ),
        finishArticleOverlay: (
            <div className="video-transition-overlay" onClick={() => {
                this.props.backToLanding()
            }}>
                <div className="overlay-row"><img src={coins} /><span className="money">+100 XP</span></div>
                <div className="overlay-row cntr">Congratulations, you've completed the article, be proud!</div>
                <br />
                <div className="overlay-row record-again-btn">
                    <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "white" }} />
                    Wiki Homepage
                </div>
                <div className="overlay-row record-again-btn" onClick={() => this.finishRecording()}>
                    <button className="record-btn" style={{ height: "100%" }} >Finished</button>
                </div>
            </div>
        ),
        recordingUpdatedOverlay: (
            <div className="video-transition-overlay">
                <div className="overlay-row record-again-btn">
                    Your previous submission was updated!
                </div>
            </div>
        )
    }

    turnOffCamera = async () => {
        const self = this;
        try {
            // Turn off camera if on
            // if (this.recorder) {
            //     this.recorder.stop();
            // }
            const mediaStream = this.video.srcObject;

            // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
            const tracks = mediaStream.getTracks();

            // Tracks are returned as an array, so if you know you only have one, you can stop it with: 
            tracks[0].stop();
            clearInterval(this.rClock);
            await this.setState({ isRecording: false, isSaving: true })

        }
        catch (err) {
        }
    }

    async componentWillUnmount() {

        this.turnOffCamera();

    }

    video = null

    setRef = (ref) => {
        const self = this;
        self.video = ref;
        // if (self.video) {
        //     self.video.style.transform = 'scale(-1, 1)';
        // }
    }

    render() {
        const { stayOpen } = this.state;
        return (
            <React.Fragment>
                {/* {"Time Progress : " + this.state.currentVideoTimeProgress} */}
                {/* TODO Consolidate click state between parent and child */}
                { (this.props.recordMode && !this.state.transitionBetweenRecordings) &&
                    <WikiRecorder
                        videos={this.state.selectedOption ? this.props.sentenceVideos.filter((video) => video.user.userid == this.state.selectedOption) : this.props.sentenceVideos}
                        sentence={"bla"}
                        articleId={"bla"}
                        title={"bla"}
                        toggleMode={this.props.toggleMode}
                        hideWindowControls={this.hideWindowControls}
                        isRecorderLoaded={this.state.isRecorderLoaded}
                        mode={true}
                        currSentence={this.state.currSentence}
                        controlsVisible={this.state.controlsVisible}
                        isPlaying={this.state.isPlaying}
                        redoBtn={this.state.redoBtn}
                        confirmBtn={this.state.confirmBtn}
                        overwriteVideo={this.props.overwriteVideo}
                        recordTransitionState={this.state.recordTransitionState}
                        setRef={this.setRef}
                        controls={this.state.selectedControlButtons}
                        initializeRecorderFunc={this.startStopRecording}
                        selectedControlsName={this.state.selectedControlsName}
                        videoTransitionOverlay={this.state.videoTransitionOverlay}
                        currentVideoTimeProgress={this.state.currentVideoTimeProgress}
                        isRecording={this.state.isRecording}
                        recordingTime={this.state.recordingTime}
                    />

                }
                {/* {this.state.selectedControlsName }
                           <div>
                          { (this.state.selectedControlButtons && this.state.selectedControlButtons.length === 1) && ("Button Index Set 0 " + this.state.selectedControlButtons[0].selectedIndex) }
                          { (this.state.selectedControlButtons && this.state.selectedControlButtons.length >= 2) && ("Button Index Set 1 " + this.state.selectedControlButtons[1].selectedIndex) }
                          </div> */}
                { (this.state.transitionBetweenRecordings) &&
                    <Loader
                        type="Puff"
                        color="#9BD5FB"
                        height={80}
                        width={80}
                    />
                }
            </React.Fragment>
        )
    }
}




const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const RecorderWikiLayer = withRouter(connect(
    
    mapDispatchToProps
)(RecorderWikiLayerContainer));

export default RecorderWikiLayer;
