import React, { Component } from "react";
import { loaderStyle } from '../../styles/modalStyles';
import Loader from 'react-loader-spinner';
import "../../styles/dashboard.css";
import { debounce } from "../../utils/utilMethods";
import DatePicker from "react-datepicker";
import ApiClient from "../../api/ApiClient";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { disambiguateSASURL } from "../../utils/urlSAS";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import SelectSearch from 'react-select-search';

class StatsDashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            isLoading: true,
            queryString: "",
            selectedGroup: "none",
            isAdminMode: true,
            accountType: "UnpaidWiki",
            sOptions: [],
            arOptions: [],
            searchPlaceholder: "Search for a user",
            searchWikiPlaceholder: "Search for an article",
            searchVisible: false,
            searchWikiVisible: false,
            searchText: "",
            searchTextWiki: "",
            showUserLoader: false
        }
    }

    componentDidMount = () => {
        this.loadTargetUserStudy(this.state.accountType);
        this.pullUserStatsSearchOptions();
        this.pullWikiStatsSearchOptions();
    }

    userAccountTypes = [
        { name: "PaidDictionary", value: "PaidDictionary" },
        { name: "PaidWiki", value: "PaidWiki" },
        { name: "UnpaidDictionary", value: "UnpaidDictionary" },
        { name: "UnpaidWiki", value: "UnpaidWiki" }

    ]

    handleAccountInputChange = async (event) => {
        await this.setState({
            accountType: event,
            targetStructuredUserData: undefined,
            targetStructuredGeneralData: undefined,
            targetStructuredWikiData: undefined,
            searchText: undefined,
            searchTextWiki: undefined,
            arOptions: undefined,
            sOptions: undefined,
            userArticleDisplay: undefined,
            wikiArticlesDisplay: undefined
        });
        this.loadTargetUserStudy(event);
        this.pullUserStatsSearchOptions();
        this.pullWikiStatsSearchOptions();
    }

    handleSearchInputChange = async (selection) => {
        if (this.state.dropdownSelectOptions) {
            await this.setState({
                userArticleDisplay: undefined, showUserLoader: true
            })
            this.loadTargetUserStudyUser(selection)

        }
    }

    handleSearchInputChangeWiki = async (selection) => {
        if (this.state.dropdownSelectOptionsWiki) {
            await this.setState({
                wikiArticlesDisplay: undefined
            })
            this.loadTargetUserStudyWiki(selection)

        }
    }


    loadTargetUserStudyWiki = (targetArticle) => {
        this.setState({ searchTextWiki: targetArticle });
        this.apiClient.postJSON(this, "/stats-dashboard/query-wiki", {
            query: targetArticle, userType: this.state.accountType
        }).then(async (res) => {
            
            if (res && res.status) {

                await this.setState({
                    targetStructuredWikiData: res.data.targetStructuredWikiData
                });
                let wikiArticlesDisplay = []
                if ((res.data.type.toLowerCase()).includes("wiki")) {
                    wikiArticlesDisplay = res.data.targetStructuredWikiData.map((article, i) => {
                        return this.genArticleDisplay(article, i)
                    });
                }

                await this.setState({
                    wikiArticlesDisplay
                });
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });
    }


    loadTargetUserStudyUser = (targetUser) => {
        this.setState({ searchText: targetUser });
        this.apiClient.postJSON(this, "/stats-dashboard/query-user", {
            query: targetUser, userType: this.state.accountType
        }).then(async (res) => {
            
            if (res && res.status) {
                await this.setState({
                    targetStructuredUserData: res.data.targetStructuredUserData
                });
                let userArticleDisplay = []
                if ((res.data.type.toLowerCase()).includes("wiki")) {
                    userArticleDisplay = res.data.targetStructuredUserData.map((article, i) => {
                        return this.genArticleDisplay(article, i)
                    });
                }

                await this.setState({
                    userArticleDisplay, showUserLoader: false
                });


            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });
    }


    loadTargetUserStudy = (targetUserStudy) => {
        this.setState({ queryString: targetUserStudy, isLoading: true });
        this.apiClient.postJSON(this, "/stats-dashboard/query", {
            query: targetUserStudy
        }).then(async (res) => {
            
            if (res && res.status) {
                if (res.data.generalStats.length > 0) {
                    await this.setState({
                        targetStructuredGeneralData: res.data.targetStructuredGeneralData,
                        generalStats: res.data.generalStats[0],
                        isLoading: false
                    });
                }
                else {
                    await this.setState({
                        targetStructuredGeneralData: res.data.targetStructuredGeneralData,
                        generalStats: [],
                        isLoading: false
                    });
                }

            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });
    }

    pullUserStatsSearchOptions = async () => {

        await this.setState({ searchText: "" })
        this.apiClient.getJSON(this, "/stats-dashboard/load-all-target-users", "?userType=" + this.state.accountType)
            .then(res => {
                if (res && res.status) {
                    // Populate video big list
                    this.generateSearchOptions(res.data);
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
    }

    pullWikiStatsSearchOptions = async () => {

        await this.setState({ searchTextWiki: "" })
        this.apiClient.getJSON(this, "/stats-dashboard/load-all-target-articles", "?userType=" + this.state.accountType)
            .then(res => {
                if (res && res.status) {
                    // Populate video big list
                    this.generateSearchOptionsWiki(res.data);
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
    }

    genArticleDisplay = (article, i) => {
        
        return (
            <div className="article-stats-cont" style={{ backgroundColor: i % 2 === 0 ? "#ccc" : "#fff" }}>
                <div className="stats-item" style={{ fontSize: "20px", fontWeight: "600", borderBottom: "1px solid black" }}>
                    <div className="stats-col label">Article Name : </div>
                    <div className="stats-col" >{article["articleName"]}</div>
                </div>
                <div className="stats-item">
                    <div className="stats-col label">Total recordings count : </div>
                    <div className="stats-col">{article["numRecordings"]}</div>
                </div>
                <div className="stats-item">
                    <div className="stats-col label">Number of sentences in article : </div>
                    <div className="stats-col">{article["numSentences"]}</div>
                </div>
                {/* <div className="stats-item">
                    <div className="stats-col label">Number of paragraphs : </div>
                    <div className="stats-col">{article["numParagraphs"]}</div>
                </div>
                <div className="stats-item">
                    <div className="stats-col label">Percent of sentences with at least one recording : </div>
                    <div className="stats-col">{article["atLeastOneSentenceCoverage"]}</div>
                </div>
                <div className="stats-item">
                    <div className="stats-col label">Average recordings per sentence </div>
                    <div className="stats-col">{article["avgVideosPerSentence"]}</div>
                </div>
                <div className="stats-item">
                    <div className="stats-col label">Average Video Duration : </div>
                    <div className="stats-col">{article["avgVideoDuration"]}</div>
                </div> */}
            </div>
        )
    }

    setDropdownState = (data) => {
        this.setState({ dropdownSelectOptions: data, searchVisible: true })
    }

    setDropdownStateWiki = (data) => {

        this.setState({ dropdownSelectOptionsWiki: data, searchWikiVisible: true })
    }

    generateSearchOptions = (data) => {
        const uniqueList = {};

        data.forEach((datum) => {

            const name = datum.userName;
            if (!uniqueList[name]) {
                const searchTerm = {
                    name: name.toLowerCase(),
                    value: name
                }
                uniqueList[name] = searchTerm;
            }
        });
        const resArr = Object.values(uniqueList);
        this.setDropdownState(resArr);
    }

    generateSearchOptionsWiki = (data) => {

        const uniqueList = {};

        data.forEach((datum) => {
            const name = datum.articleName;
            if (!uniqueList[name]) {
                const searchTerm = {
                    name: name.toLowerCase(),
                    value: name
                }
                uniqueList[name] = searchTerm;
            }
        });
        const resArr = Object.values(uniqueList);
        this.setDropdownStateWiki(resArr);
    }

    getOptionsWiki = async (query) => {

        try {
            let loadOptions = null;

            const options = await new Promise((resolve, reject) => {
                loadOptions = setInterval(async () => {
                    try {
                        if (this.state.dropdownSelectOptionsWiki.length > 0) {
                            var self = this;

                            const filtered = self.state.dropdownSelectOptionsWiki.filter((select) => {

                                const result = (select.name.includes(query));
                                return result;
                            });
                            resolve(filtered)
                        }
                    }
                    catch (e) {
                        resolve([])
                    }
                }, 200)
            });

            clearInterval(loadOptions);
            this.setState({ arOptions: options })
            return options;
        }
        catch (e) {
            return []
        }
    }



    getOptions = async (query) => {

        try {
            let loadOptions = null;

            const options = await new Promise((resolve, reject) => {
                loadOptions = setInterval(async () => {
                    try {
                        if (this.state.dropdownSelectOptions.length > 0) {
                            var self = this;

                            const filtered = self.state.dropdownSelectOptions.filter((select) => {

                                const result = (select.name.includes(query));
                                return result;
                            });
                            resolve(filtered)
                        }
                    }
                    catch (e) {
                        resolve([])
                    }
                }, 200)
            });

            clearInterval(loadOptions);
            this.setState({ sOptions: options })
            return options;
        }
        catch (e) {
            return []
        }
    }

    render() {

        document.title = 'Admin Dashboard';

        if (this.state.isLoading || !this.state.generalStats) {
            return (
                <div className="admin-loader">
                    <div>Loading Admin Dashboard in a few moments...</div>
                    <div style={{ fontSize: "16px" }}>Patientez, s'il vous plaît</div>
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
            )
        }
        let seedCoverage = []
        if (this.state.generalStats["multiSeedCoverage"]) {
            seedCoverage = Object.keys(this.state.generalStats["multiSeedCoverage"]).map((covg) => {
                return (
                    <div>
                        <span className="stats-mini-col">Number of seeds at count {covg} :</span>
                        <span>{this.state.generalStats["multiSeedCoverage"][covg]}</span>
                    </div>
                )
            });
        }

        if (this.state.generalStats["multiSeedCoverage"]) {
            seedCoverage = Object.keys(this.state.generalStats["multiSeedCoverage"]).map((covg) => {
                return (
                    <div>
                        <span className="stats-mini-col">Number of seeds at count {covg} :</span>
                        <span>{this.state.generalStats["multiSeedCoverage"][covg]}</span>
                    </div>
                )
            });
        }

        let generalUserList = []

        if (this.state.generalStats && this.state.generalStats["usersByCompletionRate"]) {

            generalUserList = this.state.generalStats["usersByCompletionRate"].map((user) => {

                if (user.hasOwnProperty("numberOfVideos")) {
                    return (
                        <div className="little-row-stats-separator">
                            <div style={{ marginBottom: "10px" }}>
                                <span style={{ background: "white" }} >Name:</span>
                                <span style={{ background: "white" }} className="stats-mini-col-2">{user.userName}</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} >Email :</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} className="stats-mini-col-2">{user.userEmail}</span>
                                <span style={{ background: "white" }} ># Recordings:</span>
                                <span style={{ background: "white" }} className="stats-mini-col-2">{user["numberOfVideos"]}</span>
                            </div>
                        </div>
                    )
                }
                else {
                    let userArticles = []
                    if (user["sentencesOfArticles"]) {
                        userArticles = Object.keys(user["sentencesOfArticles"]).map((soa_k) => {
                            let soa = user["sentencesOfArticles"][soa_k]
                            return (
                                <React.Fragment>
                                    <div style={{ marginLeft: "10px" }}><span>{soa_k}&nbsp;</span></div>
                                    <div style={{ marginRight: "5px" }}><span>{soa["userRecordingsCount"]} of {soa["sentenceCount"]}</span></div>
                                </React.Fragment>
                            )
                        })
                    }
                    return (
                        <div className="little-row-stats-separator">
                            <div style={{ marginBottom: "10px" }}>
                                <span style={{ background: "white" }} >Name:</span>
                                <span style={{ background: "white" }} className="stats-mini-col-2">{user.userName}</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} >Email :</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} className="stats-mini-col-2">{user.userEmail}</span>
                                <span style={{ background: "white" }} ># Recordings:</span>
                                <span style={{ background: "white" }} className="stats-mini-col-2">{user.numberOfCompletedWikiSentences}</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} ># Articles :</span>
                                <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} className="stats-mini-col-2">{user.numberOfClaimedArticles}</span>
                            </div>
                            <div className="wiki-stats-article-list flx-row">
                                <span style={{ background: "white" }} >User Articles:</span>
                                <span style={{ background: "white" }} className="stats-mini-col-2 flx-stats-wiki">{userArticles}</span>
                            </div>
                        </div>
                    )
                }
            });
            
        }


        let generalArticleList = []

        if (this.state.generalStats && this.state.generalStats["articlesByCompletionRate"]) {

            generalArticleList = this.state.generalStats["articlesByCompletionRate"].map((article) => {

                return (
                    <div>
                        <span style={{ background: "white" }} >Name:</span>
                        <span style={{ background: "white" }} className="stats-mini-col-2">{article.articleName}</span>
                        <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} ># Sentences :</span>
                        <span style={{ background: "#ccc", padding: "4px", borderRadius: "5px" }} className="stats-mini-col-2">{article.numberOfSentences}</span>
                        <span style={{ background: "white" }} ># Recordings:</span>
                        <span style={{ background: "white" }} className="stats-mini-col-2">{article.recordingsCount}</span>
                    </div>
                )
            });
        }

        let generalStats = (type) => {
            let self = this;
            
            if (type === "wiki") {
                return (
                    <React.Fragment>

                        <div className="stats-item">
                            <div className="stats-col label">User Study : </div>
                            <div className="stats-col">{this.state.generalStats.userStudyType}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Number of articles opened in this user study : </div>
                            <div className="stats-col">{this.state.generalStats.numberOfArticlesOpened}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Average article completion percentage: </div>
                            <div className="stats-col">{this.state.generalStats.percentageArticleCompletion}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Number of sentences belongging to articles opened: </div>
                            <div className="stats-col">{this.state.generalStats.numberOfSentencesBelongingToArticlesStarted}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Number of sentences actually completed: </div>
                            <div className="stats-col">{this.state.generalStats.numberOfSentencesCompleted}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Articles List: </div>
                            <div className="stats-col">{generalArticleList}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Users List: </div>
                            <div className="stats-col">{generalUserList}</div>
                        </div>

                    </React.Fragment>

                )
            }
            else {
                return (
                    <React.Fragment>

                        <div className="stats-item">
                            <div className="stats-col label">User Study : </div>
                            <div className="stats-col">{this.state.generalStats.userStudyType}</div>
                        </div>
                        <div className="stats-item">
                            <div className="stats-col label">Users List: </div>
                            <div className="stats-col">{generalUserList}</div>
                        </div>

                    </React.Fragment>

                )
            }
        }


        return (

            <div style={{ marginBottom: "200px" }}>

                {(!this.state.isLoading && this.state.targetStructuredGeneralData && !(this.state.accountType.toLowerCase()).includes("wiki")) &&
                    <div role="main" className="mainFrame">
                        <div className="dashboard-top-row">
                            <div className="top-row-item" style={{ width: "400px" }}>Stats Dashboard</div>
                            <SelectSearch
                                // autocomplete="off"
                                options={this.userAccountTypes}
                                value={this.state.accountType}
                                onChange={this.handleAccountInputChange}
                            >
                            </SelectSearch>

                        </div>
                        <div className="user-statistics-cont">
                            <div className="stats-section-title">User Statistics</div>
                            <SelectSearch
                                disabled={!this.state.searchVisible}
                                placeholder={this.state.searchPlaceholder}
                                options={this.state.sOptions}
                                getOptions={this.getOptions}
                                search
                                onChange={this.handleSearchInputChange}
                                value={this.state.searchText}

                            >
                            </SelectSearch>
                            {this.state.showUserLoader &&
                                <div style={{ height: "100px" }}>
                                    <div>User Data Loading</div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            }


                            {this.state.targetStructuredUserData &&
                                <div>
                                    <div className="stats-section-title">General Statistics For User : {this.state.searchText}</div>
                                    <div className="stats-item">
                                        <div className="stats-col label">Number of videos submitted by user : </div>
                                        <div className="stats-col">{this.state.targetStructuredUserData["totalVideoCount"]}</div>
                                    </div>
                                    <div className="stats-item">
                                        <div className="stats-col label">Number of seeds covered by user submissions : </div>
                                        <div className="stats-col">{this.state.targetStructuredUserData["seedCoverage"]}</div>
                                    </div>
                                    <div className="stats-item">
                                        <div className="stats-col label">First Submission Date: </div>
                                        <div className="stats-col">{this.state.targetStructuredUserData["firstSubmission"]}</div>
                                    </div>
                                    <div className="stats-item">
                                        <div className="stats-col label">Last Submission Date: </div>
                                        <div className="stats-col">{this.state.targetStructuredUserData["lastSubmission"]}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        {this.state.targetStructuredGeneralData &&
                            <React.Fragment>
                                <div className="stats-section-title">General Statistics</div>
                                <div className="stats-item">
                                    <div className="stats-col label">Total video count : </div>
                                    <div className="stats-col">{this.state.generalStats["totalVideoCount"]}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">Number of seeds for which videos exist : </div>
                                    <div className="stats-col">{this.state.generalStats["seedCoverage"]}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">Number of seeds per instance : </div>
                                    <div className="stats-col">{seedCoverage}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">Average # Videos Per Seed : </div>
                                    <div className="stats-col">{(this.state.generalStats["averageVideosPerSeed"])}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">First Submission Date: </div>
                                    <div className="stats-col">{this.state.generalStats["firstSubmission"]}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">Last Submission Date: </div>
                                    <div className="stats-col">{this.state.generalStats["lastSubmission"]}</div>
                                </div>
                                <div className="stats-item">
                                    <div className="stats-col label">Average # Of Videos Per Contributor : </div>
                                    <div className="stats-col">{this.state.generalStats["averageVideosPerContributor"].toFixed(3)}</div>
                                </div>
                                <React.Fragment>
                                    {generalStats("dictionary")}
                                </React.Fragment>
                            </React.Fragment>
                        }


                    </div>
                }


                {(!this.state.isLoading && this.state.targetStructuredGeneralData && (this.state.accountType.toLowerCase()).includes("wiki")) &&
                    <div role="main" className="mainFrame">
                        <div className="dashboard-top-row">
                            <div className="top-row-item" style={{ width: "400px" }}>Stats Dashboard</div>
                            <SelectSearch
                                // autocomplete="off"
                                options={this.userAccountTypes}
                                value={this.state.accountType}
                                onChange={this.handleAccountInputChange}
                            >
                            </SelectSearch>

                        </div>
                        <div className="user-statistics-cont">
                            <div className="stats-section-title">User Statistics</div>
                            <SelectSearch
                                disabled={!this.state.searchVisible}
                                placeholder={this.state.searchPlaceholder}
                                options={this.state.sOptions}
                                getOptions={this.getOptions}
                                search
                                onChange={this.handleSearchInputChange}
                                value={this.state.searchText}
                            >
                            </SelectSearch>
                            {this.state.showUserLoader &&
                                <div style={{ height: "100px" }}>
                                    <div>User Data Loading...</div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            }
                            {this.state.userArticleDisplay &&
                                <React.Fragment>
                                    {this.state.userArticleDisplay}
                                </React.Fragment>
                            }
                        </div>
                        <div className="user-statistics-cont">
                            <div className="stats-section-title">Search Wiki Articles Statistics</div>
                            <SelectSearch
                                disabled={!this.state.searchWikiVisible}
                                placeholder={this.state.searchWikiPlaceholder}
                                options={this.state.arOptions}
                                getOptions={this.getOptionsWiki}
                                search
                                onChange={this.handleSearchInputChangeWiki}
                                value={this.state.searchTextWiki}

                            >
                            </SelectSearch>
                            <div style={{ marginTop: "15px" }}></div>
                            {this.state.wikiArticlesDisplay &&
                                <React.Fragment>
                                    {this.state.wikiArticlesDisplay}
                                </React.Fragment>
                            }
                        </div>

                        {this.state.generalStats &&
                            <div className="stats-section-title">General Wiki Statistics</div>
                        }
                        {this.state.generalStats &&
                            <React.Fragment>


                                {generalStats("wiki")}
                            </React.Fragment>
                        }


                    </div>
                }
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const StatsDashboard = connect(

    mapDispatchToProps
)(StatsDashboardContainer);

export default StatsDashboard
