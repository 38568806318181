import React from "react";
import Person from '../auth/Person';
import defaultProfileImg from '../../resources/profile.svg';
import ApiClient from "../../api/ApiClient";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class ScoreboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            dailyScoreData: [],
            overAllScoreData: [],
            defaultProfileImg: 'profile.svg',
            signedInGroupId: this.props.signedInGroupId
        }
        //this.getDailyTopScores = this.getDailyTopScores.bind(this);
        this.getOverAllTopScores = this.getOverAllTopScores.bind(this);
    }

    componentDidMount() {
        this.getOverAllTopScores();
    }

    requestingDailyScores = false;
    requestingTopScores = false;

    componentWillReceiveProps(props) {
        this.setState({ signedInGroupId: props.signedInGroupId });

        if (this.state.overAllScoreData.length === 0 && !this.requestingTopScores) {
            this.requestingTopScores = true
            this.getOverAllTopScores();
        }

    }

    getOverAllTopScores = () => {
        this.apiClient.getJSON(this, "/getDailyTopScoresList", '')
            .then((res) => {
                if (res && res.status) {
                    this.setState({ overAllScoreData: res.data })
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
    }

    render() {

        let alltimers = null;
        if (this.state.overAllScoreData) {
            alltimers = this.state.overAllScoreData.map((person, index) => {
                var imgSrc = defaultProfileImg;

                return (
                    <div className="scoreboard-item" key={index}>
                        <Person img={imgSrc} name={person.userName} score={person.xpPoints || 0} alt="Profile pic" />
                    </div>
                )
            })
        }
        return (
            null
            // <div className="scoreboard">
            //     <div className="leaderboard-title">
            //         Leaderboard
            //     </div>
            //     {/* <div className="leaderboard-header">Daily top contributors</div>
            //     {contestants} */}
            //     <div className="leaderboard-header">All-time top contributors</div>
            //     {alltimers}
            // </div>

        )

    }
}


const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Scoreboard = connect(
    
    mapDispatchToProps
)(ScoreboardContainer);


export default Scoreboard


