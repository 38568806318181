// export const setPlayProgress = (context, start, duration) => {
//     const playRate = context.video ? context.video.playbackRate : 1;
//     start = (1/playRate) * start
//     const vMillisecondDuration = duration * 1000 * (1/playRate)
//     context.setState({ currentVideoTimeProgress: ((start*1000) + (50* (1/playRate)))/vMillisecondDuration * 100});
//     const si = setInterval(() => {
//         const percentageProgress = context.state.currentVideoTimeProgress || 0;
//         const newMS = ((percentageProgress/100) * vMillisecondDuration) + 50;
//         const newPercent = newMS / vMillisecondDuration * 100;
//         if ( newPercent >= 100) {
//             clearInterval(si);
//             context.setState({ currentVideoTimeProgress: 100});
//         }
//         else {
//             context.setState({ currentVideoTimeProgress: newPercent});
//         }
//     }, 50);
//     return si;
// }

// export const setDurationHack = (context, $event) => {
//     $event.target.currentTime = 999999;
//     const evv = $event.target;
//     setTimeout(() => {
//         const dur = evv.duration;
//         context.setState({ currentVideoDuration: dur})
//     }, 100)
//     return
// }

// export const pausePlayProgress = (self) => {
//     if (self.progressBarInterval) {
//         clearInterval(self.progressBarInterval);
//         clearTimeout(self.playPauseTimeout);
//     }
// }

export const stringTimeToSeconds = (str) => {
    var arr = str.split(":");
    const seconds = parseInt(arr[1]);
    const minutes = parseInt(arr[0]);
    const msRes = seconds + (minutes * 60);
    return msRes;
}

export const setPlayProgress = (context, start, duration) => {
    if (context.playPauseTimeout || context.progressBarInterval) {
        clearTimeout(context.playPauseTimeout)
        clearTimeout(context.progressBarInterval)
    }

    const videoTarget = context.video;
    const playRate = videoTarget ? videoTarget.playbackRate : 1;
    start = (1 / playRate) * start
    const vMillisecondDuration = duration * 1000 * (1 / playRate);
    const prog = ((start * 1000) + (50 * (1 / playRate))) / vMillisecondDuration * 100
    context.setState({ currentVideoTimeProgress: prog });
    const si = setInterval(() => {
        if (!context.state.pauseProgressOn) {
            const percentageProgress = context.state.currentVideoTimeProgress;
            const newMS = (((percentageProgress / 100) * vMillisecondDuration) + 50);
            const newPercent = newMS / vMillisecondDuration * 100;
            if (newPercent >= 100) {
                clearInterval(si);
                context.setState({ currentVideoTimeProgress: 100 });
            }
            else {
                context.setState({ currentVideoTimeProgress: newPercent });
            }
        }
    }, 50);
    return si;
}

export const setDurationHack = (context, $event) => {
    $event.target.currentTime = 999999;
    const evv = $event.target;
    setTimeout(() => {
        const dur = evv.duration;
        context.setState({ currentVideoDuration: dur })
    }, 100)
    return
}

export const pausePlayProgress = async (self) => {
    await self.setState({ pauseProgressOn: true });
    clearInterval(self.progressBarInterval);
    clearTimeout(self.playPauseTimeout);
    if (self.progressBarInterval) {


    }
    if (self.playPauseTimeout) {

    }
}

export const isRecorderAvailable = async () => {

    return new Promise((resolve, reject) => {

        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);

        navigator.getMedia({ video: true }, function () {
            resolve(true)
            // webcam is available
        }, function () {
            // webcam is not available
            resolve(false)
        });


    })
}