export const playerRecorderControls = {
    fontSize: "2em",
    borderRadius: "50%",
    marginRight: "10px"
}


export const playerRecorderControlText = {
    fontSize: "1.15em",
    marginRight: "5px",
    lineHeight: "30px",
    fontWeight: "bold",
    borderRadius: "50%",
    padding: "0px 6px 0px 4px",
    marginLeft: "10px",
    marginRight: "-160px",
    width: "150px"
}

export const playerRecorderControlSmall = {
    fontSize: "1.5em",
    marginTop: "4px"
}

export const btnRed = {

}