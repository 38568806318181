import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import WebRecorder from './WebRecorder';
import SocialIconsHeader from '../social/SocialIconsHeader';
import PropTypes from 'prop-types';
import { MdInfoOutline } from "react-icons/md";
import ReactTooltip from 'react-tooltip';

class UserCam extends Component {
    constructor(props){
        super(props);
        this.state={
            updateUserLevel:this.props.updateUserLevel,
            signedInGroupId:this.props.signedInGroupId,
            showHavingTroubleButton: false,
            video:this.props.location.state.video,
            videoChoice:this.props.location.state.videoChoice,
            showhideLabel:this.props.location.state.showhideLabel,
            showLabelButton:this.props.location.state.showhideLabel ? false : true,
            hideLabelButton:this.props.location.state.showhideLabel ? true : false
        }
    }
    componentWillReceiveProps(props){
        this.setState({signedInGroupId:props.signedInGroupId});
    }
    heading = {
        width: "300px",
        margin: "24px auto",
        textAlign: "center"
    }

    displayInline = {
        display:"inline-block",
        marginLeft:"75px"
    }

    video = {
        position: "relative",
        display: "block",
        margin: "0 auto"
    }

    btnStylingSmall = {
        fontWeight:"600",
        fontSize:"16px",
        marginRight: "20px"
    }


    displayhtml = {
        display:"inline-block"
    }

    showHavingTrouble = () => {
        this.setState({showHavingTroubleButton:true });
    }

    click = () => this.props.history.push(`/record/${this.props.video.name}`);

    showLabel = () => {
        this.setState({showhideLabel:true, showLabelButton:false, hideLabelButton:true});
    }

    hideLabel = () => {
        this.setState({showhideLabel:false, hideLabelButton:false, showLabelButton:true});
    }

    render() {
        const showVideoLabel = this.state.videoChoice === "myChoiceVideo" ? true : false;
        return (
            <React.Fragment>
                <Row>
                <Col md="6" className="pt-2">
                       
                    </Col>
                    <Col xs="6" className="pt-2">
                        <SocialIconsHeader />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="pt-2 pb-2 record-yourself">
                        <h1 className="clipped">Record yourself signing the word {this.state.video.name}</h1>
                        <div className="text-center mt-4">
                        <Row>
                        <Col xs="4">
                        {this.state.showLabelButton && <div>
                            <a href="mailto:aslgames@microsoft.com">
                                <button style={this.btnStylingSmall} className="btn btn-primary mb-2" onClick={this.showHavingTrouble} >Having Trouble?</button>
                            </a>
                            <button className="btn btn-primary mb-2" onClick={this.showLabel}>Show Label</button>
                        </div>}
                        {this.state.hideLabelButton && <div>
                            <a href="mailto:aslgames@microsoft.com">
                                <button style={this.btnStylingSmall} className="btn btn-primary mb-2" onClick={this.showHavingTrouble} >Having Trouble?</button>
                            </a>
                            <button className="btn btn-primary mb-2" onClick={this.hideLabel}>Hide Label</button>
                        </div>}
                        <div>
                                {this.state.showHavingTroubleButton && 
                                        <span>
                                            Contact : aslgames@microsoft.com
                                        </span>
                                    }
                                </div>
                        </Col>
                        <Col xs="4">
                        {(this.state.showhideLabel) && <div><h2 style={this.displayhtml} className="h3">{this.state.video.name}</h2><a style={this.displayhtml} href="#" data-for="userLevelTip" data-tip ><MdInfoOutline className="labelEnglishInfo"/></a>
                            <ReactTooltip id="userLevelTip">
                            <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                            </ReactTooltip>
                        </div>}
                        </Col>
                        <Col xs="4">
                        {/* show only for random video type */}
                        {/* {(this.state.showLabelButton && !showVideoLabel) && <div>
                                <button className="btn btn-primary mb-2" onClick={this.showLabel}>Show Label</button>
                        </div>}
                        {(this.state.hideLabelButton && !showVideoLabel) && <div>
                                <button className="btn btn-primary mb-2" onClick={this.hideLabel}>Hide Label</button>
                        </div>} */}
                        </Col>
                        </Row>
                            <WebRecorder signedInGroupId={this.state.signedInGroupId} updateUserLevel={this.state.updateUserLevel} history={this.props.history} video={this.state.video} userName={this.props.userName} userid={this.props.userid} newVideo={this.props.newVideo} videoChoice={this.state.videoChoice} />
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

UserCam.propTypes = {
    history: PropTypes.object,
    video: PropTypes.object,
    newVideo: PropTypes.func
};

export default UserCam;