export const invisibleModalStyle = {
    background: 'rgba(255,255,255, 0.75)',
    overlay: { position: 'static' },
    borderRadius: "20px",
    content : {
        top                   : '30%',
        left                  : '50%',
        color: 'grey',
        fontSize: '20px',
        width                 : '400px',
        background: 'transparent',
        border: '0px',
        height                : '280px',                    
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        overflow: "hidden"
      }
}

// TODO Refactor
export const visibleModalStyle = {
    background: '#fff',
    overlay: { position: 'static' },
    borderRadius: "20px",
    border: '3px solid #127BC2',
    content : {
        top                   : '30%',
        left                  : '50%',

        color: 'grey',
        fontSize: '20px',
        width                 : '400px',
        height                : '200px',                    
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        overflow: "hidden"
      }
}

export const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      width                 : '400px',
      padding               : '50px',
      height                : '200px',                    
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      overflow: "hidden"
    },
    levelMessage: {
        textAlign: "center",
        fontSize: '20px'
    },
    levelEmoji: {
        textAlign: "center",
        fontSize: "40px"
    },
    confirmEmoji: {
        textAlign: "center",
        fontSize: "40px",
        color: 'green'
    },
    closeModal: {
        position: 'absolute',
        right: '15px',
        top: '5px'
    },
    invisibleClose: {
        height: "200px",
        width: "400px",
        background: "transparent",
        position: 'absolute',
        top: '0px',
        left: '0px',
    }
  };


export const loaderStyle = { 
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
 };