import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiClient from "../../api/ApiClient";
import PaidWikiConsent from '../auth/PaidWikiConsent';
import PaidDictionaryConsent from '../auth/PaidDictionaryConsent';
import UnpaidGeneralConsent from '../auth/UnpaidGeneralConsent';
import { disambiguateSASURL } from "../../utils/urlSAS";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class ThirdPartySignupContainer extends Component {
  constructor(props) {
    super(props);
    this.apiClient = new ApiClient();
    this.state = {
      thirdPartyUserid: this.props.thirdPartyUserid,
      email: this.props.email,
      token: this.props.token,
      accountType: this.props.accountType,
      age18True: false,
      acceptTerms: false,
      consentForm: false,
      errorMsg: '',
      openInfoModal: false,
      errors: {},
      registerErrors: '',
      isRegisterationCompleted: false,
      firstTimeUser: true
    };
    this.toastId = null;
  }

  labelDisplay = {
    marginLeft: "15px",
    fontWeight: "bold"
  }

  inputFieldDisplay = {
    minWidth: "250px",
    marginBottom: "12px",
    height: "36px"
  }

  requiredFieldCSS = {
    fontSize: "15px",
    marginLeft: "8px",
    color: "#CA1916",
    fontWeight: "500"
  }

  passwordMatchWarning = {
    fontWeight: "450",
    color: "#CC0000"
  }
  h2fontSize = {
    fontSize: "1.75rem",
    color: "#556B2F"
  }

  h3fontSize = {
    fontSize: "1.5rem",
    color: "#556B2F"
  }

  componentDidMount() {
    this.setState({ openInfoModal: true });
    let self = this;


  }

  handleInputChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      registerErrors: ''
    });
  };
  newUserSignUp = (event) => {
    event.preventDefault();
    let self = this;

    this.props.history.push("/profile", {
      email: self.props.history.location.state.email,
      urlToken: self.props.history.location.state.urlToken,
      loginConsentType: self.props.history.location.state.loginConsentType,
      facebook_id: self.props.history.location.state.facebook_id,
      signinType: self.props.history.location.state.signinProvider,
      acceptedConsent: true,
      oldJWTToken: self.props.history.location.state.oldJWTToken,
      access_token: this.props.history.location.state.access_token
    }
    );
  }
  continueSignUp = () => {
    this.setState({ openInfoModal: false });
  }

  logOut = () => {
    this.props.history.push('/tpLogout');
  }

  toggleInfoPopup = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      openInfoModal: !prevState.openInfoModal
    }));
  }

  paidConsentLinks = ["2021WikiPUserStudy", "2021DictionaryUserStudy"]
  unPaidConsentLinks = ["test", "2021WikiUUserStudy"]


  render() {
    const enabled = this.state.age18True && this.state.acceptTerms && this.state.consentForm;

    if (!this.props.history.location.state.loginConsentType || this.props.history.location.state.loginConsentType === "GuestUser") {
      return (
        <div className="account-create-redirect">
          <div className="signup-warning">You're trying to signup without a special link. Please use one of the special links you were provided with to signup. Please refer to your invitation email, or contact the person you've been working with if you have any questions.</div>
          <div className="special-signup-link"><span>Individual Dictionary Sign Record User Study</span>
            <div onClick={() => window.location = "https://community.aslgames.org/tpLogin/dynamic-signup-form/user/2021DictionaryUserStudy"} className="record-btn record-btn special-signup-link-btn">Dictionary Signup Link</div>
          </div>
          {/* <div className="special-signup-link"><span>Wikipedia User Study 2021</span>
            <div onClick={() => window.location = "https://community.aslgames.org/tpLogin/dynamic-signup-form/user/2021WikiPUserStudy"} className="record-btn record-btn special-signup-link-btn">Wiki Signup Link</div>
          </div> */}

          <div className="special-signup-link"><span>Abraham's Wikipedia User Study</span>
            <div onClick={() => window.location = "https://community.aslgames.org/tpLogin/dynamic-signup-form/user/2021WikiUUserStudy"} className="record-btn record-btn special-signup-link-btn">Wiki Signup Link</div>
          </div>
           

        </div>
      )
    }
    // Paid Consent Forms
    //
    //alert(this.paidConsentLinks.indexOf(this.props.history.location.state.loginConsentType))
    if (this.paidConsentLinks.indexOf(this.props.history.location.state.loginConsentType) > -1) {
      return (
        <div className="login-prompt account-creation">
          <form onSubmit={this.newUserSignUp} aria-labelledby="signuplabel" aria-describedby="requiredText">
            <ToastContainer autoClose={2000} />
            <Modal width="500" isOpen={this.state.openInfoModal} toggle={this.toggleInfoPopup} className="modal-lg">
              <ModalHeader toggle={this.toggleVerifyPopup}>NEW USER ACCOUNT</ModalHeader>
              <ModalBody>
                <p>You are about to create a new account. If you are an existing user, please log out and login back using the authentication type and login info used previously, else please continue.</p>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col><button className="record-btn" onClick={this.continueSignUp} aria-label="Continue to SignUp" >CONTINUE</button></Col>
                </Row>

              </ModalFooter>
            </Modal>

            <Row className="margin-top margin-left"><h1 id="signuplabel" style={{ "margin": "0 auto", "marginBottom": "35px" }}>SIGN UP</h1></Row>
            <Row>
              <PaidDictionaryConsent></PaidDictionaryConsent>
            </Row>
            <div className="signUpDiv margin-top">
              <Row className="margin-top margin-left">
                <input type="checkbox"
                  id="age18ChtBx"
                  name="age18True"
                  className="record margin"
                  value={this.state.age18True}
                  onChange={this.handleInputChange}></input>
                <label for="age18ChtBx" className="required">I am at least 18 years old.</label>
              </Row>
              <Row className="margin-left">
                <input type="checkbox"
                  id="termsChkBox"
                  name="acceptTerms"
                  className="record margin"
                  value={this.state.acceptTerms}
                  onChange={this.handleInputChange}></input>
                <label for="termsChkBox" className="required">I agree to the <a style={this.anchorTagFooter} href="https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noopener noreferrer">terms of service</a>.</label>
              </Row>
              <Row className="margin-left">
                <input type="checkbox"
                  id="consentChkBx"
                  name="consentForm"
                  className="record margin"
                  value={this.state.consentForm}
                  onChange={this.handleInputChange}></input>
                <label for="consentChkBx" className="required">I agree to the Consent form terms.</label>
              </Row>
            </div>
            <Row>
              <Col md="6"></Col>
              <Col md="6">
                <span aria-live="assertive" style={this.requiredFieldCSS}>{this.state.registerErrors}</span>
              </Col>
            </Row>
            <Row className="paddingDivBottom">
              <Col md="4">
                <p className="requiredText" id="requiredText"> <span className="requiredTextColor">*</span> means required</p>
              </Col>
              <Col md="4"></Col>
              <Col md="4">
                <button className="record-btn" onClick={this.newUserSignUp} aria-label="Continue to SignUp" disabled={!enabled}>CONTINUE</button>
              </Col>
            </Row>

          </form>
        </div>
      )
    }
    else {

      return (
        <div className="login-prompt account-creation">
          <form onSubmit={this.newUserSignUp} aria-labelledby="signuplabel" aria-describedby="requiredText">
            <ToastContainer autoClose={2000} />
            <Modal width="500" isOpen={this.state.openInfoModal} toggle={this.toggleInfoPopup} className="modal-lg">
              <ModalHeader toggle={this.toggleVerifyPopup}>NEW USER ACCOUNT</ModalHeader>
              <ModalBody>
                <p>You are about to create a new account. If you are an existing user, please log out and login back using the authentication type and login info used previously, else please continue.</p>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col><button className="record-btn" onClick={this.continueSignUp} aria-label="Continue to SignUp" >CONTINUE</button></Col>
                </Row>

              </ModalFooter>
            </Modal>
            {/* <div>
              <UnpaidGeneralConsent></UnpaidGeneralConsent>
            </div> */}
            <div className="signUpDiv margin-top">
              <Row className="margin-top margin-left">
                <input type="checkbox"
                  id="age18ChtBx"
                  name="age18True"
                  className="record margin"
                  value={this.state.age18True}
                  onChange={this.handleInputChange}></input>
                <label for="age18ChtBx" className="required">I am at least 18 years old.</label>
              </Row>
              <Row className="margin-left">
                <input type="checkbox"
                  id="termsChkBox"
                  name="acceptTerms"
                  className="record margin"
                  value={this.state.acceptTerms}
                  onChange={this.handleInputChange}></input>
                <label for="termsChkBox" className="required">I agree to the <a style={this.anchorTagFooter} href="https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noopener noreferrer">terms of service</a>.</label>
              </Row>
              <Row className="margin-left">
                <input type="checkbox"
                  id="consentChkBx"
                  name="consentForm"
                  className="record margin"
                  value={this.state.consentForm}
                  onChange={this.handleInputChange}></input>
                <label for="consentChkBx" className="required">I agree to the Consent form terms.</label>
              </Row>
            </div>
            <Row>
              <Col md="6"></Col>
              <Col md="6">
                <span aria-live="assertive" style={this.requiredFieldCSS}>{this.state.registerErrors}</span>
              </Col>
            </Row>
            <Row className="paddingDivBottom">
              <Col md="4">
                <p className="requiredText" id="requiredText"> <span className="requiredTextColor">*</span> means required</p>
              </Col>
              <Col md="4"></Col>
              <Col md="4">
                <button className="record-btn" onClick={this.newUserSignUp} aria-label="Continue to SignUp" disabled={!enabled}>CONTINUE</button>
              </Col>
            </Row>

          </form>
        </div>
      );
    }
  }
}


const mapDispatchToProps = (dispatch) => ({
  updateScore,
  populateStore,
  requestFailedModal,
  dispatch
});

const ThirdPartySignup = connect(

  mapDispatchToProps
)(ThirdPartySignupContainer);


export default ThirdPartySignup
