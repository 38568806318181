

export const disambiguateSASURL = (url, proc) => {
    if (!url) {
        return "";
    }
    let userAgent = window.navigator.userAgent
    // if (url.includes("TestImage.mp4") {
    //     url = url.replace("TestImage.mp4", "TestImageFlipped.mp4");
    // }

    if (url.includes("controlvideos")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/controlvideos/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=controlvideos&blobName=")
        return url;
        // return url + proc.REACT_APP_CONTROL_VIDEOS 
    }
    if (url.includes("originalvideos")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/originalvideos/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=originalvideos&blobName=")
        return url;
        // return url + proc.REACT_APP_USER_VIDEOS
    }
    else if (url.includes("modifiedvideos")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/modifiedvideos/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=modifiedvideos&blobName=")
        return url;
        // return url + proc.REACT_APP_USER_VIDEOS
    }
    else if (url.includes("convertedvideos")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/convertedvideos/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=convertedvideos&blobName=")
        return url;
        // return url + proc.REACT_APP_USER_VIDEOS
    }
    else if (url.includes("wikitext")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/wikitext/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=wikitext&blobName=")
        return url;
        // return url + proc.REACT_APP_USER_VIDEOS
    }
    else if (url.includes("seedvideosprod")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/seedvideosprod/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=seedvideosprod&blobName=")
        return url;
        // return url + proc.REACT_APP_SEEDVIDEOS
    }
    else if (url.includes("seedvideos")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/seedvideos/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=seedvideos&blobName=")
        return url;
        // return url + proc.REACT_APP_SEEDVIDEOS
    }
    else if (url.includes("svgs")) {
        url = url.replace("https://asluservideos.blob.core.windows.net/svgs/", process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=svgs&blobName=")
        return url;
        // return url + proc.REACT_APP_USER_VIDEOS
    }
    else {
        // return url + proc.REACT_APP_USER_VIDEOS;
        return url;
    }

}
