export const debounce = () => {
    const time = new Date().getTime();
    let text = "";
    let processAllowed = true;
    return (txt, fn) => {
      text = txt;
      processAllowed = true;
      setTimeout(() => {
          if (processAllowed) {
            processAllowed = false;
            fn(text);
          };
      },1200);
    }
}

export const debounce2 = () => {
  const time = new Date().getTime();
  let text = "";
  let processAllowed = true;
  return (txt, fn) => {
    text = txt;
    processAllowed = true;
    setTimeout(() => {
        if (processAllowed) {
          processAllowed = false;
          fn(text);
        };
    },1200);
  }
}

  
  export const tempMirrorFirefox = (videoId, isMirror) => {
    // let userAgent = window.navigator.userAgent

    // //if (userAgent.indexOf("Firefox") > -1) {
    //     let videoElem = document.getElementById(videoId);
    //     if (!isMirror) {
    //       videoElem.style.transform = 'unset';
    //     }
    //     else {
    //       //videoElem.style.transform = 'scale(-1, 1)';
    //     }
    // //}
  }
