import config from "../../config/Config";
import ApiClient from "../../api/ApiClient";

export default class GoogleAuthService {
    //constructor(){
    // window.gapi.load('auth2', function() {
    //     window.gapi.auth2.init({
    //         client_id:config.googleAuthConfig.clientId,
    //         scope:'https://www.googleapis.com/auth/userinfo.profile'
    //     });
    // });
    //}
    getGoogleAccount = async (apiClient) => {
        const jwt = localStorage.getItem('jwtToken');
        if (jwt) {
            const res = await apiClient.getJSON(this, '/getUser/requestSignedToken', '?jwtToken=' + jwt);
            if (res && res.status) {
                var gluser = {
                    // userName: res.user.userName,
                    email: res.data.user.email,
                    // name: res.user.userName,
                    // userId: res.user.userid,
                    // accountType: 'google'
                }
                return gluser;
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong"
            }
        }
        else {
            return null;
        }
    }
    logOut = () => {
        localStorage.removeItem('jwtToken');
        window.location.href = process.env.PUBLIC_URL;
    }
}