import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check, Play, ArrowLeft, ArrowRight, SignIn, SignOut, Thumbsdown } from '@githubprimer/octicons-react';

export const stopRecordingCallback = async (video, recorder, userid, videoDownload) => {
    return new Promise(async (resolve, reject) => {
        video.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
        // this.stopTimer();
        video.muted = false;
        video.volume = 1;
        video.srcObject = null;
        video.src = null;
        video.src = URL.createObjectURL(recorder.getBlob());

        resolve(true)

    })
}

export const blobToFile = (theBlob, fileName) => {
    try {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    catch (e) {
        alert("We're really sorry but something went wrong! The page will refresh after you click ok. Please find your article again.");
        window.location.reload();
    }
}

export const saveWikiRecording = async (self, apiClient, video, userid, videoDownload, props, durationMS, articleName = "") => {
    const { sentenceIndex, sectionIndex } = props;
    const target = props.sectionSentences[sentenceIndex];
    const data = new FormData();
    const name = `${props.articleId}_${sectionIndex}_${sentenceIndex}`;
    data.append('filename', name);
    data.append('videoname', name);
    data.append('durationMS', durationMS);
    data.append('sentenceIndex', sentenceIndex);
    data.append('sectionIndex', sectionIndex);
    data.append('userid', userid);
    data.append('articleId', props.articleId);
    data.append('sentence', target.sentenceText.trim());
    data.append('title', name + ".mp4");
    data.append('file', videoDownload);
    data.append('articleName', articleName);

    let res = await apiClient.postJSON(self, '/add-content/wiki', data, true);

    if (res && res.status) {
        return res;
    }
    else if (res && res.httpServerError) {
        // throw "Something Went Wrong With User Action";
    }
};
