import { isSafari } from 'react-device-detect';
import axios from 'axios';

const TOKEN = '3cfcb8d07a9e33';

 // Update user location for compliance
export const updateUserLocation = async (apiClient, userName) => {
  // Check if location is unchanged since last update
  // let locationToken = localStorage.getItem('user_location');
  // if (!locationToken) {
    const geoLocationData = await axios.get(`https://ipinfo.io?token=${TOKEN}`);
    const { city, region, country } = geoLocationData.data;
    localStorage.setItem('user_location', {
      city, region, country
    })                  
    apiClient.postJSON(this,"/users/validation/updateUserLocation", {
      city, region, country, userName
    }).then((res) => {
    })
}

export const browserCompatibilityCheck = () => {
    let userAgent = window.navigator.userAgent
    let browser = "Incompatible Browser";
    let isMobile = false;
    let isCompatible = true;
    let isTablet = false;

    if (userAgent.indexOf("Firefox") > -1) {
        browser = "Mozilla Firefox";
      } else if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "Samsung Internet";
      } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browser = "Opera";
      } else if (userAgent.indexOf("Trident") > -1) {
        browser = "Microsoft Internet Explorer";
      } else if (userAgent.indexOf("Edge") > -1) {
        browser = "Microsoft Edge";
      } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Google Chrome or Chromium";
      } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Apple Safari";
      } else {
        browser = "unknown";
    }

    if (isSafari) {
        browser = "Safari";
        isCompatible = false;
    }

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        isTablet = true;
    }
    else if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ) {
        isMobile = true;
        isCompatible = false;
    }

    return { isCompatible, browser, isMobile, isTablet }

}