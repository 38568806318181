import React from "react";

export default class FacebookShareButton extends React.Component {
    constructor(props){
        super(props);
        this.FB = window.FB;
    }

    fbShare = {
        borderRadius: "3px",
        fontSize: "16px",
        height: "25px",
        padding: "0px 4px 4px",
        background: "#4267b2",
        border: "1px solid #4267b2",
        color: "#fff",
        cursor: "pointer"
    }

    handleClick = (url, name, description) => {

        // var imageUrl = process.env.REACT_APP_CLIENT_URL + '/circlepic.png';
        // this.FB.getLoginStatus(function(response) {
        //     if (response.status === 'connected') {
        //       var accessToken = response.authResponse.accessToken;
        //       let vid = "https://seedvideos.blob.core.windows.net/seedvideosprod/project.mp4";
        //       this.FB.api(
        //           "/me/videos ",
        //           "POST",
        //           {
        //               "source": vid,
        //           },
        //           function (response) {
        //           }
        //       );
      
        //     } 
        //   } );
     

        // this.FB.ui(
        //     {
        //     app_id: '809985406475562',
        //     method: 'feed',
        //     name: name,
        //     link: 'https://seedvideos.blob.core.windows.net/seedvideos/5_DOLLARS.mp4',
        //     href: 'https://seedvideos.blob.core.windows.net/seedvideos/5_DOLLARS.mp4', //'https://seedvideos.blob.core.windows.net/seedvideos/5_DOLLARS.mp4',
        //     picture: "http://www.fbrell.com/f8.jpg",
        //     caption: 'ASL',
        //     description: description,
        //     caption: 'ASL Video recorded by the user'
        //     });

        
    }
    render() {
        var description = 'This is how I sign '+ this.props.videoName +'. How does your execution compare to the community? https://community.aslgames.org/';
        //var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + this.props.url + '&t=' + description; 
        
        //return (
            // <div className="fb-share-button" 
            //     data-href={this.props.url}
            //     data-layout="button_count">
            // </div>
            
        //     <div className="fb-share-button" 
        //     data-href={this.props.url}
        //     data-layout="button_count" 
        //     data-size="small">
        //         <a target="_blank" 
        //         href={shareUrl} 
        //         className="fb-xfbml-parse-ignore">
        //             Share
        //         </a>
        //     </div>
        //  )

         return (<button style={this.fbShare} onClick={(url, name, decription) => this.handleClick(this.props.url, "Facebook share", description)}>Share</button>)
    }
}