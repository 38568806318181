import React, { Component } from 'react';
class Filter extends Component {
    searchChange = (event) => {
        this.props.setFilter(event.target.value);
      };
  
    render() {
        return(<input type="text" className="ml-2 mr-3 pl-1 pr-1 mb-2 filterControl d-inline-block" onChange={(event) => this.searchChange(event)} aria-label="filter" name="filter" placeholder="Filter" ></input>);
    }
  }
  export default Filter;

  