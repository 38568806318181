// eslint-disable-next-line
import React, { Component } from 'react';
import RecordRTC from 'recordrtc';
import { Row, Col } from 'reactstrap';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check } from '@githubprimer/octicons-react';
import { withRouter } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DuplicateSeedVideos from '../video-verify/DuplicateSeedVideos';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from "react-icons/md";
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import ApiClient from "../../api/ApiClient";
import { disambiguateSASURL } from "../../utils/urlSAS";
import "../../styles/recorder.css";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import { invisibleModalStyle, modalStyles, loaderStyle } from '../../styles/modalStyles';
import { verifyTextContent } from "../../utils/verifyText";
import DisappearNotification from "./../../modals/disappearNotification";
import Loader from 'react-loader-spinner';

class WebModelVideoRecorderContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.recorder = null;
        this.video = React.createRef();
        this.buttonStop = React.createRef();
        this.buttonStart = React.createRef();
        this.buttonSubmit = React.createRef();
        this.recordingStart = this.recordingStart.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.timerId = null;
        this.state = {
            playIcon: PrimitiveDot,
            display: "hide",
            cameraReady: false,
            submitState: false,
            showSubmitState: false,
            response: '',
            post: '',
            responseToPost: '',
            videoNameError: '',
            file: null,
            videoDownload: null,
            userName: null,
            showControls: false,
            recordTime: 0,
            showTimer: false,
            videoNameInput: '',
            openDuplicateSeedVideosModal: false,
            seedVideosList: null,
            preventAction: false
        }
    }

    labelDisplay = {
        marginLeft: "15px",
        fontWeight: "bold"
    }

    inputFieldDisplay = {
        minWidth: "300px",
        height: "40px",
        textTransform: "uppercase",
        paddingLeft: "10px",
        textAlign: "center"
    }
    requiredFieldCSS = {
        fontSize: "15px",
        color: "#CA1916",
        fontWeight: "500",
        padding: "5px 0px 0px 20px"
    }
    startTimer = () => {
        this.setState({ recordTime: 0 });
        this.timerId = setInterval(() => this.setState({ recordTime: this.state.recordTime + 1 }), 1000);
    }
    stopTimer = () => {
        clearInterval(this.timerId);
    }

    prepareCamera = async () => {
        return new Promise((resolve, reject) => {

            // this.buttonStop.current.disabled = true;
            // this.buttonSubmit.current.disabled = true;
            navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
                resolve(camera)
            }).catch(function (error) {
                reject(null)
                // eslint-disable-next-line
                alert('Unable to capture your camera. Please check console logs. WebModelVideoRecorder 2');
                // eslint-disable-next-line
                console.error(error);
            });

        });

    }

    componentDidMount() {
        this.cameraCleanup = this.props.history.listen((location, action) => {
            try {

                this.video.current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });

                this.video.current.src.getTracks().forEach((track) => {
                    track.stop();
                });

            }
            catch (e) {
                // 
                // alert("broken")
            }
        });
    }




    componentWillUnmount() {
        this.cameraCleanup();
    }

    checkDuplicateSeedVideos = async (e) => {

        if (!this.state.preventAction) {

            await this.setState({ preventAction: true });
            e.preventDefault();
            this.apiClient.getJSON(this,"/duplicateSeedVideos?seedVideoName=" + this.state.videoNameInput)
                .then(async (res) => {
                    if (res && res.status) {
                        const modName = this.state.videoNameInput + "_" + res.data.isDuplicate;

                        if (res.data.isDuplicate) {
                            await this.setState({ videoNameInput: modName })
                        }

                        var seedVideos = res.data.seedVideos;
                        this.setState({ seedVideosList: seedVideos });
                        //this.setState({ openDuplicateSeedVideosModal: true });

                        this.handleSeedSubmit();
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }

                })
        }
    }

    recordCallback = () => {
        this.setState({ openDuplicateSeedVideosModal: false });
    }

    toggleRecordNewPopup = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openDuplicateSeedVideosModal: !prevState.openDuplicateSeedVideosModal
        }));
    }

    // continueSubmitSeedVideo = async () => {
    //     if (!this.state.preventAction) {
    //         await this.setState({ preventAction: true });
    //         this.handleSubmit();
    //         this.setState({ openDuplicateSeedVideosModal: false });
    //     }
    // }

    captureCamera(callback) {
        // eslint-disable-next-line
        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
            callback(camera);
        }).catch(function (error) {
            // eslint-disable-next-line
            alert('Unable to capture your camera. Please check console logs. Web Model Video Recorder');
            // eslint-disable-next-line
            console.error(error);
        });
    }

    blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    async stopRecordingCallback() {

        await this.setState({ submitState: true })
        this.video.current.src = this.video.current.srcObject = null;
        this.video.current.muted = false;
        this.video.current.volume = 1;
        this.stopTimer();
        // create the video download
        this.setState({ videoDownload: this.blobToFile(this.recorder.getBlob(), this.state.videoNameInput + ".mp4") });
        // eslint-disable-next-line
        this.video.current.src = URL.createObjectURL(this.recorder.getBlob());
        this.setState({ showControls: true, showTimer: false, recordTime: 0 });
        this.recorder.camera.stop();
        this.recorder.destroy();
        this.recorder = null;
        this.setState({ playIcon: Sync });
        this.setState({ display: "" });
    }

    async recordingStart() {


        // let isTextFlagged = await verifyTextContent(this.apiClient, this.state.videoNameInput, this, "seedMarker");
        // if (isTextFlagged) {
        //     return;
        // }

        await this.setState({ submitState: false })
        if (!this.state.videoNameInput) {
            return;
        }
        await this.setState({ isLoadingCamera: true });

        const camera = await this.prepareCamera();

        await this.setState({ cameraReady: true, isLoadingCamera: false });

        if (camera && !this.video.current.srcObject) {
            this.video.current.srcObject = camera;
            this.video.current.style.transform = 'scale(-1, 1)';
        }

        const inputValue = this.state.videoNameInput;
        let parent = this;
        this.setState({ showControls: false, showTimer: true });
        this.captureCamera(function (camera) {
            parent.video.current.muted = true;
            parent.video.current.volume = 0;
            parent.video.current.srcObject = camera;
            parent.recorder = RecordRTC(camera, {
                type: 'video/mp4',
                mimeType: 'video/mp4'
            });
            parent.recorder.startRecording();
            parent.startTimer();
            // release camera on stopRecording
            parent.recorder.camera = camera;
            // parent.buttonStop.current.disabled = false;
        });
    }

    stopRecording() {
        // if (!this.buttonStop.current.disabled === true) {
        //     this.buttonStop.current.disabled = true;
        //     this.buttonSubmit.current.disabled = false;
        this.recorder.stopRecording(() => this.stopRecordingCallback());
        //}
    }

    seedNameCheck = (name) => {

    }

    handleSeedSubmit = async () => {

        //await this.setState({ videoNameInput: seedNameCheck(this.state.videoNameInput)})
        let self = this;
        this.setState({ showSubmitState: true });
        const data = new FormData();
        // data.append('filename', 'this.props.video.nae');
        data.append('fileName', this.state.videoNameInput);
        data.append('userId', this.props.userid);
        data.append('file', this.state.videoDownload);

        let vresponse = await this.apiClient.postJSON(this,'/uploadModelVideo', data, true);
        if (vresponse && vresponse.status) {
            const body = vresponse.body;
            this.setState({ responseToPost: body });
            if (response.status === 200) {
                this.props.dispatch(this.props.updateScore(5, this.props.userId, this.apiClient));
            }
            let parent = this;
            setTimeout(async () => {
                await this.setState({ preventAction: true });
                parent.props.history.push("/explore");
            }, 1500);
        }
        else if (vresponse && vresponse.httpServerError) {
            // throw "Something Went Wrong With User Action";
        }
    };

    displayhtml = {
        display: "inline-block"
    }


    handleInputChange = async (event) => {
        event.persist();

        if (event.target) {
            const target = event.target;
            let value = target.type === 'checkbox' ? target.checked : target.value;
            if (value.length < 1) {
                this.setState({ cameraReady: false })
            }
            value = value.toUpperCase();
            const name = target.name;
            this.setState({
                [name]: value,
                errors: {}
            });
        }


    }
    render() {
        if (this.state.showSubmitState) {
            return (
                <div style={loaderStyle}>
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                            Uploading Your Video...
                </div>
            )
        }
        else if (this.props.video === null) return null
        // eslint-disable-next-line
        return (
            <React.Fragment>
                <DisappearNotification isTransparent={false} text={this.state.verificationStateText} symbol="processing" showInvisibleModal={this.state.cvsTextProcessing}></DisappearNotification>
                <div className="marginBottom">
                    <span>
                        <input style={this.inputFieldDisplay}
                            id="videoNameInput"
                            type="text"
                            placeholder="Enter english gloss"
                            name="videoNameInput"
                            autocomplete="off"
                            className=""
                            onChange={this.handleInputChange}
                            required
                        />
                    </span>
                    <span style={this.requiredFieldCSS}>{this.state.videoNameError}</span>
                </div>
                <div>
                {this.state.seedMarker && <span className="margin-top" style={{ color: "red" }} aria-describedby="aboutMe"><i>Please fix the seed name above.</i></span>}
                </div>

                <div className="recorder-div">

                    {this.state.isLoadingCamera &&
                        <div style={loaderStyle}>
                            <Loader
                                type="ThreeDots"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                                Loading Camera...
                            </div>
                    }

                    {(!this.state.cameraReady && !this.state.isLoadingCamera) &&
                        <div>
                            <div><VideocamRoundedIcon disabled={!this.state.videoNameInput} disabled={this.state.videoNameInput}
                                style={{ ...playerRecorderControls, border: "none", color: this.state.videoNameInput ? "rgb(14, 190, 14)" : "#ccc", fontSize: "3em" }}
                                onClick={this.recordingStart} ref={this.buttonStart} aria-label="Record"
                            /></div>
                            {!this.state.videoNameInput && <i>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</i>}
                            {this.state.videoNameInput && <div style={{ marginLeft: "-15px", fontSize: "24px" }} >Click to Record</div>}
                        </div>
                    }
                </div>

                {this.state.cameraReady && <div>
                    {/* <div className="outer-container">
                        <div className="inner-container"> */}
                    <div className="seed-container">
                        <div className="video-background-r">
                            {this.state.showTimer && <div className="recording-time-indicator-r"><span>REC {this.state.recordTime}s</span></div>}
                            <video className="video-go" controls={this.state.showControls} autoPlay playsInline ref={this.video} height="357px"></video>
                        </div>
                    </div>
                    {/* </div>
                    </div> */}

                    <div className="controls" style={this.controls} >
                        {!this.state.isLoadingCamera &&
                            <React.Fragment>
                                {!this.state.submitState &&
                                    <StopRoundedIcon onClick={this.stopRecording}
                                        style={{ ...playerRecorderControls, color: "red" }}
                                        className="control"
                                        ref={this.buttonStop} aria-label="Stop" />
                                }

                                {this.state.submitState &&
                                    <div className="recorder-controls-row">
                                        <div className="recorder-control">
                                            <div>Redo</div>
                                            <div>
                                                <RedoRoundedIcon onClick={this.recordingStart} type="submit|" className="re-record-btn"
                                                    style={{ ...playerRecorderControls, color: "red" }}
                                                    ref={this.buttonSubmit} aria-label="Submit video" />
                                            </div>
                                        </div>
                                        <div className="recorder-control" onClick={this.checkDuplicateSeedVideos}>
                                            <div>Submit</div>
                                            <div>
                                                <CheckBoxRoundedIcon className="submit-btn"
                                                    style={{ ...playerRecorderControls, color: "rgb(14, 190, 14)" }}
                                                    ref={this.buttonSubmit} aria-label="Submit video" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>}
                    </div>
                    {/* <p>{this.state.responseToPost}</p> */}
                </div>}

                <Modal width="600" isOpen={this.state.openDuplicateSeedVideosModal} toggle={this.toggleRecordNewPopup} className="modal-lg">
                    <ModalHeader toggle={this.toggleRecordNewPopup}>EXISTING SEED VIDEOS</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12" className="pb-2 max-h-72">
                                <div className="overflow-auto bg-light border-dark border mh-100">
                                    <div className="p-2 explore">
                                        <h2 className="clipped">Submissions</h2>
                                        <DuplicateSeedVideos userId={this.state.userId} seedVideosData={this.state.seedVideosList} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <p>Please check the existing videos, with the name you entered. Clicking on "Continue" will save your recorded video to seed videos collection.</p>
                        <button className="btn btn-primary mt-3 margin-left" onClick={this.recordCallback} aria-label="Cancel" >CANCEL</button>
                        {/* <button className="btn btn-primary mt-3 margin-left" onClick={this.continueSubmitSeedVideo} title="Clicking on Continue, will save the recorded seed video" aria-label="Continue to save seed video" >CONTINUE</button> */}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

// WebRecorder.propTypes = {
//     history: PropTypes.object,
//     video: PropTypes.object,
//     newVideo: PropTypes.func
// };



const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const WebModelVideoRecorder = withRouter(connect(
    
    mapDispatchToProps
)(WebModelVideoRecorderContainer));

export default WebModelVideoRecorder;