let nextTodoId = 0
export const updateScore = (increment, userid, apiClient) => {
  return ({
    type: 'UPDATE_SCORE',
    points: increment
  })
  
  const targetUrl = "/gamify/updateScore";
  const values = {
    increment: increment, userid: userid
  };

  apiClient.postJSON(this, targetUrl, values).then((res) => {

  });

}

export const populateStore = (userAccount) => {
  return ({
  type: 'POPULATE_STORE',
  account: userAccount
})};

export const requestFailedModal = (val) => {
  return ({
    type: 'REQUEST_FAILED',
    modalOn: val
  });
};