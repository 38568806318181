const incrementXP = (state = {}, action) => {
  
  switch (action.type) {
    case 'UPDATE_SCORE':
      
      return {
        ...state,
        userXPIncrement: (state.userXPIncrement || 0) + action.points
      }
    default:
      return state
  }
}

export default incrementXP