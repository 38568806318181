import React from 'react'

const PointsTotal = (state) => {
  
    if (state.user) {
      return (
        <span>
          {state.user.xpPoints + (state.userXPIncrement || 0)}
        </span>
      )
    }
    return (<span>0</span>)
  }

export default PointsTotal