// eslint-disable-next-line
import React, { Component } from "react";
import SocialIconsHeader from '../../components/social/SocialIconsHeader';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Octicon, { Play, Check, X } from '@githubprimer/octicons-react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ApiClient from "../../api/ApiClient";
import { MdInfoOutline } from "react-icons/md";
import ReactTooltip from 'react-tooltip'
import { ToastContainer, toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { invisibleModalStyle, modalStyles, loaderStyle } from '../../styles/modalStyles';
import DisappearNotification from "../../modals/disappearNotification";
import Loader from 'react-loader-spinner';
import "../../styles/verified.css";
import { capitalize } from "../../utils/textMethods";
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { disambiguateSASURL } from "../../utils/urlSAS";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import ReportVideoButton from '../report/ReportVideoButton';

class VerifyContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.exampleVid = React.createRef();
        this.userVid = React.createRef();
        this.handleClose = this.handleClose.bind(this);
        this.onOpenFlagModal = this.onOpenFlagModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this.state = {
            modelPlayDuration: null,
            showModal: false,
            videoList: null,
            showHavingTroubleButton: false,
            userVideos: null,
            userLevel: this.props.userLevel,
            ready: false,
            searchOptionsList: [],
            index: 0,
            voted: false,
            upVotes: 0,
            noContent: false,
            isPullingSearchList: false,
            downVotes: 0,
            show: false,
            showStudyCompletion: false,
            isUserStudy: false,
            showAccessMessage: false,
            blockPage: false,
            openFlagVideoModal: false,
            flagReason: '',
            studyCode: '',
            isLoggedIn: this.props.isLoggedIn,
            signedInGroupId: this.props.signedInGroupId,
            showVideoLabel: false,
            totalVerifiedVideos: 0,
            showVideosCount: true,
            verifyCodeExists: this.props.verifyCodeExists,
            searchText: "",
            preventAction: false
        }
        this.boxStyle = {
            margin: "0 auto",
            width: "300px",
            height: "300px",
            textAlign: "center"
        }
        this.imageStyle = {
            marginTop: "100px"
        }
        this.h1 = {
            marginTop: "184px"
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    btnStylingSmall = {
        fontWeight: "600",
        fontSize: "16px",
        marginRight: "20px"
    }

    searchForSign = (sign) => {
        try {

            const queryString = "?userId=" + this.props.userId + "&name=" + sign;
            this.apiClient.getJSON(this, "/getVerifyVideos/appendSearch", queryString).then((res) => {
                if (res && res.status) {
                    if (!res.data) {
                        alert("no valid video found")
                        return;
                    }

                    const tempVids = [...this.state.data.userVideos];
                    let isDuplicate = false;
                    for (let i = 0; i < tempVids.length; i++) {
                        let vid = tempVids[i]
                        if (vid.name === res.data[0].name) {
                            isDuplicate = true;
                            break;
                        }
                    };
                    if (!isDuplicate) {
                        tempVids.splice(this.state.index, 0, res.data[0]);
                        this.setState({ userVideos: tempVids, index: this.state.index })
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }

            })
        }
        catch (e) {

        }
    }

    showHavingTrouble = () => {
        this.setState({ showHavingTroubleButton: true });
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    textareaStyle = {
        width: "100%"
    };

    btnReport = {
        fontSize: "18px",
        backgroundColor: "darkred"
    }

    notify = () => {
        this.setState({ showInvisibleModal: true })
        setTimeout(() => {
            this.setState({ showInvisibleModal: false })
        }, 1200);
    }

    getVerificationCount = async () => {
        var userId = this.props.userId;
        return await this.apiClient.getJSON(this, '/getVerificationsCount', '?userid=' + this.props.userId);
    }

    getVerifyVideos = async () => {
        if (this.props.userId) {
            return await new Promise((resolve, reject) => {
                if (!this.videosDefined) {
                    this.videosDefined = true;
                    var self = this;
                    this.apiClient.getJSON(this, "/getVerifyVideos").then((res) => {
                        if (res && res.status) {
                            if (!res.data) {
                                this.setState({ noContent: true });
                                return;
                            }
                            this.setState({ userVideos: res.data, ready: true, isUserStudy: false });
                            resolve(true);
                        }
                        else if (res && res.httpServerError) {
                            // throw "Something Went Wrong"
                        }
                    })
                }
            });
        }
    }

    componentWillMount() {
        ReactModal.setAppElement('body');
    }

    generateSearchOptions = (data) => {
        data = data.map((dta) => {
            return { name: dta, value: dta }
        });
        this.props.setDropdownState(data);
    }

    videosDefined = false;

    // componentWillUnmount() {
    //     this.componentCleanup();
    //     //window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
    // }

    async componentDidMount() {
        // window.addEventListener('beforeunload', this.componentCleanup);
        this.videoDefined = false;
        if (!this.state.userLevel.verify) {
            this.setState({ blockPage: true });
        }
        const self = this;

        if (this.props.isLoggedIn && (!(this.state.userVideos || this.state.dataFirstLoad)) && this.props.userId) {

            await this.setState({ dataFirstLoad: true })
            await this.getVerifyVideos();
            this.getVerificationCount().then(res => {
                this.setState({ totalVerifiedVideos: res.totalVerificationsCount, showVideosCount: !res.studyCompleted });
            });
            this.loadSearchOptions();
        }

    }


    loadSearchOptions = async () => {
        if (this.props.userId) {
            await this.setState({ isPullingSearchList: true });
            var queryString = "?userId=" + this.props.userId;
            this.apiClient.getJSON(this, "/getVerifyVideos/search", queryString).then(async (res) => {
                if (res && res.status) {
                    if (!res.data) {
                        this.setState({ noContent: true });
                        return;
                    }
                    await this.setState({ searchOptionsList: res.data });
                    this.generateSearchOptions(res.data);
                    await this.setState({ isPullingSearchList: false });
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            });
        }

    }

    componentWillUnmount() {
        this.setState({ dataFirstLoad: false })
    }


    async componentWillReceiveProps(props) {
        if (this.state.searchOptionsList.length < 1 && !this.state.isPullingSearchList) {
            this.loadSearchOptions();
        }
        if (this.state.searchText !== props.searchText) {
            this.searchForSign(props.searchText);
        }

        if (props.userId) {
            if (props.userLevel.verify) {
                this.setState({ userLevel: props.userLevel, signedInGroupId: props.signedInGroupId, verifyCodeExists: props.verifyCodeExists }, async () => {
                    if (this.state.userLevel.verify) {
                        this.setState({ blockPage: false });
                    }
                    if (props.isLoggedIn && props.userId && !(this.state.userVideos || this.state.dataFirstLoad)) {
                        this.setState({ dataFirstLoad: true }, async () => {
                            await this.getVerifyVideos();
                            this.getVerificationCount().then(res => {
                                this.setState({ totalVerifiedVideos: res.totalVerificationsCount, showVideosCount: !res.studyCompleted });
                            });
                        });
                    }
                })
            }
            else {
                await this.getVerifyVideos();
                this.getVerificationCount().then(res => {
                    this.setState({ totalVerifiedVideos: res.totalVerificationsCount, showVideosCount: !res.studyCompleted });
                });
            }
        }
    }


    static contextTypes = {
        router: PropTypes.object
    }

    playModel() {
        var vid = document.getElementsByClassName("videoModel")[0];
        vid.play();
    }

    eventHandler(event) {
        this.playModel();
    }

    onOpenFlagModal = () => {
        this.setState({ openFlagVideoModal: true });
    }

    playSubmission() {
        var vid = document.getElementsByClassName("videoSubmission")[0];
        vid.play();
    }

    handleClose() {
        this.setState({ show: false, index: this.state.index + 1 });
        if (this.state.index === this.state.userVideos.length - 1 && this.state.isUserStudy) {
            this.setState({ index: 0 });
        } else if (this.state.index === this.state.userVideos.length - 1 && !this.state.isUserStudy) {
            this.setState({ index: 0 });
        }
        else {
            this.getVotes();
        }
    }

    nextVideo = async () => {
        let newIndx = this.state.index + 1;
        const consideredVideos = this.state.userVideos.filter((uv) => !uv.completed);
        if (consideredVideos.length < 1) {
            this.videosDefined = false;
            await this.getVerifyVideos();
            newIndx = 0;
        }
        this.setState({ index: newIndx });
        if (this.state.index === this.state.userVideos.length) {
            this.setState({ index: 0 });
        } else if (this.state.index === this.state.userVideos.length && !this.state.isUserStudy) {
            this.setState({ index: 0 });
        }
        else {
            this.getVotes();
        }
        this.getVerificationCount().then(res => {
            this.setState({ totalVerifiedVideos: res.totalVerificationsCount, showVideosCount: !res.studyCompleted });
        });
        this.setState({ voted: false });
    }

    onHideStudyModal = () => {
        this.setState({ showStudyCompletion: false });
    }

    onClose() {
        this.setState({ openFlagVideoModal: false });
    }

    removeFirstVideo = async () => {
        const uvs = [...this.state.userVideos]
        this.state.userVideos[this.state.index].completed = true;
        await this.setState({ userVideos: uvs })
    }

    otherFunction(event) {
        this.playSubmission();
    }

    showLabel = () => {
        this.setState({ showVideoLabel: true });
    }

    hideLabel = () => {
        this.setState({ showVideoLabel: false });
    }

    vote = async (yesOrNo) => {
        if (!this.state.preventAction) {
            await this.setState({ preventAction: true })
            let self = this;
            this.setState({ voted: true });
            var userVideoId = this.state.userVideos[this.state.index]._id;
            var userId = this.props.userId;
            this.apiClient.getJSON(this, "/verifyUserVideo", "?userVideoId=" + userVideoId + "&verifyResult=" + yesOrNo + "&userId=" + userId)
                .then(async (res) => {
                    if (res && res.status) {
                        if (res.status === 200) {
                            self.props.updateScore(1, self.props.userId, self.apiClient);
                            this.notify();

                            let parent = this;
                            setTimeout(async () => {
                                await this.removeFirstVideo();
                                parent.nextVideo();
                                await this.setState({ preventAction: false })
                                // parent.setState({ index: parent.state.index + 1 });
                                // parent.getVotes();
                            }, 2000);

                        }
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                })
        }
    }

    skip = async () => {
        if (!this.state.preventAction) {
            await this.setState({ preventAction: true })
            const target = this.state.userVideos[this.state.index];
            const tid = target._id;
            target.completed = true;
            var body = { userid: this.props.userId, videoid: tid };
            this.apiClient.postJSON(this, '/getVerifyVideos/skipVerify', body).then(async (res) => {
                if (res && res.status) {
                    await this.setState({ preventAction: false })
                    this.nextVideo()
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });
        }
    }

    getVotes = () => {
        if (this.state.index < this.state.userVideos.length) {
            var userVideoId = this.state.userVideos[this.state.index]._id;
            this.apiClient.getJSON(this, "/getVotes", "?userVideoId=" + userVideoId)
                .then((res) => {
                    if (res && res.status) {
                        if (res.status === 200) {
                            this.setState({ upVotes: res.data.upVotes, downVotes: res.data.downVotes })
                        }
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                })
        }
    }

    waitPlayVideo = (video) => {
        const self = this;
        const interval = setInterval(() => {
            if (self.state.modelPlayDuration) {
                clearInterval(interval);
                setTimeout(() => {
                    video.play();
                }, (self.state.modelPlayDuration - 0.75) * 1000);
                this.setState({ modelPlayDuration: null })
            }
        }, 1000)
    }

    setModelVideoDuration = (duration) => {
        this.setState({ modelPlayDuration: duration })
    }

    voteNo = () => {
        // this.setState({ index: this.state.index + 1 });
    }

    render() {
        if (this.state.blockPage) {
            return (
                <div>Please complete at least 60 recordings before verifying videos</div>
            )
        }

        if (this.state.noContent) {
            return (<Row>
                <Col xs="12">
                    <div style={loaderStyle}>
                        There are no videos to verify
                    </div>
                </Col>
            </Row>)
        }
        const tabindexValue = this.state.blockPage === true ? "-1" : "0";
        document.title = 'Verify - The ASL Data Community';
        this.eventHandler = this.eventHandler.bind(this);
        this.otherFunction = this.otherFunction.bind(this);

        let carouselItems = null;
        let self = this;

        if (this.state.userVideos) {
            const consideredVideos = this.state.userVideos.filter((uv) => !uv.completed);

            carouselItems = consideredVideos.map((uv, i) => {
                return (
                    <React.Fragment>
                        {(i !== 0) &&
                            <div className="carousel-column">
                                <div className="carousel-item-rest ci">
                                    {!this.state.showVideoLabel && uv.name}
                                    {this.state.showVideoLabel && <PlayCircleFilledWhiteIcon style={{ color: "#697777", background: "white", borderRadius: "50%" }} />
                                    }
                                </div>
                            </div>
                        }
                        {(i === 0) &&
                            (
                                <div className="col-first-c">
                                    <div className="carousel-item-first  ci">
                                        <PlayCircleFilledWhiteIcon style={{ color: "#127BC2", background: "white", borderRadius: "50%" }} />
                                        <span className="name-cont"> {!this.state.showVideoLabel && uv.name}</span>
                                    </div>
                                </div>
                            )
                        }
                    </React.Fragment>


                )
            })
        }

        if (!this.props.userId) {
            return (
                <Row>
                    <Col xs="12">
                        <div style={loaderStyle}>
                            Please login to access this view.
                        </div>
                    </Col>
                </Row>
            )
        }
        if (this.state.ready === false) {
            return (
                <Row>
                    <Col xs="12">
                        <div style={loaderStyle}>
                            <Loader
                                type="ThreeDots"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                            Loading videos for verification...
                        </div>
                    </Col>
                </Row>
            )
        }
        else if (this.state.ready === true) {
            if (this.state.index >= this.state.userVideos.length) {
                return (
                    null
                    // <div>
                    //     <BlockUi loader={<span></span>} tag='div' className="row" blocking={this.state.blockPage} message="To access this page, you should have contributed 50 or more videos.">
                    //         <Col xs="12" aria-hidden={this.state.blockPage} className="text-center">
                    //             <h1 style={this.h1}>No more videos to vote on!</h1>
                    //             <p>You can create more videos for voting on the <Link to="/record">record page</Link>.</p>
                    //         </Col>
                    //     </BlockUi>
                    // </div>
                )
            }

            else {
                return (
                    <React.Fragment>
                        {/* Verify Part II Completed Notification */}
                        <DisappearNotification symbol="check-mark" text="Thanks, video was verified!" showInvisibleModal={this.state.showInvisibleModal}></DisappearNotification>
                        <div role="main">

                            <div className="section-top-row">
                                <div className="description-second lower-col-1">
                                    Judge whether the submitted sign adequately demonstrates the model sign.
                                </div>
                                <div className="lower-col-2">
                                    <CheckIcon style={{ color: "rgb(14, 190, 14)", marginRight: "5px" }} />
                                    <div >You've verified {this.state.totalVerifiedVideos} videos</div>

                                </div>
                                <div className="lower-col-3">
                                    {!this.state.showVideoLabel && <div onClick={this.showLabel} className="show-label-cont">
                                        <VisibilityOffIcon style={{ marginRight: "5px", color: "#127BC2", cursor: "pointer" }} />
                                        <span style={{ marginLeft: "5px" }} className="show-label" >Hide Label</span>
                                    </div>}
                                    {this.state.showVideoLabel && <div onClick={this.hideLabel} className="show-label-cont">
                                        <VisibilityIcon style={{ marginRight: "5px", color: "#127BC2", cursor: "pointer" }} />
                                        <span style={{ marginLeft: "5px" }} className="show-label" >Show Label</span>
                                    </div>}
                                    <div className="having-trouble">
                                        <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbRx1qYFM3N0FCo6HgdDr2DB1UMkJZRTFWSzJJR0VNSjBYU05CQldRTldWWi4u">Having trouble?</a>
                                    </div>
                                </div>

                            </div>
                            <div className="carousel">
                                {carouselItems}
                                <div className="carousel-right-boundary" onClick={() => this.skip()}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.46967 4.21967C8.17678 4.51256 8.17678 4.98744 8.46967 5.28033L15.1893 12L8.46967 18.7197C8.17678 19.0126 8.17678 19.4874 8.46967 19.7803C8.76256 20.0732 9.23744 20.0732 9.53033 19.7803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L9.53033 4.21967C9.23744 3.92678 8.76256 3.92678 8.46967 4.21967Z" fill="#212121" />
                                    </svg>
                                </div>
                            </div>


                            <div className="video-side-by-side-cont">
                                <div className="verify-col-left">
                                    <div className="video-background-v-left" style={{ position: "relative" }}>
                                        {/* {((this.state.userVideos[this.state.index].modelVideo.avertJobId && this.state.userVideos[this.state.index].modelVideo.avertJobStatus === "InProgress")
                                            || !this.state.userVideos[this.state.index].modelVideo.verifiedCVS) &&
                                            <div className="video-warning">
                                                <span>
                                                    WARNING: This video is currently under review.
                       
                                                </span>
                                            </div>
                                        } */}
                                        <video controls className="verify-video l verify"
                                            src={disambiguateSASURL(this.state.userVideos[this.state.index].modelVideo.convertedUrl, process.env)} onLoadedData={($event) => {
                                                this.setModelVideoDuration($event.target.duration);
                                                return
                                            }} autoPlay muted preload='auto' style={this.video} key={this.state.index}>
                                            <source type="video/mp4" ref={this.exampleVid} />
                                            <track id="enTrackModel" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div>


                                    <div className="video-bottom-dv-v">

                                        <div style={{ position: "relative" }}>
                                            <span className="video-sign" >Model Video</span>
                                            <span className="verify-flag" >
                                                <ReportVideoButton skipToNewVideo={() => this.skip()} data={this.state.userVideos[this.state.index].modelVideo} loggedInUserId={this.props.userId} />
                                            </span>
                                            {/* {this.state.showVideoLabel && <p className="h2 mb-2 verifySignName">{this.state.userVideos[this.state.index].modelVideo.name}<a href="#" aria-hidden="false" data-for="userLevelTip" data-tip ><MdInfoOutline className="labelEnglishInfo" /></a>
                                        <ReactTooltip id="userLevelTip">
                                            <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                        </ReactTooltip></p>} */}
                                        </div>


                                        {(!this.state.showVideoLabel) && <div className="reveal-word-display">{this.state.userVideos[this.state.index].modelVideo.name}
                                            <a style={this.displayhtml} href="#" aria-hidden="false" data-for="userLevelTip" data-tip

                                                aria-label="This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing."
                                            ><MdInfoOutline className="labelEnglishInfo" /></a>
                                            <ReactTooltip id="userLevelTip">
                                                <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                            </ReactTooltip></div>}
                                    </div>


                                </div>

                                <div className="verify-col-right">
                                    <div className="video-background-v-right" style={{ position: "relative" }}>
                                        {/* {((this.state.userVideos[this.state.index].avertJobId && this.state.userVideos[this.state.index].avertJobStatus === "InProgress")
                                            || !this.state.userVideos[this.state.index].verifiedCVS) &&
                                            <div className="video-warning">
                                                <span>
                                                    WARNING: This video is currently under review.
                                         
                                                </span>
                                            </div>
                                        } */}
                                        <video controls className="verify-video r"
                                            src={disambiguateSASURL(this.state.userVideos[this.state.index].convertedUrl, process.env)} onLoadedData={(event) => {
                                                this.waitPlayVideo(event.target);
                                                return;
                                            }} muted preload='auto' style={this.video} key={this.state.index}>
                                            <source type="video/mp4" ref={this.userVid} />
                                            <track id="enTrackUser" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div>


                                    <div className="video-bottom-dv-v space-three">
                                        <div style={{ position: "relative" }}>
                                            <span className="video-sign">Community Video</span>
                                            <span className="verify-flag comm" >
                                                <ReportVideoButton skipToNewVideo={() => this.skip()} data={this.state.userVideos[this.state.index]} loggedInUserId={this.props.userId} />
                                            </span>

                                        </div>
                                        <div>
                                            {(!this.state.showVideoLabel) && <div className="reveal-word-display">{this.state.userVideos[this.state.index].modelVideo.name}
                                                <a style={this.displayhtml} href="#" aria-hidden="false" data-for="userLevelTip" data-tip
                                                    aria-label="This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing."
                                                ><MdInfoOutline className="labelEnglishInfo" /></a>
                                                <ReactTooltip id="userLevelTip">
                                                    <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                                </ReactTooltip></div>}
                                        </div>

                                    </div>



                                </div>

                            </div>
                            <div className="verify-portal-cont">
                                {/* <div className="portal-top-row">
                                    Verify the submitted video
                                            <div className="flag-and-info">
                                        <ReportButton loadNextVideo={() => this.nextVideo()} updateVideoList={null} data={this.state.userVideos[this.state.index]} loggedInUserId={this.props.userId} />
                                    </div>
                                </div> */}
                                <div className="explainer-text-portal">
                                    <span>
                                        Does the user-submitted sign approximately match the model sign?<a href="#" data-for="userLevelTip1B"
                                            aria-label=" Please answer “Yes” if it is an exact match, or if it looks a little different but is basically the same sign. Please answer “No” if it has the same/similar meaning but is a different sign, contains unrecognizable signing, or contains other content."
                                            data-tip ><MdInfoOutline className="labelEnglishInfo" /></a>
                                        <ReactTooltip id="userLevelTip1B">
                                            Please answer “Yes” if it is an exact match, or if it looks a little different but is basically the same sign. Please answer “No” if it has the same/similar meaning but is a different sign, contains unrecognizable signing, or contains other content.
                                        </ReactTooltip>
                                    </span>
                                    <span>
                                        <button className="confirm-btn"
                                            onClick={() => this.vote(true)}>Yes</button>
                                    </span>
                                    <span>
                                        <button
                                            className="confirm-btn"
                                            onClick={() => this.vote(false)}> No</button>
                                    </span>
                                    <span>
                                        <button
                                            className="confirm-btn skip"
                                            onClick={() => this.skip()}> Skip</button>
                                    </span>


                                </div>
                                {/* <div className="portal-response-row">

                                </div> */}
                            </div>


                            <Modal show={this.state.showStudyCompletion} onHide={this.onHideStudyModal} backdrop="static" style={{ top: "30%" }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Thanks for your vote!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Congratulations! You have finished verifying 60 signs. If you are participating in our user study, please enter the following code in the survey: {this.state.studyCode}</Modal.Body>
                            </Modal>
                        </div>
                    </React.Fragment>
                )
            }
        }
    }
}



const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Verify = withRouter(connect(
    
    mapDispatchToProps
)(VerifyContainer));

export default withRouter(Verify);
