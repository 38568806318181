import React, { Component } from "react";
import PropTypes from 'prop-types'
import VerifyUserVideo from '../video-verify/VerifyUserVideo';
import RecordNewVideo from '../player-recorder/RecordNewVideo';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import DeleteContent from '../misc/DeleteContent';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import SelectSearch from 'react-select-search';
import "../../styles/profile.css";
import { Row, Col } from 'reactstrap';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { debounce } from "../../utils/utilMethods";
import ApiClient from "../../api/ApiClient";
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { disambiguateSASURL } from "../../utils/urlSAS";
import ReportVideoButton from '../report/ReportVideoButton';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

const VIDEO_LIMIT = 8;

class UserVideoListingContainer extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            userId: this.props.userId,
            loggedInUser: this.props.loggedInUser,
            isCurrentUser: this.props.userId === this.props.loggedInUser,
            data: null,
            userVideo: null,
            openVerifyModal: false,
            openRecordNewModal: false,
            userLevel: this.props.userLevel,
            signedInGroupId: this.props.signedInGroupId,
            videoCount: 0,
            searchValue: '',
            currentPage: 1,
            targetTerm: "",
            wikiVideos: [],
            dropdownSelectOptions: [],
            subTabState: "UserVideos",
            wikiTotalCount: 0,
            totalPages: 1,
            userVideos: [],
            alreadyLoadedSeedIds: [],
            totalCount: 0,
            scrollDiv: '',
            isYourProfile: false,
            alreadyLoadedVideoIds: [],
            alreadyLoadedWikiIds: [],
            seedVideos: [],
            preLoadWiki: [],
            preLoadSeed: [],
            preLoadDictionary: [],
            seedCount: 0,
            wikiCount: 0,
            dictionaryCount: 0,
            isDropdownComplete: false,
            isLoading: false
        }

        this.onShowVerifyModal = this.onShowVerifyModal.bind(this);
        this.onShowRecordNewModal = this.onShowRecordNewModal.bind(this);
        this.updateVideoList = this.updateVideoList.bind(this);
        this.disableButton = this.disableButton.bind(this);
    }

    async componentWillMount() {
        if (!this.state.isLoading) {
            this.updateVideoList(this.state.searchValue, this.props.tabState);
        }
        
    }


    identifyFiltered = () => {
        switch (this.props.tabState) {
            case "DictionaryVideos":
                return [this.state.preLoadDictionary, this.state.userVideos, "userVideos", "preLoadDictionary"];
            case "WikiVideos":
                return [this.state.preLoadWiki, this.state.wikiVideos, "wikiVideos", "preLoadWiki"];
            case "SeedVideos":
                return [this.state.preLoadSeed, this.state.seedVideos, "seedVideos", "preLoadSeed"];
            default:
                return
        }
    }

    async componentWillReceiveProps(props) {

        if (this.state.userId !== props.userId && !this.state.isLoading) {
            this.setState({ userVideos: [] });
            this.updateVideoList(this.state.searchValue, this.props.tabState);
        }
        this.setState({
            userId: props.userId,
            userLevel: props.userLevel,
            loggedInUser: props.loggedInUser,
            signedInGroupId: props.signedInGroupId,
            isCurrentUser: props.userId === props.loggedInUser
        });
    }

    componentDidMount() {
        window.onscroll = () => {
            const self = this;
            if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= document.documentElement.clientHeight + 100) {
                let self = this;
                let itF = this.identifyFiltered();
                let targetFiltered = itF[0];
                let targetDataInWaiting = itF[1];
                let targetStateProperty = itF[2];
                let preloadStateProperty = itF[3];

                if (targetFiltered.length > 0 && targetDataInWaiting.length > 0) {
                    const targetAdd = targetDataInWaiting.slice(0, VIDEO_LIMIT);
                    const dataRemnants = targetDataInWaiting.slice(VIDEO_LIMIT, targetDataInWaiting.length);
                    this.setState({ [targetStateProperty]: dataRemnants });
                    const newFilterSet = [...targetFiltered, ...targetAdd];
                    if (preloadStateProperty === "preLoadDictionary") {
                        this.setState({ preLoadDictionary: newFilterSet });
                    }
                    else {
                        this.setState({ [preloadStateProperty]: newFilterSet });
                    }

                }
                else {
                };
            }
        }
    }

    switchSubFocus = (tabFocus) => {
        this.setState({ subTabState: tabFocus });
    }


    pullVideos = async (search, isSilent, extension, secondPull) => {
        // isSilent is false on first pull, results from first pull show up immediately.
        if (extension === "DictionaryVideos") {
            //
        }
        if (!isSilent) {
            // alert("invoked " + extension)
            await this.setState({
                alreadyLoadedVideoIds: [], dataInWaiting: [], totalCount: 0, preLoadWiki: [], alreadyLoadedSeedIds: [],
                preLoadSeed: [],
                preLoadDictionary: []
            })
        }
        await this.setState({ isYourProfile: this.isYourProfile() });
        return new Promise((resolve, reject) => {
            var self = this;

            const body = {
                isSilent,
                targetLetter: this.state.targetLetter,
                search: search,
                alreadyLoadedVideoIds: this.state.alreadyLoadedVideoIds,
                alreadyLoadedWikiIds: this.state.alreadyLoadedWikiIds,
                alreadyLoadedSeedIds: this.state.alreadyLoadedSeedIds,
                email: this.props.email,
                targetUserId: this.state.isYourProfile ? this.props.targetProfileId : this.props.userId,
                isYourProfile: this.state.isYourProfile
            }

            this.apiClient.postJSON(this, "/userVideosFilterByUser/" + extension, body)
                .then(async (res) => {
                    if (extension === "DictionaryVideos") {
                        // 
                    }
                    if (res && res.status) {
                        var self = this;

                        if (!res.data.isSilent) {

                            if (extension === "DictionaryVideos") {
                                let alis = []
                                let salis = []
                                let pD = [];
                                let pS = [];
                                if (res.data.seedVideos) {
                                    self.state.seedCount += res.data.seedVideos.length;
                                    salis = [...this.state.alreadyLoadedSeedIds, ...res.data.seedVideos.map((mv) => mv.id.toString())];
                                    pS = [...res.data.seedVideos];
                                }
                                if (res.data.userVideos) {
                                    self.state.videoCount += res.data.userVideos.length;
                                    alis = [...this.state.alreadyLoadedVideoIds, ...res.data.userVideos.map((mv) => mv.id.toString())];
                                    pD = [...res.data.userVideos]
                                }

                                await this.setState({ alreadyLoadedVideoIds: alis, showCount: false, alreadyLoadedSeedIds: salis });
                                await this.setState({ preLoadDictionary: pD, preLoadSeed: pS });
                            }
                            else if (extension === "WikiVideos") {
                                // Filter for count only
                                let paidUserVideos = res.data.userVideos.filter((wiki) => {
                                    return !wiki.wasFromUnpaidStudy
                                });
                                await this.setState({ wikiCount: paidUserVideos.length });
                                let newIds = res.data.userVideos.map((mv) => mv.id.toString());
                                const walis = [...self.state.alreadyLoadedWikiIds, ...newIds];
                                await this.setState({ alreadyLoadedWikiIds: walis, showCount: false });
                                await this.setState({ preLoadWiki: [...res.data.userVideos] });
                            
                            }
                            if (secondPull) {
                                if (extension === "DictionaryVideos") {
                                    // 
                                }
                                // Silent Query
                                this.pullVideos(search, true, extension, false);
                            }
                        }
                        else {
                            if (extension === "DictionaryVideos") {
                                self.state.seedCount += res.data.seedVideos.length;
                                self.state.videoCount += res.data.userVideos.length;
                                // this.setState({ totalCount: self.state.totalCount += res.totalCount });
                                if (res.data.userVideos && res.data.userVideos.length > 0) {
                                    await this.setState({ userVideos: [...res.data.userVideos], showCount: true });
                                }
                                if (res.data.seedVideos && res.data.seedVideos.length > 0) {
                                    await this.setState({ seedVideos: [...res.data.seedVideos], showCount: true });
                                }
                            }
                            else if (extension === "WikiVideos") {
                                // Filter for count only
                                let paidUserVideos = res.data.userVideos.filter((wiki) => {
                                    return !wiki.wasFromUnpaidStudy
                                });
                                this.state.wikiCount += paidUserVideos.length
                                // this.setState({ wikiTotalCount: self.state.wikiTotalCount += res.totalCount });               
                                await this.setState({ wikiVideos: [...res.data.userVideos] });
                            }
                        }
                        if (extension === "DictionaryVideos") {
                            if (!this.state.isDropdownComplete || !this.state.dropdownSelectOptions.length) {
                                this.setDropdownState([...this.state.dropdownSelectOptions, ...res.data.userVideos, ...res.data.seedVideos]);
                            }
                        }
                        else {
                            if (!this.state.isDropdownComplete || !this.state.dropdownSelectOptions.length) {
                                this.setDropdownState([...this.state.dropdownSelectOptions, ...res.data.userVideos]);
                            }
                        }
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong With User Action";
                    }

                })
        });
    }

    updateVideoList = async (search, extension) => {
        // 
        var self = this;

        await self.setState({ seedCount: 0, wikiCount: 0, dictionaryCount: 0, videoCount: 0, isLoading: true });

        // Initial Pull Loads Limit
        this.pullVideos(search, false, "DictionaryVideos", true);

        this.pullVideos(search, false, "WikiVideos", true);
    }

    // search = async () => {
    //     await this.setState({ totalCount: 0, wikiTotalCount: 0 })
    //     if (this.state.searchValue && this.state.searchValue !== '') {
    //         this.updateVideoList(this.state.searchValue, this.props.tabState);
    //     } else {
    //         this.updateVideoList('', this.props.tabState);
    //     }
    // }

    updateQueryResults = (query) => {
        // this.debounceSearchText(query, this.search);
    }


    // debounceInitTime;
    // debounceSearchText = debounce();

    handleSearchInputChange = (event) => {
        // const target = event.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;
        this.setState({
            [event]: event,
            errors: {}
        });
        this.setState({ targetTerm: event })
        // this.updateQueryResults(event)
    }

    clearSearchResults = async () => {
        await this.setState({ targetTerm: "" });
    }

    onShowVerifyModal = (userVideo) => {
        this.setState({ userVideo: userVideo, openVerifyModal: true });
    }

    onShowRecordNewModal = (userVideo) => {
        this.setState({ userVideo: userVideo, openRecordNewModal: true });
    }

    onClick = () => this.props.history.push(`/verify/${this.name}`);

    toggleVerifyPopup = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openVerifyModal: !prevState.openVerifyModal
        }));
    }

    toggleRecordNewPopup = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openRecordNewModal: !prevState.openRecordNewModal
        }));
    }

    disableButton = (userVideo) => {
        var verifyResults = userVideo.verifyResults;
        var parent = this;
        if (verifyResults !== undefined) {
            var newResults = verifyResults.filter(function (item) {
                return item.verifiedByUser === parent.props.loggedInUser;
            });
            if ((newResults && newResults.length > 0) || (userVideo.user.id === this.props.loggedInUser)) {
                return true;
            } else {
                return false;
            }
        } else if (userVideo.user.id === this.props.loggedInUser) {
            return true;
        } else {
            return false;
        }
    }

    setDropdownState = (data) => {
        this.setState({ dropdownSelectOptions: data, searchVisible: true })
    }

    getOptions = async (query) => {
        try {
            let loadOptions = null;
            const options = await new Promise((resolve, reject) => {
                loadOptions = setInterval(async () => {
                    try {
                        if (this.state.dropdownSelectOptions.length > 0) {
                            var self = this;
                            const filtered = self.state.dropdownSelectOptions.filter((select) => {
                                let result = false;
                                if (select.name) {
                                    result = (select.name.toUpperCase()).includes(query.toUpperCase());
                                }
                                else {
                                    result = (select.sentence.toUpperCase()).includes(query.toUpperCase());
                                }
                                return result;
                            });
                            const filteredNames = filtered.map((item) => {
                                if (item.name) {
                                    return { name: item.name, value: item.name }
                                }
                                else {
                                    const resi = item.sentence.substring(0, 15) + "...";
                                    return { name: resi, value: item.sentence }
                                }
                            })
                            resolve(filteredNames)
                        }
                    }
                    catch (e) {
                        resolve([])
                    }
                }, 200)
            });
            clearInterval(loadOptions);
            this.setState({ sOptions: options })
            return options;
        }
        catch (e) {
            return []
        }
    }

    isYourProfile = () => {
        const urlString = window.location.href;
        var url = new URL(urlString);

        var uid = url.searchParams.get("userId");
        // Another users account
        if (uid) {
            // alert("NOT YOUR PROFILE")
            return false
        }
        else {
            // alert("YOUR PROFILE")
            return true
        }

    }

    // verifyCallback = () => {
    //     this.updateVideoList(this.state.searchValue, this.props.tabState);
    //     this.setState({ openVerifyModal: false });
    // }

    // recordCallback = () => {
    //     this.updateVideoList(this.state.searchValue, this.props.tabState);
    //     this.setState({ openRecordNewModal: false });
    // }

    flagRemoval = () => {

    }

    handleScroll = () => {
        var target = this.state.scrollDiv;
        if (target !== "") {
            target.scrollTop = 0;
        }
    }

    handleClick = (event) => {
        event.preventDefault();
        this.setState({ scrollDiv: event.target });
    }

    generateShareUrl = (id) => {
        const rootUrl = process.env.REACT_APP_CLIENT_URL;
        const specificUrl = rootUrl + '/playVideo?id=' + id;
        return specificUrl;
    }

    render() {
        const flaggedMsg = <p style={this.flggedFontSize}><i><b style={{ color: "#E20C0C" }}>WARNING:</b> This video has been flagged and we are in the process of reviewing it.</i></p>

        var self = this;

        var wikiVideos = self.state.preLoadWiki.map((userVideo, index) => {
            if (!userVideo.sentence.includes(this.state.targetTerm) && this.state.targetTerm.length > 0) {
                return (null);
            }
            else {
                return (

                    <div className="video-row-p">
                        <div style={{ width: "25%", marginRight: "30px" }}>
                            <div className="video-background">
                                {/* {((userVideo.avertJobId && userVideo.avertJobStatus === "InProgress") || !userVideo.verifiedCVS) &&
                                    <div className="video-warning">
                                        <span>
                                            WARNING: This video is currently under review.
                                  
                                        </span>
                                    </div>
                                } */}
                                {(userVideo.avertFlagViolated || userVideo.flaggedByCVS) &&
                                    <div className="video-warning">
                                        <span>
                                            NOTE: Inappropriate content was detected in this video, so it is not visible throughout the site.
                                        </span>
                                    </div>
                                }
                                <video className="profile-video" id={`userVideoModel_${userVideo.convertedUrl}`} controls muted preload='auto' key={userVideo.convertedUrl}>
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/mp4" ref={this.exampleVid} />
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/webm" ref={this.exampleVid} />
                                    <track id={`enTrack_${userVideo.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                        <div style={{ width: "34%", padding: "5px 20px 5px 20px" }}>
                            <span className="article-title-user-vids">
                                {userVideo.articleName}
                            </span><p>  {userVideo.sentence}</p>
                        </div>
                        <div style={{ width: "15%", padding: "5px 20px 5px 20px" }}>
                            <Moment format="YYYY/MM/DD">
                                {userVideo.date}
                            </Moment>

                        </div>
                        <div style={{ width: "8%", padding: "5px 20px 5px 20px" }}>
                            5
                        </div>
                        <div style={{ width: "18%", display: "flex", flexDirection: "row", padding: "5px 20px 5px 20px" }}>
                            {/* <button className="record-btn" role="link" aria-label="submit new recording"
                                style={{ marginRight: "25px" }}
                                onClick={(uv) => this.onShowRecordNewModal(userVideo)}>Record</button> */}
                            {this.state.isYourProfile && <div style={{ marginRight: '15px' }}>
                                <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={userVideo} loggedInUserId={this.props.loggedInUser} />
                            </div>
                            }
                            {this.state.isYourProfile && <DeleteContent tabState={this.props.tabState} updateVideoList={this.updateVideoList} deleteExtension="wikiVideo" data={userVideo}
                                loggedInUserId={this.props.loggedInUser} explorePage={false} userId={this.props.userId} currentPage={this.state.currentPage}
                                searchValue={this.state.searchValue} />
                            }
                        </div>
                    </div>




                );
            }
        });



        var seedVideos = self.state.preLoadSeed.map((userVideo, index) => {
            if (!userVideo.name.includes(this.state.targetTerm) && this.state.targetTerm.length > 0) {
                return (null);
            }
            else {
                return (

                    <div className="video-row-p">
                        <div style={{ width: "25%", marginRight: "30px" }}>
                            <div className="video-background">
                                {/* {((userVideo.avertJobId && userVideo.avertJobStatus === "InProgress") || !userVideo.verifiedCVS) &&
                                    <div className="video-warning">
                                        <span>
                                            WARNING: This video is currently under review.
                        
                                        </span>
                                    </div>
                                } */}
                                {(userVideo.avertFlagViolated) &&
                                    <div className="video-warning">
                                        <span>
                                            NOTE: Inappropriate content was detected in this video, so it is not visible throughout the site.
                                        </span>
                                    </div>
                                }
                                <video className="profile-video" id={`userVideoModel_${userVideo.convertedUrl}`} controls muted preload='auto' key={userVideo.convertedUrl}>
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/mp4" ref={this.exampleVid} />
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/webm" ref={this.exampleVid} />
                                    <track id={`enTrack_${userVideo.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                        <div style={{ width: "34%", padding: "5px 20px 5px 20px" }}>
                            {userVideo.name}
                        </div>
                        <div style={{ width: "15%", padding: "5px 20px 5px 20px" }}>
                            <Moment format="YYYY/MM/DD">
                                {userVideo.date}
                            </Moment>

                        </div>
                        <div style={{ width: "8%", padding: "5px 20px 5px 20px" }}>
                            5
                        </div>
                        <div style={{ width: "18%", display: "flex", flexDirection: "row", padding: "5px 20px 5px 20px" }}>
                            {!this.state.isYourProfile && <div style={{ marginRight: '15px' }}>
                                <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={userVideo} loggedInUserId={this.props.loggedInUser} />
                            </div>
                            }
                            {/* <button className="record-btn" role="link" aria-label="submit new recording"
                                style={{ marginRight: "25px" }}
                                onClick={(uv) => this.onShowRecordNewModal(userVideo)}>Record</button> */}
                            {this.state.isYourProfile &&
                                <DeleteContent tabState={this.props.tabState} updateVideoList={this.updateVideoList} deleteExtension="seedVideo" data={userVideo} loggedInUserId={this.props.loggedInUser} explorePage={false} userId={this.props.userId} currentPage={this.state.currentPage} searchValue={this.state.searchValue} />
                            }
                        </div>
                    </div>

                );
            }
        });

        var userVideos = self.state.preLoadDictionary.map((userVideo, index) => {

            if (this.state.targetTerm && (!userVideo.name.includes(this.state.targetTerm) && this.state.targetTerm.length > 0)) {
                return (null);
            }
            else {

                if (!userVideo.convertedUrl) {
                    return null;
                }
                return (
                    <div className="video-row-p">
                        <div style={{ width: "25%", marginRight: "30px" }}>
                            <div className="video-background">
                                {/* {((userVideo.avertJobId && userVideo.avertJobStatus === "InProgress") || !userVideo.verifiedCVS) &&
                                    <div className="video-warning">
                                        <span>
                                            WARNING: This video is currently under review.
             
                                        </span>
                                    </div>
                                } */}
                                {(userVideo.avertFlagViolated) &&
                                    <div className="video-warning">
                                        <span>
                                            NOTE: Inappropriate content was detected in this video, so it is not visible throughout the site.
                                        </span>
                                    </div>
                                }
                                <video className="profile-video" id={`userVideoModel_${userVideo.convertedUrl}`} controls muted preload='auto' key={userVideo.convertedUrl}>
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/mp4" ref={this.exampleVid} />
                                    <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/webm" ref={this.exampleVid} />
                                    <track id={`enTrack_${userVideo.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                        <div style={{ width: "34%", padding: "5px 20px 5px 20px" }}>
                            {userVideo.name}
                        </div>
                        <div style={{ width: "15%", padding: "5px 20px 5px 20px" }}>
                            <Moment format="YYYY/MM/DD">
                                {userVideo.date}
                            </Moment>

                        </div>
                        <div style={{ width: "8%", padding: "5px 20px 5px 20px" }}>
                            2
                        </div>
                        <div style={{ width: "18%", display: "flex", flexDirection: "row", padding: "5px 20px 5px 20px" }}>
                            {/* <button className="record-btn" role="link" aria-label="submit new recording"
                                style={{ marginRight: "25px" }}
                                onClick={(uv) => this.onShowRecordNewModal(userVideo)}>Record</button> */}
                            {!this.state.isYourProfile && <div style={{ marginRight: '15px' }}>
                                <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={userVideo} loggedInUserId={this.props.loggedInUser} />
                            </div>
                            }
                            {this.state.isYourProfile && <DeleteContent tabState={this.props.tabState} updateVideoList={this.updateVideoList} deleteExtension="userVideo" data={userVideo} loggedInUserId={this.props.loggedInUser} explorePage={false} userId={this.props.userId} currentPage={this.state.currentPage} searchValue={this.state.searchValue} />
                            }
                        </div>
                    </div>
                );
            }
        });

        return (
            <React.Fragment>

                <div className="search-row">
                    <div className="header-search-profile" style={{ position: "relative" }}>
                        <SelectSearch
                            // disabled={!this.state.searchVisible}
                            placeholder="Search Videos"
                            autoComplete="off"
                            // autocomplete="off"
                            options={this.state.sOptions}
                            // closeOnSelect={true}
                            getOptions={this.getOptions}
                            search
                            onChange={this.handleSearchInputChange}
                            value={this.state.targetTerm}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.search()
                                }
                            }}
                        >


                        </SelectSearch>
                        {this.state.targetTerm.length > 0 && <HighlightOffRoundedIcon onClick={this.clearSearchResults} className="clear-search-btn-profile" />}

                    </div>
                    {this.props.tabState === "DictionaryVideos" &&
                        <div onClick={() => this.switchSubFocus("UserVideos")} className="tabs-item"
                            style={{
                                borderBottom: this.state.subTabState === "UserVideos" ? "4px solid #127BC2" : "none",
                                color: this.state.subTabState === "UserVideos" ? "#127BC2" : "#697777",
                                paddingBottom: "0px"
                            }}>
                            {this.state.isYourProfile &&
                                <span>Your User Videos</span>}
                            {!this.state.isYourProfile &&
                                <span>User Videos</span>}
                        </div>
                    }

                    {this.props.tabState === "DictionaryVideos" &&

                        <div onClick={() => this.switchSubFocus("SeedVideos")} className="tabs-item" style={{
                            borderBottom: this.state.subTabState === "SeedVideos" ? "4px solid #127BC2" : "none",
                            color: this.state.subTabState === "SeedVideos" ? "#127BC2" : "#697777",
                            paddingBottom: "0px",
                            marginLeft: "20px"
                        }}>
                            {this.state.isYourProfile &&
                                <span>Your Seed Videos</span>}
                            {!this.state.isYourProfile &&
                                <span>Seed Videos</span>}
                        </div>
                    }
                    {(this.props.tabState === "DictionaryVideos" && this.state.userVideos && this.state.seedVideos && this.state.isYourProfile) &&
                        <span style={this.videoCount}><b>Total Dictionary Video Count: {(this.props.tabState2 + this.props.totalUserVideos)}
                        </b></span>
                    }
                    {(this.props.tabState === "WikiVideos" && this.state.isYourProfile) &&
                        <span style={this.videoCount}><b>Total Wiki Video Count: {this.state.wikiCount}</b></span>
                    }

                </div>
                <div className="video-columns-defs">
                    <div style={{ width: "25%" }}>Sign</div>
                    <div style={{ width: "34%", padding: "5px 20px 5px 20px" }}></div>
                    <div style={{ width: "15%", padding: "5px 20px 5px 20px" }}>Date Recorded</div>
                    <div style={{ width: "8%", padding: "5px 20px 5px 20px" }}>Points</div>
                    <div style={{ width: "18%", padding: "5px 20px 5px 20px" }}></div>
                </div>
                <div className="video-collection-box">

                    {this.props.tabState === "DictionaryVideos" &&
                        <div style={{ width: '100% !imporant' }} onScroll={this.handleClick}>
                            <div>
                                {(seedVideos.length > 0 && this.state.subTabState === "SeedVideos") &&
                                    <div>

                                        {seedVideos}
                                    </div>
                                }

                                {(userVideos.length > 0 && this.state.subTabState === "UserVideos") &&
                                    <div>
                                        {userVideos}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {this.props.tabState === "WikiVideos" &&
                        <div className="overflow-y bg-white border-dark border mh-100" style={{ width: '100% !imporant' }} onScroll={this.handleClick}>
                            <div>
                                {wikiVideos}
                            </div>
                        </div>
                    }
                </div>

                <Modal width="500" isOpen={this.state.openVerifyModal} toggle={this.toggleVerifyPopup} className="modal-lg record-modal">
                    <ModalHeader toggle={this.toggleVerifyPopup}>Verify User Video</ModalHeader>
                    <ModalBody>
                        <VerifyUserVideo userVideo={this.state.userVideo} onFinish={() => this.verifyCallback()} userId={this.props.loggedInUser} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
                <Modal width="500" isOpen={this.state.openRecordNewModal} toggle={this.toggleRecordNewPopup} className="modal-lg record-modal">
                    <ModalHeader className="record-modal-header" toggle={this.toggleRecordNewPopup}>Record New Video</ModalHeader>
                    {/* <ModalBody>
                        <RecordNewVideo userVideo={this.state.userVideo} onFinish={() => this.recordCallback()} userId={this.props.loggedInUser} email={this.props.email} signedInGroupId={this.props.signedInGroupId} />
                    </ModalBody> */}
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const UserVideoListing = connect(

    mapDispatchToProps
)(UserVideoListingContainer);

export default UserVideoListing;
