// Microsoft Login Config
module.exports = {
  msAuthConfig:{
    clientId: 'cf7087d6-8df3-464e-8175-de980eb23b70',
    scopes: [
      'user.read',
      'calendars.read'
    ],
    redirectUri: process.env.REACT_APP_CLIENT_URL_MSFT
  },
  // googleAuthConfig:{
  //   clientId:'152340878085-4gmhgu0po35uv36899qeifo8fea4ec2f.apps.googleusercontent.com',
  //   redirectUri:'http://localhost:3000'
  // },
  fbAuthconfig:{

  }
  
};