import React from "react";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class PersonContainer extends React.Component {
    imgcontainer = {
        height: 40,
        width: 40
    }
    render() {
        return (
            <div className="person" style={this.person} size={this.props.size}>
                {/* <span style={this.imgcontainer}> */}
                    {/* <img className="userimg" src={this.props.img} height="30" alt={this.props.alt} /> */}
                    <AccountCircleRoundedIcon style={{color: "#7A22D1"}} />

                {/* </span> */}
                <p className="h6 username">
                    {this.props.name}
                </p>
                <p className="userscore" style={this.score}>
                    {this.props.score}
                </p>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Person = connect(
    
    mapDispatchToProps
)(PersonContainer);

export default Person