import store from '../store/accountInfo.js';

export default class ApiClient {

    getCSV = async (routeName, queryString = "") => {
        
        var url = process.env.REACT_APP_SERVER_URL + routeName + queryString;
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                authorization: localStorage.getItem("jwtToken")
            }
        });

        let json = await response.json();

        // Reauthenticate via login page
        if (response.status === 200) {

            let csvContent = "data:text/csv;charset=utf-8,";
            json.data.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", json.fileName + ".csv");
            document.body.appendChild(link);

            return link;
        }
        else if (response.status === 401) {
            return { isLoggedOut: true }
        }
        else if (response.status === 403) {
            localStorage.removeItem("jwtToken")
            window.location.href = process.env.PUBLIC_URL + "/tpLogin";
        }
        else {
            throw Error("Failed to connect to server");
        }



    }


    getJSONFile = async (routeName, queryString = "") => {
        
        var url = process.env.REACT_APP_SERVER_URL + routeName + queryString;
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                authorization: localStorage.getItem("jwtToken")
            }
        });

        let jsonRes = await response.json();
        
        // Reauthenticate via login page
        if (response.status === 200) {
            
            const fileName = jsonRes.fileName;
            
            const jsonData = jsonRes.data;
            const blob = new Blob([jsonData],{type:'application/json'});
            const href = await URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          
        }
        else if (response.status === 401) {
            return { isLoggedOut: true }
        }
        else if (response.status === 403) {
            localStorage.removeItem("jwtToken")
            window.location.href = process.env.PUBLIC_URL + "/tpLogin";
        }
        else {
            throw Error("Failed to connect to server");
        }



    }

    getJSON = async (self, routeName, queryString = "") => {
        
        try {
            let responseStatus = 500;
            let retryCount = 0;
            let reqRes = undefined;
            while (responseStatus.toString()[0] === "5" && retryCount < 5) {
    
                reqRes = await new Promise(async (resolve, reject) => {
                    var url = process.env.REACT_APP_SERVER_URL + routeName + queryString;
                    let response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            authorization: localStorage.getItem("jwtToken")
                        }
                    });
                    let json = await response.json();
                    // Reauthenticate via login page
                    if (response.status === 200 || response.status === 217) {
                        responseStatus = 200;
                        resolve(json);
                    }
                    else if (response.status === 401) {
                        responseStatus = 401;
                        resolve({ isLoggedOut: true })
                    }
                    else if (response.status === 403) {
                        localStorage.removeItem("jwtToken")
                        window.location.href = process.env.PUBLIC_URL + "/tpLogin";
                        responseStatus = 200;
                        resolve({})
                    }
                    else {
                        responseStatus = response.status;
                        resolve({})
                    }
                });
                retryCount += 1;
                // Wait 500 ms before next retry to give servers time in their regeneration alcove
                if (responseStatus.toString()[0] === "5") {
                    await new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve({})
                        }, 500);
                    });
                }
            }


            if (self && responseStatus.toString()[0] === "5") {
                self.props.dispatch(self.props.requestFailedModal(true));
                setTimeout(() => {
                    self.props.dispatch(self.props.requestFailedModal(false));
                }, 7000);
                return {
                    httpServerError: true
                }

            }

            return reqRes;
        }
        catch (e) {
            if (self) {
                self.props.dispatch(self.props.requestFailedModal(true));

                setTimeout(() => {
                    
                    self.props.dispatch(self.props.requestFailedModal(false));
                }, 7000);
            }

            return {
                httpServerError: true
            }
        }
    }

    postJSON = async (self, routeName, body, isForm) => {
        try {
            
            let uniqueness = (Math.random() + (new Date()).getTime());
            var url = process.env.REACT_APP_SERVER_URL + routeName;
            let response = undefined

            let responseStatus = 500;
            let retryCount = 0;
            let reqRes = undefined;

            while (responseStatus.toString()[0] === "5" && retryCount < 5) {
                if (retryCount === 0 && isForm) {
                    body.append("uniqueness", uniqueness);
                }
                else if (retryCount === 0 && !isForm) {
                    body["uniqueness"] = uniqueness;
                }

                reqRes = await new Promise(async (resolve, reject) => {
                    if (isForm) {
                        response = await fetch(url, {
                            method: 'POST',
                            headers: new Headers({ "Authorization": `${localStorage.getItem("jwtToken")}` }),
                            body:  body,
                        });
                    }
                    else {
                        response = await fetch(url, {
                            method: 'POST',
                            body: JSON.stringify(body),
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                                authorization: localStorage.getItem("jwtToken")
                            }
                        });
                    }
                    

                    let json = await response.json();
                    
                    if (response.status === 200 || response.status === 217) {
                        responseStatus = 200;
                        resolve(json);
                    }
                    else if (response.status === 401) {
                        responseStatus = 401;
                        resolve({ isLoggedOut: true })
                    }
                    else if (response.status === 403) {
                        localStorage.removeItem("jwtToken")
                        window.location.href = process.env.PUBLIC_URL + "/tpLogin";
                        responseStatus = 200;
                        resolve({})
                    }
                    else {
                        responseStatus = response.status;
                        resolve({})
                    }
                });

                retryCount += 1;
                // Wait 500 ms before next retry to give servers time in their regeneration alcove
                if (responseStatus.toString()[0] === "5") {
                    await new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve({})
                        }, 500);
                    });
                }
            }

            if (responseStatus.toString()[0] === "5") {
                
                self.props.dispatch(self.props.requestFailedModal(true));
                setTimeout(() => {
                    self.props.dispatch(self.props.requestFailedModal(false));
                }, 7000)
                return {
                    httpServerError: true
                }

            }

            return reqRes;
        }
        catch (error) {
            
            self.props.dispatch(self.props.requestFailedModal(true));
            setTimeout(() => {
                self.props.dispatch(self.props.requestFailedModal(false));
            }, 7000);
            return {
                httpServerError: true
            }
        }

    }

}