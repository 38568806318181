const populateStore = (state = {}, action) => {
  
  switch (action.type) {
    case 'POPULATE_STORE':
      return {
        ...state,
        user: action.account
      }
    case 'REQUEST_FAILED':
      
      return {
        ...state,
        reqFailedModal: action.modalOn
      }
    default:
      return state
  }
}

export default populateStore