import React, {Component} from 'react';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = {

        }
     }

     footerLinkCSS = {
         float:"left",
         listStyle:"none",
         margin:"0px 30px 0px -15px"
     }

     socialMediaIconsCSS = {
         float:"left",
         listStyle:"none",
         margin:"0px 5px 0px -15px"
     }

     anchorTagFooter = {
         color:"green",
         textDecoration:"underline",
         fontSize:"14px",
         fontWeight:"450"
     }

     fontSizes = {
         fontSize:"14px",
         fontWeight:"450"
     }

     iconStyle = {
        height:"30px",
        width:"30px"
    }

     showCurrentYear() {
        return new Date().getFullYear();
    }


     render(){
         const logoUrl = `${process.env.PUBLIC_URL}/logoMicrosoft.jpg`;
         return(
             <div className="footerDiv" style={{display:"inline"}}>
                 <ul>
                     <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://go.microsoft.com/?linkid=2028325" target="_blank" rel="noopener noreferrer">Contact us</a></li>
                     <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noopener noreferrer">Privacy & Cookies</a></li>
                     <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://go.microsoft.com/fwlink/?linkid=2259814" target="_blank" rel="noopener noreferrer">Consumer Health Privacy</a></li>
                     <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                     {/* <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx" target="_blank" rel="noopener noreferrer">Code of Conduct</a></li> */}
                     <li style={this.footerLinkCSS}><a style={this.anchorTagFooter} href="https://go.microsoft.com/fwlink/?LinkId=506942" target="_blank" rel="noopener noreferrer">Trademarks</a></li>
                     <li style={this.footerLinkCSS}>
                         <span style={this.fontSizes}>© {this.showCurrentYear()} Microsoft </span>
                         <a href="http://www.microsoft.com" target="_blank" rel="noopener noreferrer">
                             <img style={{marginLeft:"6px"}} src={logoUrl} title="Microsoft Corporation" alt="Microsoft Logo" ></img>
                         </a>
                     </li>                              
                    <li style={this.socialMediaIconsCSS}>
                        <SocialIcon style={this.iconStyle} url="https://www.facebook.com/sharer/sharer.php?u=https://community.aslgames.org&t=ASL%20Games" network="facebook"fgColor="white" label="Click to share the ASL site through facebook" target="_blank" rel="noopener noreferrer"/>
                    </li>
                    <li style={this.socialMediaIconsCSS}>
                        <SocialIcon style={this.iconStyle} url="https://twitter.com/intent/tweet?text=Hello%20world&url=https://community.aslgames.org" label="Click to share the ASL site through twitter" target="_blank" rel="noopener noreferrer" network="twitter"fgColor="white"/>
                    </li>
                    <li style={this.socialMediaIconsCSS}>
                        <SocialIcon style={this.iconStyle} url="http://pinterest.com/pin/create/button/?url=https://community.aslgames.org&description=ASL%20Games" className="pin-it-button" label="Click to share the ASL site through pinterest" target="_blank" rel="noopener noreferrer" network="pinterest"fgColor="white"/>
                    </li>
                    <li style={this.socialMediaIconsCSS}>
                        <SocialIcon style={this.iconStyle} url="https://www.linkedin.com/shareArticle?mini=true&url=https://community.aslgames.org&title=ASL%20Games&summary=ASL%20Games" label="Click to share the ASL site through linkedin" target="_blank" rel="noopener noreferrer" network="linkedin"fgColor="white"/>
                    </li>
                </ul>
         </div>
         );
     }
}

export default Footer
