// eslint-disable-next-line
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Octicon, { Play, Check, X } from '@githubprimer/octicons-react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import ApiClient from "../../api/ApiClient";
import { MdInfoOutline } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import { disambiguateSASURL } from "../../utils/urlSAS";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class VerifyUserVideoContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.exampleVid = React.createRef();
        this.userVid = React.createRef();
        this.state = {
            userVideo: this.props.userVideo,
            ready: false,
            upVotes: 0,
            downVotes: 0
        }
        this.boxStyle = {
            margin: "0 auto",
            width: "300px",
            height: "300px",
            textAlign: "center"
        }
        this.imageStyle = {
            marginTop: "100px"
        }
        this.h1 = {
            marginTop: "184px"
        }
    }

    componentDidMount() {
        this.setState({ ready: true });
    }

    static contextTypes = {
        router: PropTypes.object
    }

    playModel() {
        var vid = document.getElementById("videoModel");
        vid.play();
    }

    eventHandler(event) {
        this.playModel();
    }

    playSubmission() {
        var vid = document.getElementById("videoSubmission");
        vid.play();
    }

    otherFunction(event) {
        this.playSubmission();
    }

    vote = (yesOrNo) => {
        var userVideoId = this.state.userVideo._id;
        var userId = this.props.userId;
        this.apiClient.getJSON(this, "/verifyUserVideo", "?userVideoId=" + userVideoId + "&verifyResult=" + yesOrNo + "&userId=" + userId)
            .then((res) => {
                if (res && res.status) {
                    if (res.status === 200) {
                        this.props.onFinish();
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
    }

    getVotes = () => {
        var userVideoId = this.state.userVideo._id;
        this.apiClient.getJSON(this, "/getVotes", "?userVideoId=" + userVideoId)
            .then((res) => {
                if (res && res.status) {
                    if (res.status === 200) {
                        this.setState({ upVotes: res.data.upVotes, downVotes: res.data.downVotes });
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            });
    }

    voteNo = () => {
        this.setState({ index: this.state.index + 1 });
    }

    render() {
        document.title = 'Verify - The ASL Data Community';
        const modelVideoURL = this.state.userVideo.modelVideo.convertedUrl !== undefined ? this.state.userVideo.modelVideo.convertedUrl : this.state.userVideo.modVideo.convertedUrl
        this.eventHandler = this.eventHandler.bind(this);
        this.otherFunction = this.otherFunction.bind(this);
        if (this.state.ready === false) {
            return (
                <Row>
                    <Col xs="12">
                        <div style={this.boxStyle}>
                            <img src="./spin.gif" alt="" style={this.imageStyle} />
                        </div>
                    </Col>
                </Row>
            )
        }
        else if (this.state.ready === true) {
            return (
                <React.Fragment>
                    <Row>
                        <Col xs="12" className="text-center">
                            <p className="h3 mb-2 verifySignName">{this.state.userVideo.modelVideo.name}</p>
                            <p className="h4">{this.state.userVideo.thumbsUp} <FaThumbsUp style={{ color: "#5E1D9F" }} /> {this.state.userVideo.thumbsDown} <FaThumbsDown /></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="text-center">
                            <div className="videoplaceholder verify mb-1">
                                <video id="videoModel" controls autoPlay muted preload='auto' height="100px" style={this.video}>
                                    <source src={modelVideoURL} type="video/webm" ref={this.exampleVid} />
                                    <track id="enTrack" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                            <p><Button className="primary mt-2 mb-2 mr-3 ml-3 btn-primary" target="videoModel" onClick={this.playModel}><Octicon icon={Play} className="height-1rem mr-1" /> Play model sign</Button></p>
                        </Col>
                        <Col md="6" className="text-center">
                            <div className="videoplaceholder verify mb-1">
                                <video id="videoSubmission" controls autoPlay muted preload='auto' height="100px" style={this.video}>
                                    <source src={disambiguateSASURL(this.state.userVideo.convertedUrl, process.env)} type="video/webm" ref={this.userVid} />
                                    <track id="enTrack" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                            <p><Button className="primary mt-2 mb-2 mr-3 ml-3 btn-primary" onClick={this.playSubmission}><Octicon icon={Play} className="height-1rem mr-1" /> Play user-submitted sign</Button></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="text-center">
                            <p className="mb-0 h6">Does the user-submitted sign approximately match the model sign?
                                <a href="#" data-for="userLevelTip1"
                                    aria-label="Please answer “Yes” if it is an exact match, or if it looks a little different but is basically the same sign. Please answer “No” if it has the same/similar meaning but is a different sign, contains unrecognizable signing, or contains other content."
                                    data-tip ><MdInfoOutline className="labelEnglishInfo" /></a></p>
                            <ReactTooltip id="userLevelTip1">
                                <p>Please answer “Yes” if it is an exact match, or if it looks a little different but is basically the same sign. Please answer “No” if it has the same/similar meaning but is a different sign, contains unrecognizable signing, or contains other content.</p>
                            </ReactTooltip>
                            <p><Button className="primary mt-2 mb-2 mr-3 ml-3 pl-5 pr-5 btn-success" onClick={() => this.vote(true)}><Octicon icon={Check} className="height-1rem mr-1" />  Yes</Button>
                                <Button className="primary mt-2 mb-2 mr-3 ml-3 pl-5 pr-5 btn-danger" onClick={() => this.vote(false)}><Octicon icon={X} className="height-1rem mr-1" /> No</Button></p>
                        </Col>
                    </Row>
                </React.Fragment>
            )
            //}
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const VerifyUserVideo = connect(
    
    mapDispatchToProps
)(VerifyUserVideoContainer);

export default VerifyUserVideo;
