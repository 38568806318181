

import React from "react";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { disambiguateSASURL } from "../../utils/urlSAS";

class PaidWikiConsent extends React.Component {
    imgcontainer = {
        height: 40,
        width: 40
    }
    render() {
        return (
            <div>
                <div>
                    <video key="1.1" controls loop preload='auto' style={this.video} style={{ "margin": "0 auto", "height": "350px", "marginTop": "35px" }}>
                        <track id="enTrackWork" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                        {/* eslint-disable-next-line */}
                        <source src={disambiguateSASURL("https://asluservideos.blob.core.windows.net/svgs/Paid_Consent.mp4", process.env)} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>


                <div className="signUpConsentForm">
                    <h2 style={this.h2fontSize}>Microsoft Research Special Project Participation Consent Form </h2>
                    <div>
                        <h3 style={this.h3fontSize}>INTRODUCTION</h3>
                        <p>
                            Thank you for deciding to volunteer in a Microsoft Corporation research project.  You have no obligation to participate and you may decide to terminate your participation at any time.  You also understand that the researcher has the right to withdraw you from participation in the project at any time. Below is a description of the research project, and your consent to participate.  Read this information carefully. If you agree to participate, sign in the space provided.
                        </p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>TITLE OF RESEARCH PROJECT</h3>
                        <p>ASL Dataset Community</p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>PRINCIPAL INVESTIGATOR</h3>
                        <p>Danielle Bragg</p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>PURPOSE</h3>
                        <p>
                            The purpose of this project is to collect sign language videos from volunteer contributors to advance sign language recognition, while fostering American Sign Language (ASL) community online. The website falls under the category of "citizen science", where people contribute for the purpose of advancing science or research. Contributors will be able to do three things: 1) record videos of themselves executing specific signs, 2) validate that other contributors executed signs correctly, and 3) explore the communal dataset.
                        </p>
                    </div>



                    <div>
                        <h3 style={this.h3fontSize}>PROCEDURES</h3>
                        <p>During this project, the following will happen:</p>
                        <ul>
                            <li>You will create a user profile, including a username, email address, and optional picture and demographics.</li>
                            <li>
                                You will then be able to do three main things on the website: 1) record videos of yourself signing, 2) validate that other contributors executed signs correctly, and 3) explore the communal dataset.
                            </li>
                            <li>
                                For every 300 signs you record, we will send you a $30 Amazon gift card, for up to 3000 signs. The gift card will be sent to the email address associated with your profile.
                            </li>
                        </ul>

                        <p>
                            Microsoft may document and collect information about your participation by storing your profile information, the videos you submit, your ratings of other contributors’ videos, and any other interactions with the site.
                        </p>

                        <p>Approximately 60 participants will be involved in this study. </p>

                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>PERSONAL INFORMATION AND CONFIDENTIALITY</h3>
                        <p>Microsoft Research is ultimately responsible for determining the purposes and uses of your personal information. </p>
                        <ul>
                            <li><b>Personal information we collect.</b>
                                During the project we may collect personal information about you such as image, likeness, email, age, gender, and ASL experience.
                            </li>
                            <li>
                                <b>How we use personal information.</b>
                                The personal information and other data collected during this project will be used primarily to perform research for purposes described in the introduction above.   Such information and data, or the results of the research may eventually be used to develop and improve our commercial products, services or technologies.

                            </li>

                            <li><b>Right of Publicity.</b>
                                By submitting video(s) of yourself, you confirm that you are the depicted person in the video(s) you submit and you grant Microsoft an unrestricted, perpetual, worldwide, royalty-free, irrevocable license, with rights to assign and sublicense, to use your image and likeness for the research project above and in any related services, on a worldwide basis.

                            </li>

                            <li><b>How we store and share your personal information.</b>
                                Your personal data will be stored for a period of up to 5 years from your last login.  This project is a collaboration with Boston University, who will have access to collected data. In addition, we may release a dataset that includes videos and other demographics publicly to help advance research.
                            </li>

                            <li><b>How you can access and control your personal information.</b>
                                If you wish to review or copy any personal information you provided during the study, log in to your account to view, edit or delete data from the live site. If you have any additional questions, please email the research team at: aslgames@microsoft.com.  Please note that we will not be able to delete data that has already been shared publicly in a research dataset release. We will respond to questions or concerns within 30 days.
                            </li>

                            <p>For additional information on how Microsoft handles your personal information, please see the <a href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noopener noreferrer">Microsoft Privacy Statement.</a></p>
                        </ul>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>RESEARCH RESULTS & FEEDBACK</h3>
                        <p>
                            Microsoft will own all of the research data and analysis and other results (collectively “Research Results”) generated from the information you provide and your participation in the research project. You may also provide suggestions, comments or other feedback (“Feedback”) to Microsoft with respect to the research project. Feedback is entirely voluntary, and Microsoft shall be free to use, disclose, reproduce, license, or otherwise distribute, and leverage the Feedback and Research Results.
                        </p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}> MICROSOFT AND CONFIDENTIALITY </h3>
                        <p>
                            The research project and information you learn by participating in the project is confidential to Microsoft.  Sharing this confidential information with people other than those we’ve identified above could negatively affect the scientific integrity of the research study and could even make it more difficult for Microsoft to develop new products based on the information obtained in this study. It is therefore important that you do not talk about the project outside of the study team (unless you are legally required to do so by a court or other government order).  This does not apply if the information is general public knowledge or if you have a legal right to share the information.
                        </p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>BENEFITS AND RISKS</h3>
                        <p><b>Benefits:</b>
                            The research team expects to collect videos of diverse signers from this project which we hope will improve the accuracy of sign language recognition systems for diverse signers, for example enabling the creation of drive-through. You will receive any public benefit that may come of these Research Results being shared with the greater scientific community.
                        </p>
                        <p><b>Risks:</b>
                            During participation, you may experience discomfort at contributing videos of yourself. Because you will be able to view videos recorded by other contributors, it is also possible that you will view inappropriate or offensive content. To alleviate this risk, the website allows participants to flag inappropriate content, which will be reviewed by a moderator.
                        </p>
                    </div>
                    <div>
                        <h3 style={this.h3fontSize}>FUTURE USE OF YOUR IDENTIFIABLE INFORMATION  </h3>
                        <p>
                            Identifiers might be removed from your identifiable private information, and after such removal, the information could be used for future research studies or distributed to another investigator for future research studies without your (or your legally authorized representative’s)  additional informed consent,
                        </p>
                    </div>

                    <div>
                        <h3 style={this.h3fontSize}>PAYMENT FOR PARTICIPATION</h3>
                        <p>
                            For every 300 signs you record, we will send you a $30 Amazon gift card, for up to 3000 signs. The gift card will be sent to the email address associated with your profile.
                        </p>
                        <p>
                            Your data may be used to make new products, tests or findings.  These may have value and may be developed and owned by Microsoft and/or others.  If this happens, there are no plans to pay you.
                        </p>
                    </div>

                    <div>
                        <h3 style={this.h3fontSize}>PARTICIPATION</h3>
                        <p>
                            Taking part in research is always a choice. If you decide to be in the study, you can change your mind at any time without affecting any rights including payment to which you would otherwise be entitled. If you decide to withdraw, you should contact the person in charge of this study, and also inform that person if you would like your personal information removed as well.
                        </p>
                        <p>
                            Microsoft or the person in charge of this study may discontinue the study or your individual participation in the study at any time without your consent for reasons including
                        </p>
                    </div>


                    <ul>
                        <li>
                            Your failure to follow directions
                        </li>
                        <li>
                            It is discovered that you do not meet study requirements
                        </li>

                        <li>
                            It is in your best interest medically
                        </li>

                        <li>
                            The study is canceled
                        </li>

                        <li>
                            Administrative reasons
                        </li>

                    </ul>

                    <p>
                        If you leave the study, the study staff will still be able to use your information that they have already collected, however, you have the right to ask for it to be removed when you leave.
                    </p>

                    <p>
                        Significant new findings that develop during the course of this study that might impact your willingness to be in this study will be given to you.
                    </p>

                    <div>
                        <h3 style={this.h3fontSize}>CONTACT INFORMATION </h3>
                        <p>
                            Should you have any questions concerning this project, please contact the research team at aslgames@microsoft.com.
                        </p>
                        <p>
                            Should you have any questions about your rights as a research subject, please contact Microsoft Research Ethics Program Feedback at MSRStudyfeedback@microsoft.com.
                        </p>
                    </div>


                    <div>
                        <h3 style={this.h3fontSize}>CONSENT </h3>
                        <p>
                            By clicking CONTINUE, you confirm that the study was explained to you, you had a chance to ask questions before beginning the study, and all your questions were answered satisfactorily. At any time, you may ask other questions. By clicking CONTINUE, you voluntarily consent to participate, and you do not give up any legal rights you have as a study participant.
                        </p>
                        <p>
                            Please confirm your consent by clicking CONTINUE. If you wish, you may now save a copy of this consent form for future reference. On behalf of Microsoft, we thank you for your contribution and look forward to your research session.
                        </p>
                    </div>


                </div>
            </div>



        )
    }
}

export default PaidWikiConsent
