import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react'
class CustomPageDropdown extends Component {
    onPageChange = (event) => {
        var page = parseInt(event.target.value);
        this.props.setPage(page);
    }
	render() {
		const { maxPages, currentPage} = this.props;
		let pagesObj = [];
		for (let i = 1; i <= maxPages; i++) {
			pagesObj.push({ text: i, value: i });
        }
        let genOption = function(item) {
            return <option key={item.value} value={item.value}>{item.text}</option>;
        };
		return (
            <select aria-label="pagination" value={currentPage} style={this.props.style} className={this.props.className} onChange={this.onPageChange}>
                {pagesObj.map(genOption)}
            </select>
            
		)
	}
}
export default CustomPageDropdown;