import React from "react";
import ReactModal from 'react-modal';
import { Modal } from 'react-bootstrap';
import { invisibleModalStyle, visibleModalStyle, modalStyles } from '../styles/modalStyles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export default class DisappearNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            text2: "",
            symbol: "",
            showInvisibleModal: false,
            background: 'transparent',
            isTransparent: false
        }
    }

    componentDidMount() {
        let self = this;
        //this.setState({ text: self.props.text, showInvisibleModal: self.props.showInvisibleModal, isTransparent: self.props.isTransparent });
    }

    componentDidUpdate(prevProps) {

        if (this.props.text !== prevProps.text) {
            this.setState({ text: this.props.text });
        }

        if (this.props.showInvisibleModal !== prevProps.showInvisibleModal) {
            this.setState({ showInvisibleModal: this.props.showInvisibleModal });
        }

        if (this.props.isTransparent !== prevProps.isTransparent) {
            this.setState({ isTransparent: this.props.isTransparent });
        }

    }

    componentWillReceiveProps(props){
        this.setState({text: props.text, showInvisibleModal: props.showInvisibleModal, isTransparent: props.isTransparent });
    }

    handleCloseInvisibleModal = () => {
        this.setState({ showInvisibleModal: false });
    }

    render() {
        let symbol = "";
        switch (this.props.symbol) {
            case "check-mark":
                symbol = <div style={modalStyles.confirmEmoji}><CheckRoundedIcon className="check-mark" /></div>;
                break;
            case "processing":
                symbol = <div style={modalStyles.confirmEmoji}><MoreHorizIcon className="check-mark" /></div>;
                break;
            default:
                symbol = <div style={modalStyles.confirmEmoji}><CheckRoundedIcon className="check-mark" /></div>;
                break;
        }
        return (
            <React.Fragment>
                <ReactModal
                    isOpen={this.state.showInvisibleModal}
                    background={this.state.background}
                    style={invisibleModalStyle}
                    contentLabel="Verification"
                >
                    <Modal.Body style={this.state.isTransparent ? invisibleModalStyle : visibleModalStyle}>
                        <div style={modalStyles.invisibleClose} onClick={this.handleCloseInvisibleModal} ></div>
                        <div style={modalStyles.levelMessage}>{this.state.text}</div>
                        <div style={modalStyles.levelMessage}>{this.state.text2}</div>
                        {symbol}
                    </Modal.Body>
                </ReactModal>
            </React.Fragment>
        )
    }
}

