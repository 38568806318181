import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import VideoListing from '../video-ui/VideoListing';
import RecordNewModelVideo from '../player-recorder/RecordNewModelVideo';
import SocialIconsHeader from '../social/SocialIconsHeader';
import Switch from "react-switch";
import { Bold } from "@githubprimer/octicons-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiClient from "../../api/ApiClient";
import "../../styles/videoReversal.css";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class ExploreContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            userdata: null,
            userId: this.props.userId,
            userLevel: this.props.userLevel,
            email: this.props.loggedInUser,
            isLoggedIn: this.props.isLoggedIn,
            signedInGroupId: this.props.signedInGroupId,
            openRecordNewModal: false,
            searchText: ""
        }
    }

    componentWillReceiveProps(props) {
        if (props.searchText || props.searchText === "") {
            this.setState({
                searchText: props.searchText
            });
        }
        this.setState({
            userLevel: props.userLevel,
            isLoggedIn: props.isLoggedIn,
            email: props.loggedInUser,
            userId: props.userId,
            signedInGroupId: props.signedInGroupId
        });
    }

    marginTop = {
        marginTop: "50px"
    }

    paddingLeft = {
        paddingLeft: "10px",
        paddingRight: "10px"
    }

    seedVideoBtn = {
        marginTop: "-10px"
    }

    generateSearchOptions = (data) => {
        const uniqueList = {};
        data.forEach((datum) => {
            const name = datum.name;
            if (!uniqueList[name]) {
                const searchTerm = {
                    name: name,
                    value: name
                }
                uniqueList[name] = searchTerm;
            }
        });
        const resArr = Object.values(uniqueList);
        this.props.setDropdownState(resArr);
    }

    onAddModelVideo = () => {
        if (this.props) {
            this.props.history.push("/recordSeedVideo");
        }
    }

    recordCallback = () => {
        this.setState({ openRecordNewModal: false });
    }

    toggleRecordNewPopup = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openRecordNewModal: !prevState.openRecordNewModal
        }));
    }

    pullExplorePageSearchOptions = async () => {
        await this.setState({ searchText: "" })
        this.apiClient.getJSON(this,"/exploreContent/load-all-explore-seeds", "")
        .then(res => {
            if (res && res.status) {
                this.generateSearchOptions(res.data);
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong"
            }
        })
        // eslint-disable-next-line 
        .catch(err => console.log(err))
    }

    async componentDidMount() {
        this.pullExplorePageSearchOptions();
    }


    render() {
        document.title = 'Explore - The ASL Data Community';
        return (
            <React.Fragment>
               
                <VideoListing history={this.props.history} onAddModelVideo={this.onAddModelVideo.bind(this)} signedInGroupId={this.state.signedInGroupId} searchText={this.state.searchText} 
                userLevel={this.props.userLevel} userId={this.props.userId} email={this.props.loggedInUser} 
                updateSelectedUser={(email, userId) => this.props.updateSelectedUser(email, userId)} />
                <div>
                    <Row>
                        {this.state.isLoggedIn && <Col xs="6" className="pt-2">
                            <SocialIconsHeader />
                        </Col>
                        }
                    </Row>
                </div>
                <Modal width="600" isOpen={this.state.openRecordNewModal} toggle={this.toggleRecordNewPopup} className="modal-lg">
                    {/* <ModalHeader toggle={this.toggleRecordNewPopup}>RECORD NEW SEED VIDEO</ModalHeader> */}
                    <ModalBody>
                        <RecordNewModelVideo userId={this.state.userId} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Explore = connect(
    
    mapDispatchToProps
)(ExploreContainer);


export default Explore

