import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check, Play, ArrowLeft, ArrowRight, SignIn, SignOut, Thumbsdown } from '@githubprimer/octicons-react';
import Loader from 'react-loader-spinner';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import Progress from 'react-progressbar';
import { setDurationHack } from "../../services/video/videoUtils";
import { playerRecorderControlText } from '../../styles/playerRecorderStyles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ApiClient from "../../api/ApiClient";
import { Switch, FormGroup, FormControlLabel, createGenerateClassName } from '@material-ui/core';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { disambiguateSASURL } from "../../utils/urlSAS";
import ReportVideoButton from '../report/ReportVideoButton';

class PlayerContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            display: "hide",
            videoPlayback: false,
            isVideoLoaded: false,
            isCountdownActive: false,
            playbackRate: 1,
            currentVideoDuration: null,
            currentVoteUp: undefined,
            voteUpCount: 0,
            currentVoteDown: undefined,
            voteDownCount: 0
        }
        this.recorder = null;
        this.video = React.createRef();
        this.buttonSubmit = React.createRef();
    }

    // Styles
    videoPlaceholderStyle = {
        border: "1px solid gray",
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        borderRadius: "10px",
        cursor: "pointer"
    }

    vote = async (yesOrNo) => {
        const res = await this.apiClient.getJSON(this, "/verifySentenceVideo", "?userVideoId=" + this.props.currRecording._id + "&verifyResult=" + yesOrNo + "&userId=" + this.props.loggedInUser);
        if (res && res.status === 200) {

            const pointsMod = res.data;
            this.props.dispatch(this.props.updateScore(pointsMod, this.props.loggedInUser, this.apiClient));
        }
        else if (res && res.httpServerError) {
            // throw "Something Went Wrong"
        }
    }

    getVotes = async (recId) => {
        const res = await this.apiClient.getJSON(this, "/getVotes", "?userVideoId=" + recId);
        if (res && res.status) {
            return res.data;
        }
        else if (res && res.httpServerError) {
            // throw "Something Went Wrong"
        }
    }

    // Why is React so popular and yet garbage? Fix this ridiculous code dup nonsense.
    async componentDidMount() {

        var self = this;
        if (self.props.recordTransitionState && !this.state.isCountdownActive) {
            await this.setState({ isCountdownActive: true })
            this.initiateRecorder();
        }
        this.setState(
            {
                uiControls: self.props.controls.map((c, i) => {
                    // Inject playback speed, possibly a better way to do this, but it eludes me.
                    return (
                        c.control[c.selectedIndex].ui
                    );
                })
            });

        // Select current video
        this.setState({
            currentVideoSelection: "https://asluservideos.blob.core.windows.net/convertedvideos/TestImage.mp4",
            isVideoLoaded: true
        });
    }

    async componentWillReceiveProps() {
        var self = this;
        if (self.props.recordTransitionState && !this.state.isCountdownActive) {
            await this.setState({ isCountdownActive: true })
            this.initiateRecorder();
        }
        this.setState(
            {
                uiControls: self.props.controls.map((c, i) => {
                    // Inject playback speed, possibly a better way to do this, but it eludes me.
                    return (
                        c.control[c.selectedIndex].ui
                    );
                })
            });
        // try {}
        if (this.props.currRecording && this.state.currRecording !== this.props.currRecording && (this.props.currRecording._id !== this.state.currentTargetId)) {
            await this.setState({
                currentTargetId: this.props.currRecording._id
            });
            // Get votes is slow, prevent rapid clicking. Should really get votes all at once...
            try {
                //await this.setState({ currRecording: this.props.currRecording });
                const allVotes = await this.getVotes(this.props.currRecording._id);
                const userVoteStatus = await this.apiClient.getJSON(this, "/verifySentenceVideo/userVoteStatus", "?userVideoId=" + this.props.currRecording._id + "&userId=" + this.props.loggedInUser);
                if (!userVoteStatus) {
                    return;
                }
                await this.setState({
                    currentVoteDown: userVoteStatus.currentVoteDown,
                    currentVoteUp: userVoteStatus.currentVoteUp,
                    voteUpCount: allVotes.upVotes,
                    voteDownCount: allVotes.downVotes
                });
            }
            catch (e) {

            }
        }

        // Select current video
        this.setState({
            currentVideoSelection: "https://asluservideos.blob.core.windows.net/convertedvideos/TestImage.mp4",
            isVideoLoaded: true
        });
    }

    voteUp() {
        if (this.state.currentVoteUp) {
            this.setState({ currentVoteUp: false, voteUpCount: this.state.voteUpCount - 1 });
            this.vote("undo")
        }
        else {
            this.setState({ currentVoteUp: true, voteUpCount: this.state.voteUpCount + 1 });
            this.vote(true)
        }

        if (this.state.currentVoteDown) {
            this.setState({ currentVoteDown: !this.state.currentVoteDown, voteDownCount: this.state.voteDownCount - 1 });
        }
    }

    voteDown() {
        if (this.state.currentVoteDown) {
            this.setState({ currentVoteDown: false, voteDownCount: this.state.voteDownCount - 1 });
            this.vote("undo")
        }
        else {
            this.setState({ currentVoteDown: true, voteDownCount: this.state.voteDownCount + 1 });
            this.vote(false)
        }

        if (this.state.currentVoteUp) {
            this.setState({ currentVoteUp: !this.state.currentVoteUp, voteUpCount: this.state.voteUpCount - 1 });
        }
    }

    flagRemoval = () => {

    }


    render() {
        return (
            <React.Fragment>
                <div className="videoBox">
                    <div className="video-container">
                        {this.props.currRecording &&
                            <div >
                                
                                <video className="player-recorder"
                                    onLoadedData={($event) => this.props.play(true)}
                                    src={disambiguateSASURL(this.props.currRecording.convertedUrl, process.env)}
                                    ref={this.props.setRef}
                                    muted
                                    style={this.videoStyle}
                                />
                            </div>
                        }

                        {(this.state.isVideoLoaded && this.props.currRecording && this.props.currentVideoTimeProgress > 5) &&
                            <Progress className="video-progress-bar" color="#0EBE0E" completed={this.props.currentVideoTimeProgress} />
                        }
                    </div>
                    {!this.state.isVideoLoaded &&
                        <div className="transition-counter">
                            <Loader
                                type="Puff"
                                color="#9BD5FB"
                                height={80}
                                width={80}
                            />
                        </div>
                    }
                    {((!this.props.currRecording || !this.props.currRecording.convertedUrl)) &&
                        <div className="overlay-row please-select" >
                            To watch videos, click on article text that has recordings.
                        </div>
                    }
                </div>
                {(this.state.isVideoLoaded && this.props.currRecording) &&
                    <React.Fragment>
                        <div className="video-controls-cont" >
                            <div className="video-controls-box">

                                {this.state.uiControls}
                                <span className="txt-control" style={playerRecorderControlText}>
                                    {this.props.video && this.props.video.playbackRate && this.props.video.playbackRate + "x speed"}
                                </span>
                            </div>
                            {/* <div className="vote-box">
                                <div>Is this recording accurate?</div>
                                <button className="thumb up" onClick={() => this.voteUp()} style={{ background: this.state.currentVoteUp && "rgba(14, 190, 14,1.0)" }}>
                                    Yes
                                </button>
                                <button className="thumb down" onClick={() => this.voteDown()} style={{ background: this.state.currentVoteDown && "rgba(255, 0, 0,1.0)" }}>
                                    No
                                </button>
                                <div className="wiki-flag">
                                    <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={this.props.currRecording} loggedInUserId={this.props.loggedInUser} />
                                </div>
                            </div> */}
                            <div className="mode-toggle play">
                                <FormGroup className="switch">
                                    <FormControlLabel color="secondary" className="switch"
                                        control={<Switch
                                            thumbStyle={{ backgroundColor: 'red' }}
                                            trackStyle={{ backgroundColor: 'green' }}
                                            className="switch" checked={this.props.autoReadMode} onChange={this.props.toggleAutoRead} />}
                                        label="Continuous Play"
                                    />
                                </FormGroup>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}



const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Player = withRouter(connect(
    
    mapDispatchToProps
)(PlayerContainer));

export default Player;
