export default class FacebookAuthService{
    
    getFacebookAccount = () =>{
        return new Promise(function(resolve, reject){
            if (window.FB !== undefined && window.FB !== null) {
            window.FB.getLoginStatus(function(response){
                if(response.status === 'connected'){
                    var uid = response.authResponse.userID
                    var path = '/'+uid;
                    window.FB.api(path, { locale: 'en_US', fields: 'name, email' },
                        function(response) {
                            if(response){
                                response.userID = uid;
                                resolve(response);
                            }
                    });
                }else{
                }
            })
        }
        });
    }
    logOut = () =>{
        window.FB.logout(function(response) {
        });
    }
}