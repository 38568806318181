// eslint-disable-next-line
import React, { Component } from 'react';
import RecordRTC from 'recordrtc';
import { Row, Col } from 'reactstrap';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check } from '@githubprimer/octicons-react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import ApiClient from "../../api/ApiClient";
import { disambiguateSASURL } from "../../utils/urlSAS";
import { invisibleModalStyle, modalStyles, loaderStyle } from '../../styles/modalStyles';
import Loader from 'react-loader-spinner';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import { tempMirrorFirefox } from "../../utils/utilMethods";
import "../../styles/mirror.css";

class WebRecorderExploreContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.recorder = null;
        this.video = React.createRef();
        this.buttonStop = React.createRef();
        this.buttonStart = React.createRef();
        this.buttonSubmit = React.createRef();
        this.recordingStart = this.recordingStart.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.timerId = null;
        this.state = {
            playIcon: PrimitiveDot,
            display: "hide",
            response: '',
            post: '',
            recordState: false,
            submitState: false,
            responseToPost: '',
            file: null,
            isSubmitting: false,
            videoDownload: null,
            userName: null,
            showControls: false,
            recordTime: 0,
            showTimer: false,
            signedInGroupId: this.props.signedInGroupId,
            videoKey: ''
        }
    }

    startTimer = () => {
        this.setState({ recordTime: 0 });
        this.timerId = setInterval(() => this.setState({ recordTime: this.state.recordTime + 1 }), 1000);
    }
    stopTimer = () => {
        clearInterval(this.timerId);

    }

    // async componentWillUnmount() {

    //     this.video.srcObject = null;

    // }

    componentDidMount() {
        this.video.current.src = null;
        this.video.current.srcObject = null;

        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
            this.video.current.srcObject = camera;
            this.video.current.style.transform = 'scale(-1, 1)';
        });
        this.cameraCleanup = this.props.history.listen((location, action) => {
            try {
    
                this.video.current.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });
    
            }
            catch (e) {
            }
        });
    }


    componentWillUnmount() {
        this.cameraCleanup();
    }
  

    blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    redo = () => {


        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
            tempMirrorFirefox("video-recorder-explore", true)
            this.video.current.src = null;
            this.video.current.srcObject = null;
            this.video.current.srcObject = camera;
            let self = this;
            this.setState({ showControls: false, showTimer: true, recordState: true, submitState: false });
            this.video.current.muted = true;
            this.video.current.volume = 0;
            this.recorder = RecordRTC(this.video.current.srcObject, {
                type: 'video/mp4',
                mimeType: 'video/mp4'
            });
            this.recorder.startRecording();
            this.startTimer();
        });


    }



    recordingStart() {
        tempMirrorFirefox("video-recorder-explore", true)
        if (this.video.current.srcObject) {
            let self = this;
            this.setState({ showControls: false, showTimer: true, recordState: true });
            this.video.current.muted = true;
            this.video.current.volume = 0;
            this.recorder = RecordRTC(this.video.current.srcObject, {
                type: 'video/mp4',
                mimeType: 'video/mp4'
            });
            this.recorder.startRecording();
            this.startTimer();
        }
    }

    stopRecording() {

        this.setState({
            recordState: false,
            submitState: true
        });
        tempMirrorFirefox("video-recorder-explore", false)
        this.recorder.stopRecording(() => this.stopRecordingCallback());
        //this.stopRecordingCallback();
    }
    stopRecordingCallback() {
        this.video.current.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
        this.stopTimer();
        this.video.current.muted = false;
        this.video.current.volume = 1;
        this.video.current.srcObject = null;
        this.video.current.src = null;
        this.video.current.src = URL.createObjectURL(this.recorder.getBlob());
        this.setState({ videoDownload: this.blobToFile(this.recorder.getBlob(), this.props.video.name + ".mp4"), showControls: true, showTimer: false, recordTime: 0, playIcon: Sync, display: "" });

    }

    handleSubmit = async () => {
        if (!this.state.preventAction) {
            await this.setState({ preventAction: true });
            await this.setState({ isSubmitting: true })
            // eslint-disable-next-line
            const data = new FormData();
            // data.append('filename', 'this.props.video.nae');
            data.append('videoname', this.props.video.name);
            data.append('userid', this.props.userid);
            data.append('refID', this.props.video._id);
            data.append('count', this.props.video.count);
            data.append('file', this.state.videoDownload);
            data.append('groupId', this.state.signedInGroupId === undefined ? "" : this.state.signedInGroupId);

            let vresponse = await this.apiClient.postJSON(this,'/add-content', data, true);

            if (vresponse && vresponse.status) {

                this.setState({ responseToPost: vresponse });

                let parent = this;

                setTimeout(function () {
                    parent.props.onFinish();;
                }, 1500);

                if (response.status === 200) {
                    await this.setState({ preventAction: false });
                    this.props.dispatch(this.props.updateScore(2, this.props.userId, this.apiClient));
                }
            }
            else if (vresponse && vresponse.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        }

    };

    render() {
        if (this.state.isSubmitting) {
            return (
                <div className="recorder-extension-body">

                    <div style={loaderStyle}>
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                        Uploading Video...
                </div>
                </div>
            )
        }
        else if (this.props.video === null) return null
        // eslint-disable-next-line
        else {
            return (
                <React.Fragment>
                    <div className="recorder-extension-body">

                        <div className="outer-container-r">
                            <div className="inner-container-r">
                                {this.state.showTimer && <div className="video-overlay"><span>REC {this.state.recordTime}</span></div>}
                                <video className="video-r video-go" id="video-recorder-explore" controls={this.state.showControls} autoPlay playsInline ref={this.video} height="357px"></video>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            {this.state.recordState &&
                                <div className="stop" style={this.controls} onClick={this.stopRecording} >

                                    <StopRoundedIcon
                                        style={{ ...playerRecorderControls, color: "red" }}
                                        className="control"
                                        ref={this.buttonStop} aria-label="Stop"

                                    />
                                </div>
                            }
                            {(!this.state.recordState && !this.state.submitState) &&

                                <button onClick={this.recordingStart} aria-label="submit new recording" className="record-btn" aria-label="submit new recording" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="white" />
                                    <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z" fill="white" />
                                </svg><span className="inner-record-btn-label">Record</span></button>

                            }
                            {this.state.submitState &&
                                // <button type="submit|" className="record-btn" ref={this.buttonSubmit} display={this.state.display} aria-label="Submit video">
                                //     Submit
                                // </button>
                                <div className="submit flex-row-center">
                                    <div style={{ marginRight: "10px", color: "#333333" }} onClick={this.redo}>Redo</div>
                                    <div className="recorder-control" onClick={this.redo}>
                                        <RedoRoundedIcon type="submit|" className="re-record-btn"

                                            style={{ ...playerRecorderControls, color: "red" }}
                                            ref={this.buttonSubmit} aria-label="Submit video" />
                                    </div>
                                    <div style={{ marginRight: "10px", color: "#333333" }} onClick={this.handleSubmit}>Submit</div>
                                    <div className="recorder-control" onClick={this.handleSubmit}>
                                        <div>
                                            <CheckBoxRoundedIcon className="submit-btn"
                                                style={{ ...playerRecorderControls, color: "rgb(14, 190, 14)" }}
                                                ref={this.buttonSubmit} aria-label="Submit video" />
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}



const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const WebRecorderExplore = withRouter(connect(
    
    mapDispatchToProps
)(WebRecorderExploreContainer));

export default WebRecorderExplore