import React from 'react';
import queryString from 'query-string';
import ApiClient from "../../api/ApiClient";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class GoogleAuthIntermediateContainer extends React.Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
    }

    reestablishUserAccount = () => {
        return new Promise(async (resolve, reject) => {
            const res = await this.apiClient.postJSON(this, "/deleteUserAccount/reestablish");
            if (res && res.status) {
                if (res.status === 200) {
                    resolve(true)
                }
                else {
                    reject(false)
                }
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        })

    }

    async componentDidMount() {
        // let tokenExists = true
        // let urlToken = localStorage.getItem('jwtToken');
        // if (!urlToken) {
        //     tokenExists = false;

        let urlToken = window.location.search.split("?token=")[1].split("&accountType=")[0];
        let accountType = window.location.search.split("&accountType=")[1]
        let self = this;
        

        //}
        this.props.apiClient.getJSON(null, '/getUser/requestSignedToken', '?jwtToken=' + urlToken + "&signinType=goog")
            .then(async (res) => {
                
                if (res && res.status) {
                    // If Account already exists, redirect to homepage
                    if (res.data.jwtToken && res.data.user) {
                        const { isAccountReestablished } = res.data
                        // Ensure that user jwt token exists, plant new one in case of sign out
                        localStorage.setItem('jwtToken', res.data.jwtToken);
                        if (isAccountReestablished) {
                            this.reestablishUserAccount().then((reRes) => {
                                if (reRes && reRes.status) {
                                    if (reRes.data) {

                                        alert("We noticed you are logging into an account in the process of being deleted. If this is a mistake, please feel free to re-request account deletion again. You can create a fresh account by switching social providers.");
                                        window.location.href = process.env.REACT_APP_CLIENT_URL;
                                    }
                                    else {
                                        alert("Something went wrong, please contact us")
                                    }
                                }
                                else if (reRes && reRes.httpServerError) {
                                    // throw "Something Went Wrong With User Action";
                                }
                            })
                        }
                        else {
                            const appRoot = process.env.REACT_APP_CLIENT_URL;
                            window.location.href = appRoot
                        }
                    }
                    // Create new account
                    else {

                        // Reroute to generate account view TODO Refactor this ugly API code
                        //localStorage.setItem('jwtToken', urlToken);
                        //let tokenData =  await this.props.apiClient.getJSON(this,'/getUser/decodeAndGenerateAccount', '?jwtToken='+urlToken);
                        this.props.history.push("/tpSignUp", {
                            email: res.data.email,
                            urlToken: urlToken,
                            signinProvider: "Google",
                            oldJWTToken: res.data.oldJWTToken,
                            loginConsentType: accountType
                            // userId: tokenData.sub,
                            // thirdPartyUserid: tokenData.sub,
                            // uid: tokenData.uid
                        });
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });
    }



    render() {
        return (
            <div></div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const GoogleAuthIntermediate = connect(
    
    mapDispatchToProps
)(GoogleAuthIntermediateContainer);

export default GoogleAuthIntermediate
