import React from "react";

export default class SocialIconsHeader extends React.Component{

    header ={
        float:"right"
    }

    iconStyle = {
        height:"40px",
        width:"40px"
    }

    render() {
    return(
            <div className="socialMediaIcons" style={this.header}>
            {/* <SocialIcon style={this.iconStyle} url="https://www.facebook.com/sharer/sharer.php?u=https://community.aslgames.org&t=ASL%20Games" network="facebook"fgColor="white" label="Click to share the ASL site through facebook" target="_blank" rel="noopener noreferrer"/>

            <SocialIcon style={this.iconStyle} url="https://twitter.com/intent/tweet?text=Hello%20world&url=https://community.aslgames.org" label="Click to share the ASL site through twitter" target="_blank" rel="noopener noreferrer" network="twitter"fgColor="white"/>

            <SocialIcon style={this.iconStyle} url="http://pinterest.com/pin/create/button/?url=https://community.aslgames.org&description=ASL%20Games" className="pin-it-button" label="Click to share the ASL site through pinterest" target="_blank" rel="noopener noreferrer" network="pinterest"fgColor="white"/>

            <SocialIcon style={this.iconStyle} url="https://www.linkedin.com/shareArticle?mini=true&url=https://community.aslgames.org&title=ASL%20Games&summary=ASL%20Games" label="Click to share the ASL site through linkedin" target="_blank" rel="noopener noreferrer" network="linkedin"fgColor="white"/> */}
            </div>
        )
    }
}