import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check, Play, ArrowLeft, ArrowRight, SignIn, SignOut, Thumbsdown } from '@githubprimer/octicons-react';
import Loader from 'react-loader-spinner';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import Progress from 'react-progressbar';
import { setDurationHack } from "../../services/video/videoUtils";
import { disambiguateSASURL } from "../../utils/urlSAS";
import { tempMirrorFirefox } from "../../utils/utilMethods";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';


class WikiRecorder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "hide",
            videoPlayback: false,
            isCountdownActive: false,
            currentVideoDuration: null,
            currSentence: null,
            value: this.props.value
        }
        this.recorder = null;
        this.video = React.createRef();
        this.buttonSubmit = React.createRef();
    }

    // Styles
    videoPlaceholderStyle = {
        border: "1px solid gray",
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        borderRadius: "10px",
        cursor: "pointer"
    }

    // Timeouts (for counter)
    countdown = null;
    innerCountdownTimeout = null;
    outerInitTimeout = null;
    innerInitTimeout = null;

    // For crazy clicks between recordings
    async clearRecTime() {
        await this.setState({ isTimerVisible: false })
        return await new Promise(async (acc, rej) => {
            await clearInterval(this.countdown)
            await clearTimeout(this.innerCountdownTimeout)
            acc(true)
        })

    }

    async clearCountdown() {
        await this.setState({ isTimerVisible: false })
        return await new Promise(async (acc, rej) => {
            await clearTimeout(this.outerInitTimeout)
            await clearTimeout(this.innerInitTimeout)
            await this.setState({ counter: "", recordLoader: false });
            acc(true)
        })

    }

    async startCountdown() {

        await this.clearCountdown();
        await this.clearRecTime();
        let counter = 3;
        await this.setState({ counter: counter, isCountdownActive: true });
        counter--;
        this.countdown = setInterval(() => {
            if (counter === 0) {
                //alert("Actual Start")
                clearInterval(this.countdown);
                this.setState({ counter: "Start" })
                this.innerCountdownTimeout = setTimeout(async () => {
                    await this.setState({ isCountdownActive: false, isTimerVisible: true })
                    await this.setState({ counter: "" })
                }, 1000)
            }
            else {
                this.setState({ counter: counter })
                counter--;
            }
        }, 800)
    }

    async initiateRecorder() {
        await this.setState({ isTimerVisible: false })
        await this.clearCountdown();
        await this.clearRecTime();
        await this.setState({ recordLoader: true })
        this.outerInitTimeout = setTimeout(async () => {
            await this.setState({ isTimerVisible: false })
            await this.setState({ recordLoader: false })
            this.innerInitTimeout = setTimeout(async () => {
                await this.setState({ isTimerVisible: false })
                this.startCountdown()
            }, 500)

        }, 1000)
    }

    async componentWillReceiveProps(nextProps) {

        if (nextProps) {

            if (nextProps.currSentence && nextProps.currSentence !== this.state.currContext) {
                await this.setState({ isCountdownActive: false });
                await this.clearCountdown();
                await this.clearRecTime();
                await this.setState({ currContext: nextProps.currSentence });
            }
            else if (!nextProps.currSentence && nextProps.currSentence !== this.state.currContext) {
                await this.setState({ currContext: nextProps.currSentence });
            }
        }

        if (this.props.recordTransitionState && !this.state.isCountdownActive) {
            await this.setState({ isCountdownActive: true })
            this.initiateRecorder();
        }
        // if (props.controls && props.controls.length) {
        //     this.setState(
        //         {
        //     })
        // }
    }

    componentDidMount() {
        //this.initiateRecorder();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }


    render() {
        /*
            Possible UI Control States
            recordNext : Not moving to next section yet, but video confirmed
            finishSection: Similar to recordNext but for section enders
            confirmOverride: Confirm state particular to your already recorded and saved videos on re-record click
            confirm: Videos not yet saved
            record: Standard record mode
        */
        const uiControls = this.props.controls ? this.props.controls.map((c) => {
            return (
                c.control[c.selectedIndex].ui
            );
        }) : []
        return (
            <React.Fragment>
                {/* <div>{ this.state.isTimerVisible && <span>{this.state.isTimerVisible.toString()}</span> }</div> */}
                <div className="videoBox" key={this.props.currSentence.toString()}>

                    {/* Display that shows up when you have recording to override */}
                    {(this.props.overwriteVideo && this.props.overwriteVideo.convertedUrl) &&
                        <div className="video-container">
                            <video className="player-recorder"
                                src={disambiguateSASURL(this.props.overwriteVideo.convertedUrl, process.env)}
                                ref={this.props.setRef}
                            />
                            {this.props.controlsVisible &&
                                <Progress className="video-progress-bar" color="#0EBE0E" completed={this.props.currentVideoTimeProgress} />
                            }
                        </div>
                    }

                    {/* Display shows only when recording has just been made in this session and someone clicks to change it */}
                    {(this.props.overwriteVideo && !this.props.overwriteVideo.convertedUrl) &&
                        <div className="video-container msg-rc">
                            <div>You have a recorded video here that is processing. Please refresh page if you wish to record an updated version, or you can view it in read mode.
                            </div>
                            <button onClick={this.props.toggleMode} className='record-btn' style={{marginTop: "20px"}}>Read Mode</button>
                        </div>
                    }



                    {(this.props.isRecorderLoaded && !this.props.overwriteVideo) &&
                        <div className="video-container">
                            <video id="recorder-video" className="player-recorder"
                                onLoadedData={($event) => setDurationHack(this, $event)}
                                ref={this.props.setRef}
                                muted
                                style={this.videoStyle}
                            />
                            {(this.props.isRecording && this.state.isTimerVisible)
                                && <div className="recording-time-indicator">{this.props.recordingTime}</div>
                            }
                            {(!this.props.recordTransitionState && !this.props.isRecording && this.props.controlsVisible)
                                && <Progress className="video-progress-bar" color="#0EBE0E" completed={this.props.currentVideoTimeProgress} />
                            }
                        </div>
                    }


                    { (!this.props.overwriteVideo || this.props.overwriteVideo.convertedUrl) &&
                    <React.Fragment>

                    
                    {/* Transition Overlay with UI controls */}
                    {((this.props.selectedControlsName === "confirm")
                        && (this.props.isPlaying !== true) && this.props.controlsVisible) &&
                        <div className="video-transition-overlay" style={{ height: "100px", padding: "50px" }}>
                            <div className="video-confirm-row">
                                <div className="video-confirm-col">Redo</div>
                                <div className="video-confirm-col">Keep</div>
                            </div>

                            <div className="video-confirm-row">
                                <div className="video-confirm-col">{uiControls[0]}</div>
                                <div className="video-confirm-col">
                                    {uiControls[2]}
                                </div>
                            </div>
                        </div>
                    }

                    {(this.props.selectedControlsName === "confirmOverride"
                        && (this.props.isPlaying !== true) && this.props.controlsVisible) &&
                        <div className="video-transition-overlay" style={{ height: "100px", padding: "50px" }}>
                            <div className="video-confirm-row re-record-text">

                            </div>

                            <div className="video-confirm-row">
                                <div style={{ marginRight: "30px" }}>
                                    <div className="video-confirm-col" style={{ marginTop: "5px", zIndex: "9999" }}>Redo?</div>
                                    <div className="video-confirm-col" style={{ zIndex: "99999" }}>{this.props.redoBtn.control[0].ui}</div>
                                </div>
                                <div>
                                    <div className="video-confirm-col" style={{ marginTop: "5px", zIndex: "9999" }}>Keep?</div>
                                    <div className="video-confirm-col" style={{ zIndex: "99999" }}>
                                        <CheckBoxRoundedIcon
                                            onClick={() => this.props.hideWindowControls()}
                                            style={{ ...playerRecorderControls, color: "rgb(14, 190, 14)", border: "unset" }} aria-label="Ok" className="control" /></div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Transition Overlay */}
                    {((!this.props.overwriteVideo && (this.props.selectedControlsName === "recordNext" || this.props.selectedControlsName === "finishSection"))
                        && this.props.controlsVisible) &&
                        this.props.videoTransitionOverlay
                    }

                    <div className="transition-counter">
                        {this.state.recordLoader &&
                            <Loader
                                type="Puff"
                                color="#9BD5FB"
                                height={80}
                                width={80}
                            />
                        }
                        {(!this.state.recordLoader && (this.state.counter <= 3 || this.state.counter === "Start")) &&
                            (<span>{this.state.counter}</span>)
                        }
                    </div>

                    {(!this.props.isRecorderLoaded && !this.props.overwriteVideo) &&
                        <div className="overlay-row record-message"  >
                            To record videos, click on article text to the right.
                    </div>
                        // onClick={() => this.props.initializeRecorderFunc(true)}
                    }
                    </React.Fragment>
                }

                </div>

                { (!this.props.overwriteVideo || this.props.overwriteVideo.convertedUrl) &&
                    <React.Fragment>

                <div className="video-controls-box">
                    {((this.props.controlsVisible && this.props.selectedControlsName !== "finishSection" && this.props.selectedControlsName !== "recordNext")) &&
                        <React.Fragment>
                            {/* Hacky, but works, if ony record button visible, hide, refactor this awful mess */}
                            {(this.props.redoBtn && this.props.isPlaying) &&
                                <span>
                                    <span className="re-record-text"></span>
                                    {this.props.redoBtn.control[0].ui}
                                </span>
                            }
                            {/* UI controls visible during recording */}
                            {(this.props.selectedControlsName === "record"
                                && ((this.props.isRecorderLoaded && this.props.isRecording && this.state.isTimerVisible)
                                    || (uiControls && uiControls.length) !== 1)) &&
                                <span>
                                    {uiControls}

                                </span>
                            }
                            {(this.props.selectedControlsName === "confirm" && this.state.isTimerVisible) &&
                                <span>

                                    {uiControls[1]}
                                </span>
                            }
                            {(this.props.selectedControlsName === "confirmOverride") &&

                                <span>

                                    {uiControls[1]}
                                </span>
                            }
                            {(this.props.confirmBtn && this.props.isPlaying && this.state.isTimerVisible) &&
                                <span>

                                    {this.props.confirmBtn.control[0].ui}
                                </span>
                            }
                        </React.Fragment>
                    }
                </div>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default WikiRecorder;
