import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Octicon, { DeviceCameraVideo } from '@githubprimer/octicons-react';
import WebRecorderExplore from './WebRecorderExplore';
import { capitalize } from "../../utils/textMethods";
import { disambiguateSASURL } from "../../utils/urlSAS";
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';

class RecordNewVideo extends Component {

    constructor(props) {
        super(props);
        //this.props = this.props ? this.props : this.props;
        this.state = {
            userVideo: this.props.userVideo,
            showModelVideoDiv: true,
            showSelfRecordDiv: false,
            signedInGroupId: this.props.signedInGroupId
        }
    }

    heading = {
        width: "300px",
        margin: "24px auto .7rem",
        textAlign: "center"
    }

    video = {
        width: "100%",
        position: "relative",
        display: "block",
        margin: "0 auto"
    }

    componentDidMount() {
;
    }

    componentWillUnmount() {

    }


    //onClick = () => this.props.history.push(`/webcam/${this.state.userVideo.modelVideo.name}`);

    onClick = () => {
        this.setState({
            showModelVideoDiv: false,
            showSelfRecordDiv: true
        })
    }

    newVideo = () => this.props.newVideo();

    render() {
        const showModelDiv = this.state.showModelVideoDiv ? {} : { display: 'none' };
        const showRecordDiv = this.state.showSelfRecordDiv ? {} : { display: 'none' };

        if (this.state.userVideo.modelVideo.convertedUrl === null) return null
        document.title = 'Record - The ASL Data Community'
        return (
            <Row className="modal-font">
                <Col xs="12" className="pt-2 pb-2" style={showModelDiv}>
                    <p className="mb-2">Study the sign for the word provided. When you&apos;re ready click &quot;Record&quot; to record yourself providing the same sign for the word.</p>
                    <div className="text-center">
                        <h2 className="h3">{this.state.userVideo.modelVideo.name}</h2>
                        <div className="videoplaceholder">
                            <video controls autoPlay loop preload='auto' style={this.video}>
                                <track id="enTrack" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                {/* eslint-disable-next-line */}
                                <source src={disambiguateSASURL(this.state.userVideo.modelVideo.convertedUrl, process.env)} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                            </video>
                        </div>

                        <div onClick={this.onClick} className="overlay-row record-again-btn" style={{ margin: "0 auto", background: "#7A22D", cursor: "pointer", width: "125px", padding: "unset" }} onClick={this.onClick} >
                            <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "white", marginBottom: "2px" }} />
                                            Record
                                        </div>




                    </div>
                </Col>
                <Col sm="12" className="pt-2 pb-2 record-yourself" style={showRecordDiv}>
                    <h1 className="clipped">Record yourself signing the word {this.state.userVideo.modelVideo.name}</h1>
                    <div className="text-center">
                        <h2 className="h3">{this.state.userVideo.modelVideo.name}</h2>
                        <WebRecorderExplore history={this.props.history} video={this.state.userVideo.modelVideo} userName={this.props.email} userid={this.props.userId} onFinish={this.props.onFinish} signedInGroupId={this.props.signedInGroupId} />
                    </div>
                </Col>
            </Row>
        )
    }
}

// RecordNewVideo.propTypes = {
//     history: PropTypes.object,
//     video: PropTypes.object,
//     newVideo: PropTypes.func
// };

export default RecordNewVideo;
