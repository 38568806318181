import React, { Component } from "react";
import PropTypes from 'prop-types';
import Filter from '../misc/CustomFilter';
import CustomPageDropdown from '../misc/CustomPageDropdown';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
// eslint-disable-next-line
import Griddle, { components, plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import ApiClient from "../../api/ApiClient";
import { disambiguateSASURL } from "../../utils/urlSAS";
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class DuplicateSeedVideosContainer extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props);
       this.apiClient = new ApiClient();
        this.state = {
            userId:this.props.userId,
            data : this.props.seedVideosData,
            userLevel: this.props.userLevel,
            userVideo:null,
            videoCount:this.props.seedVideosData.length
        }
        
    }

    componentWillReceiveProps(props) {
        this.setState({userId:props.userId});
    }

    videoCount = {
        float:"right",
        padding:"10px 50px 0 0"
    }

    flggedFontSize = {
        fontSize:"13px"
    }

    marginTop = {
        marginTop: "20px",
        marginBottom: "0px"
    }

    CustomFilter = () => {
        var onChange = (e) => {
            this.props.onChange(e.target.value);
          };
    }
        NewLayout = ({ Table, Pagination, Filter, SettingsWrapper }) => (
            <div>
                <div className="filterdiv">
                    <Filter />
                    <Pagination />
                </div>
                <Table />
            </div>
        );

    render() {
        const CustomRowComponent = connect((state, props) => ({
            rowData: plugins.LocalPlugin.selectors.rowDataSelector(state, props)
        }))(({ rowData }) => ( 
            <tr style={{
                backgroundColor: "#EDEDED",
                border: "1px solid #777",
                padding: 5,
                margin: 10,
            }}>
                <td className="videoListCell">
                    {/* <div className="video-thumb"><img src={rowData.thumb} alt="" /></div> */}
                    <div className="video-thumb">
                        <video id={`userVideoModel_${rowData.convertedUrl}`} controls muted preload='auto' key={rowData.convertedUrl}>
                            <source src={rowData.convertedUrl} type="video/webm" ref={this.exampleVid} />
                            <track id={`enTrack_${rowData.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>

                    <div className="video-extrainfo">
                        <div className="video-description">
                            <h3 className="video-name h4">
                                {rowData.name}
                            </h3>
                            {/* <p className="video-author">
                                <i>Submitted by </i>
                            </p> */}
    
                            {/* <p className="video-rating">
                                Accuracy {rowData.rating}
                            </p> */}
                        </div>
                    </div>
                </td>
            </tr>
        ));

        return (
            // <div>
            // <Row>
            // <Col md="8">
            <div className="gridwrapper">
                <span style={this.videoCount}><b>Total Video Count: {this.state.videoCount}</b></span>
                <Griddle
                    data={this.state.data}
                    pageProperties={{
                        currentPage: 1,
                        pageSize: 4,
                        recordCount: this.state.data ? this.state.data.length : 0 ,               
                    }}
                    plugins={[plugins.LocalPlugin]}
                    sortProperties={this.sortProperties}
                    components={{
                        Filter:Filter,
                        Row: CustomRowComponent,
                        Layout: this.NewLayout,
                        PageDropdown : components.PageDropdownEnhancer(components.PageDropdownContainer(CustomPageDropdown)),
                    }}
                    styleConfig={{
                        classNames: {
                            NextButton: 'btn btn-primary ml-3',
                            PreviousButton: 'btn btn-primary mr-3',
                            SettingsToggle: 'd-none',
                            TableHeading: 'd-none',
                            Filter: 'ml-2 mr-3 pl-1 pr-1 mb-2 filterControl d-inline-block',
                            Pagination: 'paginationControl d-inline-block',
                            TableBody: 'dataContentBody',
                            Table: 'videoListTable'
                        }
                    }}
                >
                    <RowDefinition>
                        <ColumnDefinition id="name" title="name" />
                    </RowDefinition>
                </Griddle>
            </div>
        );


    }
    
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const DuplicateSeedVideos = connect(
    
    mapDispatchToProps
)(DuplicateSeedVideosContainer);

export default DuplicateSeedVideos;
