import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import VideoListing from '../video-ui/VideoListing';
import RecordNewModelVideo from '../player-recorder/RecordNewModelVideo';
import SocialIconsHeader from '../social/SocialIconsHeader';
import Switch from "react-switch";
import { Bold } from "@githubprimer/octicons-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiClient from "../../api/ApiClient";
import SelectSearch from 'react-select-search';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import "../../styles/vocab.css";
import { disambiguateSASURL } from "../../utils/urlSAS";
import Loader from 'react-loader-spinner';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DisappearNotification from "./../../modals/disappearNotification";
import { verifyTextContent } from "../../utils/verifyText";
import { invisibleModalStyle, modalStyles, loaderStyle } from '../../styles/modalStyles';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class CreateVocabListContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            userdata: null,
            userId: this.props.userId,
            userLevel: this.props.userLevel,
            showInvisibleModal: false,
            email: this.props.loggedInUser,
            isLoggedIn: this.props.isLoggedIn,
            signedInGroupId: this.props.signedInGroupId,
            openRecordNewModal: false,
            searchText: "",
            dropdownSelectOptions: [],
            searchVisible: false,
            unpackedVideoOptions: [],
            selectedVideos: [],
            listName: "",
            searchPlaceholder: "Search for a dictionary video to add to your new list"
        }
    }

    notify = () => {
        this.setState({ showInvisibleModal: true })
        setTimeout(() => {
            this.setState({ showInvisibleModal: false });
            window.location.href = process.env.PUBLIC_URL + "/vocab";
        }, 3000);
    }


    saveNewList = async () => {
        let listName = this.state.listName;
        if (this.state.listName) {
            // Submit request to verify list name

            // let isTextFlagged = await verifyTextContent(this.apiClient, listName, this, "vocabMarker");
            // if (isTextFlagged) {
            //     return;
            // }

            this.apiClient.postJSON(this, "/vocabList", {
                listName: this.state.listName, selectedVideos: this.state.selectedVideos
            }).then((res) => {
                if (res && res.status) {
                    if (res.status === 217) {
                        alert("This list name is already taken! Your list wasn't created. Please select another name.")
                    }
                    else if (res.status === 200) {
                        let contentType = "VocabList";
                        let text = listName;
                        let targetId = res.data;
                        if (targetId) {

                            this.notify();
                        }
                        else {
                            alert("Something went wrong, please try again.");
                        }
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });

        }
        else {
            alert("Please give your new list a name")
        }

    }

    getOptions = async (query) => {
        try {
            let loadOptions = null;
            const options = await new Promise((resolve, reject) => {
                loadOptions = setInterval(async () => {
                    if (this.state.dropdownSelectOptions.length > 0) {
                        var self = this;
                        const filtered = self.state.dropdownSelectOptions.filter((select) => {
                            try {
                                const result = (select.name.toUpperCase()).includes(query.toUpperCase());
                                return result;
                            }
                            catch (e) {

                                return false;
                            }
                        });
                        resolve(filtered)
                    }

                }, 200)
            });
            clearInterval(loadOptions);
            this.setState({ sOptions: options })
            return options;
        }
        catch (e) {
            return []
        }
    }

    componentWillReceiveProps(props) {
        if (props.searchText || props.searchText === "") {
            this.setState({
                searchText: props.searchText
            });
        }
        this.setState({
            userLevel: props.userLevel,
            isLoggedIn: props.isLoggedIn,
            email: props.loggedInUser,
            userId: props.userId,
            signedInGroupId: props.signedInGroupId
        });
    }

    marginTop = {
        marginTop: "50px"
    }

    paddingLeft = {
        paddingLeft: "10px",
        paddingRight: "10px"
    }

    seedVideoBtn = {
        marginTop: "-10px"
    }


    setDropdownState = (data) => {
        this.setState({ dropdownSelectOptions: data, searchVisible: true })
    }

    generateSearchOptions = (data) => {
        const uniqueList = {};
        data.forEach((datum) => {
            const name = datum.name;
            if (!datum.name) {

            }
            if (!uniqueList[name]) {
                const searchTerm = {
                    name: name,
                    value: name,
                }
                uniqueList[name] = searchTerm;
            }
        });
        const resArr = Object.values(uniqueList);
        this.setDropdownState(resArr);
    }

    onAddVocabListVideo = () => {
        if (this.props) {
            // Notify of generation
        }
    }

    recordCallback = () => {
        this.setState({ openRecordNewModal: false });
    }

    toggleRecordNewPopup = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            openRecordNewModal: !prevState.openRecordNewModal
        }));
    }

    pullVocabListSearchOptions = async () => {
        await this.setState({ searchText: "" })
        this.apiClient.getJSON(this, "/vocabList/load-vocab-video-options", "")
            .then(res => {
                if (res && res.status) {
                    // Populate video big list
                    this.generateSearchOptions(res.data.allVideos);
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            });
    }

    async componentDidMount() {
        this.pullVocabListSearchOptions();
    }

    performQuery = async (query) => {
        if (query.length > 1) {
            let qq = query.toUpperCase();
            this.setState({ searchText: qq });
            this.apiClient.getJSON(this, "/vocabList/load-vocab-video-query", "?searchterm=" + qq)
                .then(async (res) => {
                    if (res && res.status) {
                        // Populate vi
                        let matches = res.data.filter((video) => {
                            return (video.name.toUpperCase()).includes(qq);
                        });

                        await this.setState({ unpackedVideoOptions: matches });
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                });
        }

    }

    handleInputChange = (selection) => {
        if (this.state.dropdownSelectOptions) {
            selection = selection.toUpperCase();
            this.performQuery(selection)
        }
    }

    clearSearchResults = async () => {
        await this.setState({ searchText: "" });
        // setTimeout(() => {
        //     this.performQuery("");
        // },500)
    }

    addSelection = async (vid) => {
        let listMod = [...this.state.selectedVideos, vid];
        await this.setState({ selectedVideos: listMod });

        // Remove from right col list
        let listRMod = [...this.state.unpackedVideoOptions];
        listRMod = listRMod.filter((item) => item._id !== vid._id)
        await this.setState({ unpackedVideoOptions: listRMod });
    }

    removeSelection = async (vid) => {
        let listMod = [...this.state.unpackedVideoOptions, vid];
        await this.setState({ unpackedVideoOptions: listMod });

        // Remove from left col list
        let listLMod = [...this.state.selectedVideos];
        listLMod = listLMod.filter((item) => item._id !== vid._id)
        await this.setState({ selectedVideos: listLMod });
    }

    modifyListName = async (name) => {
        await this.setState({ listName: name })
    }

    render() {
        document.title = 'Vocab List Creation';

        if (!this.props.userId) {
            return (
                <div>
                    <div style={loaderStyle}>
                        Please login to access this view.
                    </div>
                </div>
            )
        }


        let videoOptions = [];
        let selectedVideos = [];
        if (this.state.unpackedVideoOptions) {
            videoOptions = this.state.unpackedVideoOptions.map((vid) => {
                return (
                    <div onClick={() => this.addSelection(vid)} className="flx-row vocab-video-row">
                        <div className="vocab-video-col">
                            <video controls autoPlay muted preload='auto' className="vocab-video"
                                src={vid.convertedUrl ?
                                    disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                } type="video/mp4">
                            </video>
                        </div>
                        <div className="vocab-video-title-col">
                            <div className="flx-v-25-empty"></div>
                            <div className="v-central-item">
                                <div className="cntr">{vid.name}</div>
                                <div className="cntr"><AddCircleIcon style={{ fontSize: "2.0em" }} className="vocab-plus" /></div>
                            </div>
                            <div className="flx-v-25-empty"></div>
                        </div>

                    </div>
                )
            });
        }
        selectedVideos = this.state.selectedVideos.map((vid) => {

            return (
                <div onClick={() => this.removeSelection(vid)} className="flx-row vocab-video-row">
                    <div className="vocab-video-col">
                        <video controls autoPlay muted preload='auto' className="vocab-video"
                            src={vid.convertedUrl ?
                                disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                            } type="video/mp4">
                        </video>
                    </div>
                    <div className="vocab-video-title-col">
                        <div className="flx-v-25-empty"></div>
                        <div className="v-central-item">
                            <div className="cntr">{vid.name}</div>
                            <div className="cntr">
                                <svg style={{ cursor: "pointer", marginTop: "-3px" }} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0.75C11.733 0.75 13.1492 2.10645 13.2449 3.81558L13.25 4H18.5C18.9142 4 19.25 4.33579 19.25 4.75C19.25 5.1297 18.9678 5.44349 18.6018 5.49315L18.5 5.5H17.704L16.4239 18.5192C16.2912 19.8683 15.1984 20.91 13.8626 20.9945L13.6871 21H6.31293C4.95734 21 3.81365 20.0145 3.59883 18.6934L3.57614 18.5192L2.295 5.5H1.5C1.1203 5.5 0.806509 5.21785 0.756847 4.85177L0.75 4.75C0.75 4.3703 1.03215 4.05651 1.39823 4.00685L1.5 4H6.75C6.75 2.20507 8.20507 0.75 10 0.75ZM16.197 5.5H3.802L5.06893 18.3724C5.12768 18.9696 5.60033 19.4343 6.18585 19.4936L6.31293 19.5H13.6871C14.2872 19.5 14.7959 19.0751 14.9123 18.4982L14.9311 18.3724L16.197 5.5ZM11.75 8.25C12.1297 8.25 12.4435 8.53215 12.4932 8.89823L12.5 9V16C12.5 16.4142 12.1642 16.75 11.75 16.75C11.3703 16.75 11.0565 16.4678 11.0068 16.1018L11 16V9C11 8.58579 11.3358 8.25 11.75 8.25ZM8.25 8.25C8.6297 8.25 8.94349 8.53215 8.99315 8.89823L9 9V16C9 16.4142 8.66421 16.75 8.25 16.75C7.8703 16.75 7.55651 16.4678 7.50685 16.1018L7.5 16V9C7.5 8.58579 7.83579 8.25 8.25 8.25ZM10 2.25C9.08183 2.25 8.32881 2.95711 8.2558 3.85647L8.25 4H11.75C11.75 3.0335 10.9665 2.25 10 2.25Z" fill="#6E6E6E" />
                                </svg>
                            </div>
                        </div>
                        <div className="flx-v-25-empty"></div>
                    </div>

                </div>
            )
        });
        return (
            <React.Fragment>
                <div className="wiki-top-fixed-back" style={{ height: "110px", zIndex: "-99" }}></div>
                <div>
                    <div className="list-create-background" style={{ paddingTop: "12px", height: "80px" }}>
                        <div className="new-list-creation-title">Create a New Vocabulary List</div>
                        <div>

                        </div>
                    </div>
                    {!this.state.dropdownSelectOptions &&
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                    {this.state.unpackedVideoOptions &&
                        <div className="create-list-col-container">
                            <div className="create-list-col-1">
                                <div className="v-list-title" style={{ marginBottom: "20px" }}>Vocab List Options</div>
                                <div className="header-search" style={{ marginBottom: "21px" }}>
                                    <SelectSearch
                                        disabled={!this.state.searchVisible}
                                        placeholder={this.state.searchPlaceholder}
                                        // autocomplete="off"
                                        options={this.state.sOptions}
                                        // closeOnSelect={true}
                                        getOptions={this.getOptions}
                                        search
                                        onChange={this.handleInputChange}
                                        value={this.state.searchText}
                                    >

                                    </SelectSearch>
                                    <svg className="search-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 11.7101 16.9276 13.2866 15.964 14.5483L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3466 21.0676 19.7794 21.0953 19.3871 20.7903L19.2929 20.7071L14.5483 15.964C13.2866 16.9276 11.7101 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5ZM10 4.5C6.96243 4.5 4.5 6.96243 4.5 10C4.5 13.0376 6.96243 15.5 10 15.5C13.0376 15.5 15.5 13.0376 15.5 10C15.5 6.96243 13.0376 4.5 10 4.5Z" fill="#6E6E6E" />
                                    </svg>
                                    {this.state.searchText && <HighlightOffRoundedIcon onClick={this.clearSearchResults} className="clear-search-btn" />}
                                    {!this.state.searchVisible &&
                                        <span style={{ textAlign: "center" }}>
                                            <Loader
                                                type="ThreeDots"
                                                color="#00BFFF"
                                                height={30}
                                                width={30}
                                            />
                                        </span>

                                    }

                                </div>
                                {(videoOptions.length > 0) && videoOptions}
                                {(videoOptions.length == 0) &&
                                    (<div className="empty-list-explainer">
                                        Search for videos to add above
                                    </div>)
                                }
                            </div>
                            <div className="create-list-col-2">
                                <div className="v-list-title">Your New Vocabulary List</div>
                                <div style={{ marginBottom: "20px" }}>
                                    <input type="text"
                                        placeholder="Give your new list a name"
                                        className="dashboard-search"
                                        onChange={($event) => this.modifyListName($event.target.value)}
                                    />

                                    <button onClick={(uv) => this.saveNewList()} className="record-btn" style={{ marginLeft: "10px" }}>Publish List</button>
                                    <button
                                        className="confirm-btn skip" style={{ paddingLeft: "17px" }}
                                        onClick={() => window.location = process.env.PUBLIC_URL + "/vocab"}>Cancel</button>
                                </div>
                                <div>
                                    {this.state.vocabMarker && <span className="margin-top" style={{ color: "red" }} aria-describedby="aboutMe"><i>Please fix the list name above.</i></span>}
                                </div>
                                {(selectedVideos.length > 0) && selectedVideos}
                                {(selectedVideos.length == 0) &&
                                    (<div className="empty-list-explainer">
                                        Add some videos to your new vocab list!
                                    </div>)
                                }
                            </div>
                        </div>
                    }
                </div>
                <DisappearNotification isTransparent={false} text={this.state.verificationStateText} symbol="processing" showInvisibleModal={this.state.cvsTextProcessing}></DisappearNotification>
                <DisappearNotification isTransparent={false} text="Your New Vocab List Was Created!" symbol="check-mark" showInvisibleModal={this.state.showInvisibleModal}></DisappearNotification>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const CreateVocabList = connect(
    mapDispatchToProps
)(CreateVocabListContainer);

export default CreateVocabList;
