import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import Octicon, { DeviceCameraVideo, ArrowRight } from '@githubprimer/octicons-react';
import WebModelVideoRecorder from '../player-recorder/WebModelVideoRecorder';
import { PropTypes } from "prop-types";

class RecordNewModelVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    heading = {
        width: "300px",
        margin: "24px auto .7rem",
        textAlign: "center"
    }

    video = {
        width: "100%",
        position: "relative",
        display: "block",
        margin: "0 auto"
    }

    onClick = () => {
    }

    newVideo = () => this.props.newVideo();

    render() {
        document.title = 'Record - The ASL Data Community'
        return (
            <div style={{ paddingTop: "15px" }}>
                <div>
                    <div className="seed-header">
                        <h2 >Record Seed Video</h2>
                        {/* <div style={{ marginLeft: "50px", lineHeight: "45px" }} >Record yourself signing a new word</div> */}
                    </div>
                    <div className="seed-top-row">
                        <div style={{ textAlign: "center", display: "flex" }}>
                            {/* <i>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</i> */}
                            Record yourself signing a new word
                        </div>
                        <div className="having-trouble">
                            <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbRx1qYFM3N0FCo6HgdDr2DB1UMkJZRTFWSzJJR0VNSjBYU05CQldRTldWWi4u">Having trouble?</a>
                        </div>
                    </div>
                    <div className="text-center">
                        {this.props.history &&
                            <WebModelVideoRecorder history={this.props.history} userid={this.props.userId} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default RecordNewModelVideo;
