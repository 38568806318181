import React from "react";
import "../../styles/tos.css";

export default class FBPrivacy extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {
        return (
            <div className="tos-box">
                <div className="page-header">
                    Facebook Login Privacy Policy
                </div>
                <div className="section-text">
                    Your privacy is important to us. This privacy statement explains the personal data Microsoft processes, how Microsoft processes it, and for what purposes.

                    Microsoft offers a wide range of products, including server products used to help operate enterprises worldwide, devices you use in your home, software that students use at school, and services developers use to create and host what’s next. References to Microsoft products in this statement include Microsoft services, websites, apps, software, servers, and devices.

                    Please read the product-specific details in this privacy statement, which provide additional relevant information. This statement applies to the interactions Microsoft has with you and the Microsoft products listed below, as well as other Microsoft products that display this statement.
                
                </div>
                <div className="section-header">
                    Personal data we collect
                </div>
                <div className="section-text">
                    Microsoft collects data from you, through our interactions with you and through our products. You provide some of this data directly, and we get some of it by collecting data about your interactions, use, and experiences with our products. The data we collect depends on the context of your interactions with Microsoft and the choices you make, including your privacy settings and the products and features you use. We also obtain data about you from third parties.

                    If you represent an organization, such as a business or school, that utilizes Enterprise and Developer Products from Microsoft, please see the Enterprise and developer products section of this privacy statement to learn how we process your data. If you are an end user of a Microsoft product or a Microsoft account provided by your organization, please see the Products provided by your organization and the Microsoft account sections for more information.

                    You have choices when it comes to the technology you use and the data you share. When we ask you to provide personal data, you can decline. Many of our products require some personal data to provide you with a service. If you choose not to provide data required to provide you with a product or feature, you cannot use that product or feature. Likewise, where we need to collect personal data by law or to enter into or carry out a contract with you, and you do not provide the data, we will not be able to enter into the contract; or if this relates to an existing product you’re using, we may have to suspend or cancel it. We will notify you if this is the case at the time. Where providing the data is optional, and you choose not to share personal data, features like personalization that use such data will not work for you.
                                    
                </div>
                <div className="section-header">
                    How we use personal data
                </div>
                <div className="section-text">
                    Microsoft uses the data we collect to provide you with rich, interactive experiences. In particular, we use data to:

                    Provide our products, which includes updating, securing, and troubleshooting, as well as providing support. It also includes sharing data, when it is required to provide the service or carry out the transactions you request.
                    Improve and develop our products.
                    Personalize our products and make recommendations.
                    Advertise and market to you, which includes sending promotional communications, targeting advertising, and presenting you with relevant offers.
                    We also use the data to operate our business, which includes analyzing our performance, meeting our legal obligations, developing our workforce, and doing research.

                    In carrying out these purposes, we combine data we collect from different contexts (for example, from your use of two Microsoft products) or obtain from third parties to give you a more seamless, consistent, and personalized experience, to make informed business decisions, and for other legitimate purposes.

                    Our processing of personal data for these purposes includes both automated and manual (human) methods of processing. Our automated methods often are related to and supported by our manual methods. For example, our automated methods include artificial intelligence (AI), which we think of as a set of technologies that enable computers to perceive, learn, reason, and assist in decision-making to solve problems in ways that are similar to what people do. To build, train, and improve the accuracy of our automated methods of processing (including AI), we manually review some of the predictions and inferences produced by the automated methods against the underlying data from which the predictions and inferences were made. For example, we manually review short snippets of voice data that we have taken steps to de-identify to improve our speech recognition technologies. This manual review may be conducted by Microsoft employees or vendors who are working on Microsoft’s behalf.
                                    
                </div>
                <div className="section-header">
                    Reasons we share personal data
                </div>
                <div className="section-text">
                    We share your personal data with your consent or to complete any transaction or provide any product you have requested or authorized. We also share data with Microsoft-controlled affiliates and subsidiaries; with vendors working on our behalf; when required by law or to respond to legal process; to protect our customers; to protect lives; to maintain the security of our products; and to protect the rights and property of Microsoft and its customers.
                </div>
                <div className="section-header">
                    How to access and control your personal data
                </div>
                <div className="section-text">
                    You can also make choices about the collection and use of your data by Microsoft. You can control your personal data that Microsoft has obtained, and exercise your data protection rights, by contacting Microsoft or using various tools we provide. In some cases, your ability to access or control your personal data will be limited, as required or permitted by applicable law. How you can access or control your personal data will also depend on which products you use. For example, you can:

                    Control the use of your data for interest-based advertising from Microsoft by visiting our opt-out page.
                    Choose whether you wish to receive promotional emails, SMS messages, telephone calls, and postal mail from Microsoft.
                    Access and clear some of your data through the Microsoft privacy dashboard.
                    Not all personal data processed by Microsoft can be accessed or controlled via the tools above. If you want to access or control personal data processed by Microsoft that is not available via the tools above or directly through the Microsoft products you use, you can always contact Microsoft at the address in the How to contact us section or by using our web form.

                    We provide aggregate metrics about user requests to exercise their data protection rights via the Microsoft Privacy Report.
                                    
                </div>
                <div className="section-header">
                    Cookies and similar technologies
                </div>
                <div className="section-text">
                    Cookies are small text files placed on your device to store data that can be recalled by a web server in the domain that placed the cookie. We use cookies and similar technologies for storing and honoring your preferences and settings, enabling you to sign in, providing interest-based advertising, combating fraud, analyzing how our products perform, and fulfilling other legitimate purposes. Microsoft apps use additional identifiers, such as the advertising ID in Windows described in the Advertising ID section of this privacy statement, for similar purposes.

                    We also use “web beacons” to help deliver cookies and gather usage and performance data. Our websites may include web beacons, cookies, or similar technologies from third-party service providers.

                    You have a variety of tools to control the data collected by cookies, web beacons, and similar technologies. For example, you can use controls in your internet browser to limit how the websites you visit are able to use cookies and to withdraw your consent by clearing or blocking cookies.
                                    
                </div>
                <div className="section-header">
                    Products provided by your organization—notice to end users
                </div>
                <div className="section-text">
                    If you use a Microsoft product with an account provided by an organization you are affiliated with, such as your work or school account, that organization can:

                    Control and administer your Microsoft product and product account, including controlling privacy-related settings of the product or product account.
                    Access and process your data, including the interaction data, diagnostic data, and the contents of your communications and files associated with your Microsoft product and product accounts.
                    If you lose access to your work or school account (in event of change of employment, for example), you may lose access to products and the content associated with those products, including those you acquired on your own behalf, if you used your work or school account to sign in to such products.

                    Many Microsoft products are intended for use by organizations, such as schools and businesses. Please see the Enterprise and developer products section of this privacy statement. If your organization provides you with access to Microsoft products, your use of the Microsoft products is subject to your organization's policies, if any. You should direct your privacy inquiries, including any requests to exercise your data protection rights, to your organization’s administrator. When you use social features in Microsoft products, other users in your network may see some of your activity. To learn more about the social features and other functionality, please review documentation or help content specific to the Microsoft product. Microsoft is not responsible for the privacy or security practices of our customers, which may differ from those set forth in this privacy statement.

                    When you use a Microsoft product provided by your organization, Microsoft’s processing of your personal data in connection with that product is governed by a contract between Microsoft and your organization. Microsoft processes your personal data to provide the product to your organization and you, and in some cases for Microsoft’s legitimate business operations related to providing the product as described in the Enterprise and developer products section. As mentioned above, if you have questions about Microsoft’s processing of your personal data in connection with providing products to your organization, please contact your organization. If you have questions about Microsoft’s legitimate business operations in connection with providing products to your organization as provided in the standard Microsoft Online Services Terms (OST), please contact Microsoft as described in the How to contact us section. For more information on our legitimate business operations, please see the Enterprise and developer products section.

                    For Microsoft products provided by your K-12 school, including Microsoft 365 Education, Microsoft will:

                    not collect or use student personal data beyond that needed for authorized educational or school purposes;
                    not sell or rent student personal data;
                    not use or share student personal data for advertising or similar commercial purposes, such as behavioral targeting of advertisements to students;
                    not build a personal profile of a student, other than for supporting authorized educational or school purposes or as authorized by the parent, guardian, or student of appropriate age; and
                    require that our vendors with whom student personal data is shared to deliver the educational service, if any, are obligated to implement these same commitments for student personal data.
                                    
                </div>
                <div className="section-header">
                    Microsoft account
                </div>
                <div className="section-text">
                    With a Microsoft account, you can sign in to Microsoft products, as well as those of select Microsoft partners. Personal data associated with your Microsoft account includes credentials, name and contact data, payment data, device and usage data, your contacts, information about your activities, and your interests and favorites. Signing in to your Microsoft account enables personalization and consistent experiences across products and devices, permits you to use cloud data storage, allows you to make payments using payment instruments stored in your Microsoft account, and enables other features.

                    There are three types of Microsoft account:

                    When you create your own Microsoft account tied to your personal email address, we refer to that account as a personal Microsoft account.
                    When you or your organization (such as an employer or your school) create your Microsoft account tied to your email address provided by that organization, we refer to that account as a work or school account.
                    When you or your service provider (such as a cable or internet service provider) create your Microsoft account tied to your email address with your service provider’s domain, we refer to that account as a third-party account.
                    If you sign into a service offered by a third party with your Microsoft account, you will share with that third party the account data required by that service.
                                    
                </div>
                <div className="section-header">
                    Collection of data from children
                </div>
                <div className="section-text">
                    When a Microsoft product collects age, and there is an age in your jurisdiction under which parental consent or authorization is required to use the product, the product will either block users under that age or will ask them to provide consent or authorization from a parent or guardian before they can use it. We will not knowingly ask children under that age to provide more data than is required to provide for the product.

                    Once parental consent or authorization is granted, the child's account is treated much like any other account. The child can access communication services, like Outlook and Skype, and can freely communicate and share data with other users of all ages. Learn more about parental consent and Microsoft child accounts.

                    Parents or guardians can change or revoke the consent choices previously made, and review, edit, or request the deletion of the personal data of the children for whom they provided consent or authorization. As the organizer of a Microsoft family group, the parent or guardian can manage a child’s information and settings on their Family Safety page and view and delete a child’s data on their privacy dashboard.

                    Select Learn more below for more information about children and Xbox profiles.
                </div>
                <div className="section-header">
                    Other important privacy information
                </div>
                <div className="section-text">
                    Below you will find additional privacy information, such as how we secure your data, where we process your data, and how long we retain your data. You can find more information on Microsoft and our commitment to protecting your privacy at Microsoft Privacy.
                </div>
                <div className="section-header">
                    Enterprise and developer products
                </div>
                <div className="section-text">
                    Enterprise and Developer Products are Microsoft products and related software offered to and designed primarily for use by organizations and developers. They include:

                    Cloud services, referred to as Online Services in the Microsoft Online Services Terms (OST), such as Microsoft 365 and Office 365, Microsoft Azure, Microsoft Dynamics365, and Microsoft Intune for which an organization (our customer) contracts with Microsoft for the services (“Enterprise Online Services”).
                    Other enterprise and developer tools and cloud-based services, such as Azure PlayFab Services (to learn more see Azure PlayFab Terms of Service).
                    Server, developer, and hybrid cloud platform products, such as Windows Server, SQL Server, Visual Studio, System Center, Azure Stack and open source software like Bot Framework solutions (“Enterprise and Developer Software”).
                    Appliances and hardware used for storage infrastructure, such as StorSimple (“Enterprise Appliances”).
                    Professional services referred to in the OST that are available with Enterprise Online Services, such as onboarding services, data migration services, data science services, or services to supplement existing features in the Enterprise Online Services.
                    In the event of a conflict between this Microsoft privacy statement and the terms of any agreement(s) between a customer and Microsoft for Enterprise and Developer Products, the terms of those agreement(s) will control.

                    You can also learn more about our Enterprise and Developer Products’ features and settings, including choices that impact your privacy or your end users’ privacy, in product documentation.

                    If any of the terms below are not defined in this Privacy Statement or the OST, they have the definitions below.

                    General. When a customer tries, purchases, uses, or subscribes to Enterprise and Developer Products, or obtains support for or professional services with such products, Microsoft receives data from you and collects and generates data to provide the service (including improving, securing, and updating the service), conduct our legitimate business operations, and communicate with the customer. For example:

                    When a customer engages with a Microsoft sales representative, we collect the customer’s name and contact data, along with information about the customer’s organization, to support that engagement.
                    When a customer interacts with a Microsoft support professional, we collect device and usage data or error reports to diagnose and resolve problems.
                    When a customer pays for products, we collect contact and payment data to process the payment.
                    When Microsoft sends communications to a customer, we use data to personalize the content of the communication.
                    When a customer engages with Microsoft for professional services, we collect the name and contact data of the customer’s designated point of contact and use information provided by the customer to perform the services that the customer has requested.
                    The Enterprise and Developer Products enable you to purchase, subscribe to, or use other products and online services from Microsoft or third parties with different privacy practices, and those other products and online services are governed by their respective privacy statements and policies.
                                    
                </div>
                <div className="section-header">
                    Productivity and communications products
                </div>
                <div className="section-text">
                    Productivity and communications products are applications, software, and services you can use to create, store, and share documents, as well as communicate with others.
                </div>
                <div className="section-header">
                    Search, Microsoft Edge, and artificial intelligence
                </div>
                <div className="section-text">
                    Search and artificial intelligence products connect you with information and intelligently sense, process, and act on information—learning and adapting over time.
                </div>
                <div className="section-header">
                    Windows
                </div>
                <div className="section-text">
                    Windows is a personalized computing environment that enables you to seamlessly roam and access services, preferences, and content across your computing devices from phones to tablets to the Surface Hub. Rather than residing as a static software program on your device, key components of Windows are cloud-based, and both cloud and local elements of Windows are updated regularly, providing you with the latest improvements and features. In order to provide this computing experience, we collect data about you, your device, and the way you use Windows. And because Windows is personal to you, we give you choices about the personal data we collect and how we use it. Note that if your Windows device is managed by your organization (such as your employer or school), your organization may use centralized management tools provided by Microsoft or others to access and process your data and to control device settings (including privacy settings), device policies, software updates, data collection by us or the organization, or other aspects of your device. Additionally, your organization may use management tools provided by Microsoft or others to access and process your data from that device, including your interaction data, diagnostic data, and the contents of your communications and files. For more information about data collection and privacy in Windows, see Windows 10 and your online services. Earlier versions of Windows (including Windows Vista, Windows 7, Windows 8, and Windows 8.1) are subject to their own privacy statements.
                </div>
                <div className="section-header">
                    Entertainment and related services
                </div>
                <div className="section-text" style={{marginBottom: "200px"}}>
                    Entertainment and Related Services power rich experiences and enable you to access a variety of content, applications and games.
                </div>
            </div>
        );
    }
}