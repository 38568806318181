import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'
import DeleteContent from '../misc/DeleteContent';
import FacebookShareButton from '../social/FacebookShareButton';
import ReactTooltip from 'react-tooltip'
import { FaAngleRight, FaAngleDown, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import "../../styles/explore.css";
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import { disambiguateSASURL } from "../../utils/urlSAS";
import ReportVideoButton from '../report/ReportVideoButton';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class GroupedVideoListingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: this.props.videos,
            userLevel: this.props.userLevel,
            // verified: [],
            showHideVersions: 'none',
            pageSize: 2,
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            userVideos: [],
            isFluent: this.props.isFluent
        }
    }

    flggedFontSize = {
        fontSize: "13px"
    }

    paginationArrow = {
        width: '25px',
        height: '25px'
    }

    marginBottom = {
        marginBottom: "3px"
    }

    componentWillReceiveProps(props) {
        if (props.videos !== this.state.videos) {
            this.setState({ videos: props.videos, userLevel: props.userLevel, isFluent: props.isFluent, showHideVersions: 'none', userVideos: [] });
        } else {
            this.setState({ videos: props.videos, userLevel: props.userLevel, isFluent: props.isFluent });
        }
    }
    componentDidUpdate(prevProps) {
        // if(this.state.showHideVersions === 'block'){
        //     this.setState({showHideVersions:'none'});
        // }
    }

    flagRemoval = () => {

    }

    nextPage = async () => {
        var updatedPage = this.state.currentPage + 1;
        if (this.state.currentPage < this.state.totalPages) {
            this.setState({ currentPage: updatedPage });
            await this.updateUserVideosList(this.state.checkedValue, updatedPage, this.state.videos.modelVideo._id);
        }
    }
    previousPage = async () => {
        var updatedPage = this.state.currentPage - 1;
        if (this.state.currentPage > 1) {
            this.setState({ currentPage: updatedPage });
            await this.updateUserVideosList(this.state.checkedValue, updatedPage, this.state.videos.modelVideo._id);
        }
    }
    updateUserVideosList = async (isFluent, currentPage, modelVideo) => {
        var response = await this.getUserVideos(isFluent, currentPage, modelVideo);
        //this.setState({ verified: response.verifyBlocked });
        var totalCount = parseInt(response.totalCount);
        var pageCount = 1;
        if (totalCount % this.state.pageSize === 0) {
            pageCount = (totalCount) / this.state.pageSize;
        }
        else {
            pageCount = Math.floor((totalCount + this.state.pageSize - 1) / this.state.pageSize);
        }
        this.setState({ userVideos: response.userVideos, totalCount: response.totalCount, totalPages: pageCount });
    }

    getUserVideos = async (isFluent, currentPage, modelVideo) => {
        var groupId = this.props.signedInGroupId !== undefined ? this.props.signedInGroupId : null;
        return new Promise((resolve, reject) => {
            var url = "getUserVideos?currentPage=" + currentPage + "&pageSize=" + this.state.pageSize + "&isFluent=" + (isFluent ? "1" : "0") + "&modelVideo=" + modelVideo + "&groupId=" + groupId + "&uid=" + this.props.userId;
            this.getJSON(this, url).then((res) => {
                if (res && res.status) {
                    resolve(res.data);
                }
                else if (res && res.httpServerError) {
                    reject("Something Went Wrong");
                }
            });
        });
    }

    render() {
        var modelVideo = this.state.videos.modelVideo;
        var recordVideo = { modelVideo: modelVideo };
        const disablePreviousPagination = this.state.currentPage === 1 ? { pointerEvents: "none", color: "#667385" } : {};
        const disableNextPagination = this.state.currentPage === this.state.totalPages ? { pointerEvents: "none", color: "#667385" } : {};
        const flaggedMsg = <p style={this.flggedFontSize}><i><b style={{ color: "#E20C0C" }}>WARNING:</b> This video has been flagged and we are in the process of reviewing it.</i></p>
        var userVideos = this.state.userVideos.map((userVideo, index) => {
            return (
                <div style={{ height: '150px', width: '100%', marginBottom: '5px', paddingLeft: '20px' }}>
                    <div className="video-description">
                        <h3 className="video-name h4">
                            {userVideo.name}
                        </h3>
                        <p className="video-author">
                            <i>Submitted by </i>{this.state.userLevel.profile && <Link to={{ pathname: '/userProfile', search: '?uniqueId=' + userVideo.user.userid + '&userId=' + userVideo.user._id + '&isLoggedInUser=true' }} target="_blank">{userVideo.user.userName}</Link>}{!this.state.userLevel.profile && userVideo.user.userName}
                        </p>
                        {/* {this.props.generateShareUrl(userVideo._id)} */}
                        {/* <FacebookShareButton url="https://seedvideos.blob.core.windows.net/seedvideosprod/sauce.mp4"/> */}
                        {/* <p className="video-rating">
                                Accuracy {rowData.rating}
                            </p> */}
                        <p style={this.marginTop}>{userVideo.isFlagged && flaggedMsg}</p>
                    </div>
                    <div className="video-thumb">
                        {/* {((userVideo.avertJobId && userVideo.avertJobStatus === "InProgress") || !userVideo.verifiedCVS) &&
                            <div className="video-warning">
                                <span>
                                    WARNING: This video is currently under review.
                      
                                </span>
                            </div>
                        } */}
                        <video id={`userVideoModel_${userVideo.convertedUrl}`} controls muted preload='auto' key={userVideo.convertedUrl}>
                            <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/mp4" ref={this.exampleVid} />
                            <source src={disambiguateSASURL(userVideo.convertedUrl, process.env)} type="video/webm" ref={this.exampleVid} />
                            <track id={`enTrack_${userVideo.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                            Your browser does not support HTML5 video.
                        </video>

                    </div>
                </div>
            );
        });
        return (
            <React.Fragment>
                <div className="grouped-video-row">
                    <div className="explore-video-description">
                        {/* {this.state.videos.count === 0 && <div>
                            Be the first to record this sign
                        </div>} */}
                        <div className="video-name">
                            <p>{modelVideo.name}</p>
                            <MdInfoOutline data-tip="This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing." className="labelEnglishInfo" />
                            <ReactTooltip />
                        </div>
                        {/* <div className="record-btn-row">
                            {this.props.userId &&
                                <button onClick={(uv) => this.props.onShowRecordNewModal(recordVideo)} className="record-btn">Record</button>

                            }
                            {
                                !this.props.userId && <span>Sign in to record</span>
                            }
                        </div> */}
                        {this.state.videos.modelUserVideos.length > 0 && (<div className="recording-count">
                            <span><VideocamRoundedIcon /> {this.state.videos.modelUserVideos.length} Recordings </span>
                        </div>)}
                        {/* {this.state.videos.modelUserVideos.length === 0 && <div className="recording-count">
                            <div><VideocamRoundedIcon /></div>
                            <div><span className="record-label">Be the first to record</span></div>
                        </div>} */}
                    </div>
                    <div className="model-des">
                        <div>
                            {/* {((modelVideo.avertJobId && modelVideo.avertJobStatus === "InProgress") || !modelVideo.verifiedCVS) &&
                                <div className="video-warning" style={{ marginRight: "15px", marginTop: "10px" }}>
                                    <span>
                                        WARNING: This video is currently under review.
                       
                                    </span>
                                </div>
                            } */}
                            {(modelVideo.avertFlagViolated) &&
                                <div className="video-warning" style={{ marginRight: "15px", marginTop: "10px" }}>
                                    <span>
                                        NOTE: Inappropriate content was detected in this video, so it is not visible throughout the site.
                                    </span>
                                </div>
                            }
                            <video className="horizontal-video" style={{ padding: "unset" }} id={`userVideoModel_${modelVideo.convertedUrl}`} controls muted preload='auto' key={modelVideo.convertedUrl}>
                                <source src={disambiguateSASURL(modelVideo.convertedUrl, process.env)} type="video/mp4" />
                                <source src={disambiguateSASURL(modelVideo.convertedUrl, process.env)} type="video/webm" />
                                <track id={`enTrack_${modelVideo.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <span className="explore-flag model">
                            <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={modelVideo} loggedInUserId={this.props.userId} />
                        </span>

                    </div>

                    <div className="explore-video-listing" tabindex="0">
                        <div className="submitted-video-horizontal-list">
                            {
                                this.state.videos.modelUserVideos.map((vid) => {
                                    return (
                                        <div style={{ position: "relative" }} >
                                            {/* {((vid.avertJobId && vid.avertJobStatus === "InProgress") || !vid.verifiedCVS) &&
                                                <div className="video-warning" style={{ marginRight: "15px", marginLeft: "15px" }}>
                                                    <span>
                                                        WARNING: This video is currently under review.
                                           
                                                    </span>
                                                </div>
                                            } */}
                                            <video className="horizontal-video" id={`userVideoModel_${vid.convertedUrl}`} controls muted preload='auto' key={vid.convertedUrl}>
                                                <source src={disambiguateSASURL(vid.convertedUrl, process.env)} type="video/mp4" />
                                                <track id={`enTrack_${vid.convertedUrl}`} src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                                Your browser does not support HTML5 video.
                                            </video>
                                            {(vid.user && this.props.userId) && <div className="explore-profile-name">
                                                {/* <Link to={{ pathname: '/userProfile', search: '?uniqueId=' + vid.user.userid + '&userId=' + vid.user._id + '&isLoggedInUser=true' }} target="_blank">{vid.user.userName}
                                                </Link> */}
                                                <span className="explore-flag">
                                                    <ReportVideoButton skipToNewVideo={() => this.flagRemoval()} data={vid} loggedInUserId={this.props.userId} />
                                                </span>
                                            </div>
                                            }


                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <div className="verify-record text-center">
                            <div className="record-newsubmission pt-2">
                                {this.state.userLevel && <button disabled={!this.state.userLevel.record} className="btn btn-primary" role="link" aria-label="submit new recording" >Record new</button>}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div style={{ display: this.state.showHideVersions, paddingTop: '10px', paddingBottom: '10px', paddingLeft: "40px" }}>
                    {userVideos}
                    {(this.state.videos.count !== 0) && <Row>
                        <div style={{ flexGrow: '1' }}>
                            <span style={{ float: 'right', paddingRight: '30px' }} >
                                <a href='#' onClick={this.previousPage} ><FaAngleDoubleLeft style={{ ...this.paginationArrow, ...disablePreviousPagination }} /></a><span style={{ fontSize: '18px', fontWeight: 'bold', top: '10px' }}>{this.state.currentPage}</span><a href='#' onClick={this.nextPage}><FaAngleDoubleRight style={{ ...this.paginationArrow, ...disableNextPagination }} /></a>
                            </span>
                        </div>
                    </Row>}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const GroupedVideoListing = connect(
    
    mapDispatchToProps
)(GroupedVideoListingContainer);


export default GroupedVideoListing;