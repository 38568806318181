import React, { Component } from "react";
import { loaderStyle } from '../../styles/modalStyles';
import "../../styles/dashboard.css";
import { debounce } from "../../utils/utilMethods";
import DatePicker from "react-datepicker";
import ApiClient from "../../api/ApiClient";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { disambiguateSASURL } from "../../utils/urlSAS";
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';

class APIDashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            isLoading: true,
            queryString: "",
            selectedGroup: "none",
            isAdminMode: false,
            allowedActions: [],
            backupJSON: false
        }
    }

    componentDidMount = () => {
        this.getSupportedActions()
    }

    downloadDBBackup = () => {

    }

    getSupportedActions = (query) => {
        this.setState({ queryString: query, isLoading: true });

        this.apiClient.getJSON(this, "/dataDownloader/getSupportedActions", "").then(async (res) => {

            if (res && res.status) {
                await this.setState({ allowedActions: res.data });
            }
            else if (res && res.httpServerError) {
                throw "Something Went Wrong"
            }
        });
    }

    downloadDBBackup = async (targetCollection) => {
        await this.setState({ backupJSON: true });
        let csvLink = await this.apiClient.getJSONFile("/dataDownloader/data-reviewer/dbbackup", "?collectionName=" + targetCollection);
        await this.setState({ backupJSON: false });
    }

    download2021DictionaryCSV = async () => {

        await this.setState({ d2021DCSV: true });
        let csvLink = await this.apiClient.getCSV("/dataDownloader/data-reviewer/2021DictionaryUserStudyUserVideoReport", "");
        csvLink.click();
        await this.setState({ d2021DCSV: false });

    }

    downloadWikiCSV = async () => {

        await this.setState({ dWikiCSV: true });
        let csvLink = await this.apiClient.getCSV("/dataDownloader/data-reviewer/WikiUserStudyUserVideoReport", "");
        csvLink.click();
        await this.setState({ dWikiCSV: false });

    }


    

    generateAllTypeButtons = (type) => {
        switch (type) {
            case "Admin":
                return (
                    <React.Fragment>
                        <div className="action-div clicker">
                            <button onClick={() => window.location.href = process.env.PUBLIC_URL + "/stats-dashboard"} className="record-btn clicker" style={{ background: "#127BC2" }}>Navigate To Statistics Dashboard</button>
                        </div>
                        <div className="action-div clicker">
                            <button onClick={() => this.downloadDBBackup("uservideos")} className="record-btn clicker" style={{ background: "green" }}>DB Backup  UserVideos</button>
                        </div>
                        <div className="action-div clicker">
                            <button onClick={() => this.downloadDBBackup("videos")} className="record-btn clicker" style={{ background: "green" }}>DB Backup  SeedVideos</button>
                        </div>
                        <div className="action-div clicker">
                            <button onClick={() => this.downloadDBBackup("users")} className="record-btn clicker" style={{ background: "green" }}>DB Backup  Users</button>
                        </div>
                        <div className="action-div clicker">
                            <button onClick={() => this.downloadDBBackup("enwiki20200701sentences")} className="record-btn clicker" style={{ background: "green" }}>DB Backup  Wiki Videos</button>
                        </div>
                        <div className="action-div clicker">
                            <button onClick={() => this.downloadDBBackup("wikicontents")} className="record-btn clicker" style={{ background: "green" }}>DB Backup  Wiki Articles</button>
                        </div>
                        {this.state.backupJSON &&
                            <div style={{ height: "100px" }}>
                                <div>Your file will download momentarily</div>
                                <Loader
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        }
                    </React.Fragment>
                )
            case "ContentReviewer":
                return (
                    <React.Fragment>
                        <div className="action-div clicker">
                            <button onClick={() => window.location.href = process.env.PUBLIC_URL + "/admin-dashboard"} className="record-btn clicker" style={{ background: "#127BC2" }}>Navigate To Video Dashboard</button>
                        </div>
                    </React.Fragment>
                )
            case "DataReviewer":
                return (
                    <React.Fragment>
                          <div className="action-div">
                            <button onClick={() => this.downloadWikiCSV()} className="record-btn clicker big-btn">
                                Download Wiki Payments Form
                            </button>
                            {this.state.dWikiCSV &&
                                <div style={{ height: "100px" }}>
                                    <div>Your file will download momentarily</div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            }
                        </div>
                        <div className="action-div">
                            <button onClick={() => this.download2021DictionaryCSV()} className="record-btn clicker big-btn">
                                Download Dictionary Payments Form
                            </button>
                            {this.state.d2021DCSV &&
                                <div style={{ height: "100px" }}>
                                    <div>Your file will download momentarily</div>
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            default:
                return (null)
        }
    }

    generateFullAdminRangePerType = (typeName) => {
        return (
            <div className="action-section">
                <div style={{ marginBottom: "15px", fontSize: "18px" }}>{typeName}</div>
                {this.generateAllTypeButtons(typeName)}
            </div>
        )
    }




    render() {

        document.title = 'Admin Action Dashboard';

        const allowedActionsView = this.state.allowedActions.map((aa) => {
            return (
                this.generateFullAdminRangePerType(aa)
            )
        });

        return (

            <React.Fragment>

                <div className="admin-action-container">
                    <div style={{ marginBottom: "30px", fontSize: "24px", borderBottom: "1px solid black", paddingBottom: "15px" }}>Welcome to the admin dashboard. The Power is yours.</div>
                    <div>
                        {allowedActionsView}
                    </div>
                </div>


            </React.Fragment>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const APIDashboard = connect(

    mapDispatchToProps
)(APIDashboardContainer);

export default APIDashboard
