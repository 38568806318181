// eslint-disable-next-line
import React, { Component } from 'react';
import RecordRTC from 'recordrtc';
import FailedContentModal from '../../modals/FailedContentModal';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check } from '@githubprimer/octicons-react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Roller } from 'react-awesome-spinners';
import * as rdd from 'react-device-detect';
import ApiClient from "../../api/ApiClient";
import DisappearNotification from "../../modals/disappearNotification";
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import { connect } from 'react-redux';
import { disambiguateSASURL } from "../../utils/urlSAS";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import { MdInfoOutline } from "react-icons/md";
import "../../styles/video.css";
// import "../../styles/mirror.css";
import ReactTooltip from 'react-tooltip';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';

class WebRecorderSeedFactoryContainer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.recorder = null;
        this.video = React.createRef();
        // this.buttonStop = React.createRef();
        // this.buttonStart = React.createRef();
        // this.buttonSubmit = React.createRef();
        this.recordingStart = this.recordingStart.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.timerId = null;
        this.state = {
            isCountdownActive: false,
            counter: "",
            playIcon: PrimitiveDot,
            isRedo: false,
            submitState: false,
            display: "hide",
            recordingStarted: false,
            response: '',
            post: '',
            showMidStateConfirm: true,
            responseToPost: '',
            file: null,
            videoDownload: null,
            userName: null,
            isLabelVisible: false,
            showControls: false,
            recordTime: 0,
            showTimer: false,
            updateUserLevel: this.props.updateUserLevel,
            showInvisibleModal: false,
            showSpinner: false,
            showFailedContentModal: false,
            signedInGroupId: this.props.signedInGroupId,
            preventAction: false
        }
    }

    startTimer = () => {
        this.setState({ recordTime: 0 });
        this.timerId = setInterval(() => this.setState({ recordTime: this.state.recordTime + 1 }), 1000);
    }
    stopTimer = () => {
        clearInterval(this.timerId);

    }

    componentDidMount() {
        //this.props.initializeCamera()
        //this.recordingStart();
        // this.buttonStop.current.disabled = true;
        // this.buttonSubmit.current.disabled = true;
        this.cameraCleanup = this.props.history.listen((location, action) => {
            try {

                this.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });

            }
            catch (e) {

            }
        });


    }


    componentWillUnmount() {
        this.cameraCleanup();
    }

    componentWillReceiveProps(props) {
        this.setState({ signedInGroupId: props.signedInGroupId });
    }

    captureCamera(callback) {
        // eslint-disable-next-line
        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
            callback(camera);
        }).catch(function (error) {
            // eslint-disable-next-line
            alert('Unable to capture your camera. Please check console logs. WebRecorder.js');
            // eslint-disable-next-line
            console.error(error);
        });
    }

    blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }



    recordingStart = async () => {
        const self = this;
        //this.initiateRecorder();
        // if (!this.buttonStart.current.disabled === true) {
        // this.buttonStart.current.disabled = true;
        // this.buttonSubmit.current.disabled = true;
        this.setState({ showControls: false, showTimer: true, recordingStarted: true });
        const camera = await this.props.recordingStart()

        self.recorder = RecordRTC(camera, {
            // type:'video/webm;codecs=vp9',
            // mimeType: 'video/webm;codecs=vp9'
            type: 'video/mp4',
            mimeType: 'video/mp4'
        });

        this.recorder.startRecording();

        this.startTimer();
        // this.buttonStop.current.disabled = false;
        // }
    }

    stopCamera = () => {
        let self = this;
        // if (recorder)
        //     stopRecording();
        // release camera
        // self.props.video.getTracks().forEach( (track) => {
        //     
        //     track.stop();
        // });


    }

    async startCountdown() {
        let counter = 3;
        if (this.state.isRedo) {
            counter = "Ready?"
            await this.setState({ counter: counter })
        }
        else {
            await this.setState({ counter: counter })
            counter--;
        }
        this.countdown = setInterval(() => {
            if (counter === 0) {
                this.setState({ counter: "Start" })
                this.innerCountdownTimeout = setTimeout(() => {
                    this.setState({ isCountdownActive: false })
                    this.setState({ counter: "" })
                }, 2000)
                clearInterval(this.countdown);
            }
            else if (counter === "Ready?") {
                setTimeout(() => {
                    this.setState({ counter: "Start" })
                    this.innerCountdownTimeout = setTimeout(() => {
                        this.setState({ isCountdownActive: false })
                        this.setState({ counter: "" })
                    }, 1000)
                    clearInterval(this.countdown);
                }, 1000)

            }
            else {
                this.setState({ counter: counter })
                counter--;
            }
        }, 1300)
    }

    initiateRecorder() {
        //this.startCountdown()
    }


    stopRecording = () => {
        this.stopCamera()
        this.setState({ showMidStateConfirm: true });
        setTimeout(() => {
            this.setState({ showMidStateConfirm: false });
        }, 2500)
        // if (!this.buttonStop.current.disabled === true) {
        //     this.buttonStop.current.disabled = true;
        //     this.buttonSubmit.current.disabled = false;
        //fyd
        this.recorder.stopRecording(() => this.stopRecordingCallback());
    }

    stopRecordingCallback = async () => {
        this.stopTimer();
        const blob = this.recorder.getBlob();
        const targetSrc = URL.createObjectURL(blob);
        this.props.stopRecordingCallback(targetSrc, blob);
        this.setState({
            recordingStarted: false,
            videoDownload: this.blobToFile(this.recorder.getBlob(), this.props.video.name + ".mp4"),
            showControls: true, showTimer: false, recordTime: 0, playIcon: Sync, display: ""
        });
        await this.setState({ submitState: true })
    }

    notify = () => {
        this.setState({ showInvisibleModal: true })
        setTimeout(() => {
            this.setState({ showInvisibleModal: false });
            this.props.changeRecordState();
        }, 1000);
    }

    // Add Dictionary Video Recording
    handleSubmit = async e => {
        if (!this.state.preventAction) {
            await this.setState({ preventAction: true });
            this.props.incrementRecordedCount();
            let self = this;
            e.preventDefault();
            this.setState({ showSpinner: true, submitState: false });
            // this.buttonSubmit.current.disabled = true;
            // this.buttonStart.current.disabled = true;
            // eslint-disable-next-line
            const data = new FormData();
            // data.append('filename', 'this.props.video.nae');
            data.append('videoname', this.props.video.name);
            data.append('userName', this.props.userName);
            data.append('refID', this.props.video._id);
            data.append('seedId', this.props.video._id);
            data.append('count', this.props.video.count);
            data.append('file', this.state.videoDownload);
            data.append('groupId', this.state.signedInGroupId === undefined ? "" : this.state.signedInGroupId);

            let vresponse = await this.apiClient.postJSON(this, '/add-content/seedFactory', data, true);

            if (vresponse && vresponse.status) {

                this.setState({ responseToPost:  vresponse.data.message, showSpinner: false });
                await this.setState({ preventAction: false });
                if (vresponse.data.userLevelUpdated) {
                    this.state.updateUserLevel(vresponse.data.level);
                    this.notify(vresponse.data.level);
                }
                let parent = this;
                // eslint-disable-next-line
                if (response.status === 200) {
                    this.props.dispatch(this.props.updateScore(2, self.props.userid, this.apiClient));
                    this.notify();
                    this.props.clearPrevVideo()
                    //this.setState({showFailedContentModal:true});
                    //await parent.props.newVideo();

                    // await this.props.newVideo();
                    // this.props.history.push('/record');
                } else {
                    // this.setState({ showFailedContentModal: true });
                }
            }
            else if (vresponse && vresponse.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        }
        //this.clearVideoOptions()
    };

    clearVideoOptions = () => {
        this.props.clearVideoOptions();
    }

    redo = async () => {
        await this.setState({ isRedo: true })
        this.recordingStart();
        this.setState({ submitState: false })
    }


    render() {
        if (this.props.video === null) {
            return null
        }
        // eslint-disable-next-line
        return (
            <React.Fragment>

                <DisappearNotification background="#fff" isTransparent={false} symbol="check-mark" text="Recording Upload Successful, Thanks!" showInvisibleModal={this.state.showInvisibleModal}></DisappearNotification>
                {this.state.isRedo}

                <div className="video-container-r video-background-r" style={{ position: "relative" }}>
                    {this.state.showTimer && <div className="recording-time-indicator-r"><span>REC {this.state.recordTime}s</span></div>}

                    <div className="transition-counter">
                        {(this.state.counter <= 3 || this.state.counter === "Start" || this.state.counter === "Ready?") &&
                            (<span>{this.state.counter}</span>)
                        }
                    </div>


                    <video id="video-recorder" className="video-r" controls={this.state.submitState} autoPlay playsInline ref={this.props.setRef}></video>
                </div>





                <div className="video-bottom-dv-r">
                    <div className="r-controls-row">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/* {this.state.showLabelButton && <span className="show-label" onClick={this.showLabel}>Show Label</span>}
                            {this.state.hideLabelButton && <span className="show-label" onClick={this.hideLabel}>Hide Label</span>} */}
                            {this.props.showVideoLabel && <span style={{ marginLeft: "20px" }}
                                className="reveal-word-display">{this.props.videoName}
                                <a style={this.displayhtml} href="#" aria-hidden="false" data-for="userLevelTip" data-tip ><MdInfoOutline className="labelEnglishInfo" /></a>
                                <ReactTooltip id="userLevelTip">
                                    <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                </ReactTooltip></span>}
                        </div>

                        <div className="recorder-controls-row">
                            <div className="recorder-control">
                                <React.Fragment>
                                    {(!this.state.recordingStarted && !this.state.submitState) && <div className="record-btn-r stop" style={this.controls} >

                                        <button style={{ marginBottom: "5px" }} onClick={this.recordingStart} aria-label="submit new recording" className="record-btn" aria-label="submit new recording" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="white" />
                                            <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z" fill="white" />
                                        </svg><span className="inner-record-btn-label">Record</span></button>


                                    </div>
                                    }
                                    {(!this.state.submitState && this.state.recordingStarted) && <div className="record-btn-r stop" style={this.controls} >

                                        <StopRoundedIcon
                                            style={{ ...playerRecorderControls, color: "red" }}
                                            className="control"
                                            ref={this.buttonStop} aria-label="Stop"
                                            onClick={this.stopRecording}

                                        />
                                    </div>
                                    }
                                    {this.state.submitState &&
                                        <div className="record-btn-r submit">
                                            <div style={{ marginRight: "10px", color: "#333333" }} onClick={this.redo}>Redo</div>
                                            <div className="recorder-control" onClick={this.redo}>
                                                <RedoRoundedIcon type="submit|" className="re-record-btn"

                                                    style={{ ...playerRecorderControls, color: "red" }}
                                                    ref={this.buttonSubmit} aria-label="Submit video" />
                                            </div>
                                            <div style={{ marginRight: "10px", color: "#333333" }} onClick={this.handleSubmit}>Submit</div>
                                            <div className="recorder-control" onClick={this.handleSubmit}>
                                                <div>
                                                    <CheckBoxRoundedIcon className="submit-btn"
                                                        style={{ ...playerRecorderControls, color: "rgb(14, 190, 14)" }}
                                                        ref={this.buttonSubmit} aria-label="Submit video" />
                                                </div>
                                            </div>

                                        </div>
                                    }

                                </React.Fragment>
                            </div>


                        </div>
                    </div>

                </div>
                {this.state.showSpinner && <Roller />}
            </React.Fragment >
        )
    }
}




const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const WebRecorderSeedFactory = withRouter(connect(
    
    mapDispatchToProps
)(WebRecorderSeedFactoryContainer));

WebRecorderSeedFactory.propTypes = {
    history: PropTypes.object,
    video: PropTypes.object,
    newVideo: PropTypes.func
};


export default WebRecorderSeedFactory;