import React, { Component } from "react";
import { loaderStyle } from '../../styles/modalStyles';
import Loader from 'react-loader-spinner';
import "../../styles/dashboard.css";
import { debounce } from "../../utils/utilMethods";
import DatePicker from "react-datepicker";
import ApiClient from "../../api/ApiClient";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { disambiguateSASURL } from "../../utils/urlSAS";
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import SelectSearch from 'react-select-search';
import Pagination from "react-js-pagination";

class AdminDashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            isLoading: true,
            queryString: "",
            selectedGroup: "none",
            isAdminMode: true,
            accountType: "PaidWiki",
            activePage: 1,
            valueTab: 0,
            totalItemsCount: 12
        }
    }

    userAccountTypes = [
        { name: "PaidDictionary", value: "PaidDictionary" },
        { name: "PaidWiki", value: "PaidWiki" },
        { name: "UnpaidDictionary", value: "UnpaidDictionary" },
        { name: "UnpaidWiki", value: "UnpaidWiki" }

    ]

    a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    focusVideo = (video) => {
        //
        this.setState({ selectedVideo: video });
    }

    focusModelVideo = (video) => {
        this.setState({ selectedModelVideo: video });
    }

    changeStyle = (elem) => {
        elem.target.parentNode.style.backgroundColor = "#cccccc";
        if (this.currentElement) {
            this.currentElement.style.backgroundColor = "#fff";
        }
        this.currentElement = elem.target.parentNode
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadFlaggedVideos();
    }

    renderModelVidUI = (vid) => (<div className="data-display-row" onClick={elem => {
        this.focusModelVideo(vid);
        this.changeStyle(elem);
    }}>
        <div className="display-row-item">
            {vid.sentence ? vid.sentence.substring(0, 10) : vid.name}
        </div>
        <div className="display-row-item">
            {vid.date.substring(0, 10)}
        </div>
        <div className="display-row-item">
            {vid.id}
        </div>
    </div>)

    renderVidUI = (vid) => (<div className="data-display-row" onClick={elem => {
        this.focusVideo(vid);
        this.changeStyle(elem);
    }
    }>
        <div className="display-row-item">
            {vid.sentence ? vid.sentence.substring(0, 10) : vid.name}
        </div>
        <div className="display-row-item">
            {vid.uName}
        </div>
        <div className="display-row-item">
            {vid.date.substring(0, 10)}
        </div>
        <div className="display-row-item">
            {vid.id}
        </div>
    </div>)

    processDefaultDisplayList = () => {

        const data = this.state.queryData;

        if (data.userVideos) {
            const uvids = data.userVideos.map(vid => {
                return (
                    this.renderVidUI(vid)
                )
            });
            this.setState({ uvids: uvids });
        }

    }

    handleAccountInputChange = async (event) => {
        await this.setState({
            accountType: event
        });
        await this.setState({ selectedVideo: undefined });
        this.updateQueryResults("default")
        this.loadFlaggedVideos()
    }

    componentDidMount = () => {
        this.updateQueryResults("default")
        this.loadFlaggedVideos(true)
    }

    loadFlaggedVideos = (initialize) => {
        if (initialize || this.state.valueTab == 0) {
            this.apiClient.postJSON(this, "/admin-dashboard/query-admin-pending", {
                activePage: this.state.activePage
            }).then(async (res) => {
                if (res && res.status) {
                    await this.setState({ pendingVideos: res.data, pendingVideosCount: res.count });
                    this.updateTotalItemsCount(); 
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });
        }
        
        if (initialize || this.state.valueTab == 1) {
            this.apiClient.postJSON(this, "/admin-dashboard/query-admin-approved", {
                activePage: this.state.activePage
            }).then(async (res) => {
                if (res && res.status) {
                    await this.setState({ approvedVideos: res.data, approvedVideosCount: res.count });
                    this.updateTotalItemsCount(); 
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });
        }
        
        if (initialize || this.state.valueTab == 2) {
            this.apiClient.postJSON(this, "/admin-dashboard/query-admin-flagged", {
                activePage: this.state.activePage
            }).then(async (res) => {
                if (res && res.status) {
                    await this.setState({ flaggedVideos: res.data, flaggedVideosCount: res.count });
                    this.updateTotalItemsCount(); 
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong With User Action";
                }
            });
        }
    }

    performQuery = (query) => {
        this.setState({ queryString: query, isLoading: true });
        this.apiClient.postJSON(this, "/admin-dashboard/query", {
            query: query, dateStart: this.state.startDate, dateEnd: this.state.endDate, accountType: this.state.accountType, activePage: this.state.activePage
        }).then(async (res) => {
            //
            if (res && res.status) {
                await this.setState({ queryData: res.data, isLoading: false });
                const displayList = this.processListGroupByOption();
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });
    }

    performModelQuery = (query) => {
        this.apiClient.postJSON(this, "/admin-dashboard/query/model", {
            query: query, dateStart: this.state.startDate, dateEnd: this.state.endDate
        }).then(async (res) => {
            if (res && res.status) {
                const displayList = this.processModelList(res.data);
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });
    }

    updateQueryResults = (query) => {
        this.debounceSearchText(query, this.performQuery);
    }

    updateModelQueryResults = (query) => {
        this.debounceSearchText(query, this.performModelQuery);
    }

    // Debounce input
    debounceInitTime;
    debounceSearchText = debounce();

    handleDateStart = async (date) => {
        await this.setState({ startDate: date });
        this.performQuery(this.state.queryString)
    }

    handleDateEnd = async (date) => {
        await this.setState({ endDate: date });
        this.performQuery(this.state.queryString)
    }

    flipByUserObj = async (key, state) => {
        const obj = { ...this.state.byUserObj };
        this.state.byUserObj[key][1] = state;
        await this.setState({ byUserObj: obj });
        this.renderUserUI();
    }

    flipBySignObj = async (key, state) => {
        const obj = { ...this.state.bySignObj };
        this.state.bySignObj[key][1] = state;
        await this.setState({ bySignObj: obj });
        this.renderSignUI();
    }




    renderSignUI = async () => {
        const self = this.state;
        let signsList = Object.keys(this.state.bySignObj);
        signsList.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return 1; }
            return 0;
        });
        const bySignUI = signsList.map((key) => {
            const components = this.state.bySignObj[key][0].map((vid) => {
                return (<div className="data-display-row group-by-detail" onClick={elem => {
                    this.focusVideo(vid);
                    this.changeStyle(elem);
                }
                }>
                    <div className="display-row-item">
                        {vid.sentence ? vid.sentence.substring(0, 10) : vid.name}
                    </div>
                    <div className="display-row-item">
                        {vid.uName}
                    </div>
                    <div className="display-row-item">
                        {vid.date.substring(0, 10)}
                    </div>
                    <div className="display-row-item">
                        {vid.id}
                    </div>
                </div>)
            });
            return (
                <div>
                    <div className="group-by-row" onClick={() => this.loadList(this.state.bySignObj[key][0])}>
                        <span className="group-by-title">{key.toUpperCase()}</span>
                        {!this.state.bySignObj[key][1] && <AddCircleIcon onClick={() => this.flipBySignObj(key, true)} className="group-by-icon" />}
                        {this.state.bySignObj[key][1] && <RemoveCircleRoundedIcon onClick={() => this.flipBySignObj(key, false)} className="group-by-icon" />}
                    </div>
                    {this.state.bySignObj[key][1] &&
                        components
                    }
                </div>
            )
        });
        this.setState({ uvids: bySignUI });
    }

    loadList = (videoArr) => {
        const videoListUI = videoArr.map((vid) => {
            //if (this.bigArr.indexOf(vid.convertedUrl) === -1) {
            return (
                // <div>
                <video autoplay controls muted preload='auto' onClick={elem => this.focusVideo(vid)}
                    className="admin-video-list" src={disambiguateSASURL(vid.convertedUrl, process.env)} type="video/mp4">
                </video>

                // </div>
            )
            // }
            // else {
            // disambiguateSASURL(vid.convertedUrl,process.env)
            //     return (null)
            // }
        });
        this.setState({ videoListing: videoListUI });
    }

    renderUserUI = async () => {
        const self = this.state;
        let userList = Object.keys(this.state.byUserObj);
        userList.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return 1; }
            return 0;
        });
        const byUserUI = userList.map((key) => {
            const components = this.state.byUserObj[key][0].map((vid) => {
                return (<div className="data-display-row group-by-detail" onClick={elem => {
                    this.focusVideo(vid);
                    this.changeStyle(elem);
                }
                }>
                    <div className="display-row-item">
                        {vid.sentence ? vid.sentence.substring(0, 10) : vid.name}
                    </div>
                    <div className="display-row-item">
                        {vid.uName}
                    </div>
                    <div className="display-row-item">
                        {vid.date.substring(0, 10)}
                    </div>
                    <div className="display-row-item">
                        {vid.id}
                    </div>
                </div>)
            })
            return (
                <div>
                    <div className="group-by-row" onClick={() => this.loadList(this.state.byUserObj[key][0])}>
                        <span className="group-by-title">{key.toUpperCase()}</span>
                        {!this.state.byUserObj[key][1] && <AddCircleIcon onClick={() => this.flipByUserObj(key, true)} className="group-by-icon" />}
                        {this.state.byUserObj[key][1] && <RemoveCircleRoundedIcon onClick={() => this.flipByUserObj(key, false)} className="group-by-icon" />}
                    </div>
                    {this.state.byUserObj[key][1] &&
                        components
                    }
                </div>
            )
        });
        this.setState({ uvids: byUserUI });
    }

    processModelDisplayList = async (data) => {
        if (data.modelVideos) {
            const mvids = data.modelVideos.map(vid => {
                return (
                    this.renderModelVidUI(vid)
                )
            });
            this.setState({ mvids: mvids });
        }
    }


    processSignDisplayList = async () => {
        const data = this.state.queryData;

        const bySignObj = {}
        
        if (data.userVideos) {
            data.userVideos.forEach(vid => {
                    
                if (!bySignObj[vid.name]) {
                    bySignObj[vid.name] = [[vid], false]
                }
                else {
                    bySignObj[vid.name][0].push(vid)
                }
            });
        }

        await this.setState({ bySignObj: bySignObj });

        this.renderSignUI(bySignObj);

    }


    processUserDisplayList = async () => {
        const data = this.state.queryData;
        const byUserObj = {

        }

        if (data.userVideos) {
            data.userVideos.forEach(vid => {
                if (!byUserObj[vid.uName]) {
                    byUserObj[vid.uName] = [[vid], false]
                }
                else {
                    byUserObj[vid.uName][0].push(vid)
                }
            });
        }

        await this.setState({ byUserObj: byUserObj });

        this.renderUserUI(byUserObj);

    }

    processModelList = (data) => {
        this.processModelDisplayList(data);
    }

    currentElement = null;


    processListGroupByOption = () => {
        switch (this.state.selectedGroup) {
            case "user":
                this.processUserDisplayList();
                break;
            case "sign":
                this.processSignDisplayList();
                break;
            default:
                this.processDefaultDisplayList();
                break;
        }
    }

    selectGroupByOption = async (type) => {
        await this.setState({ selectedGroup: type })
        this.processListGroupByOption();
    }

    closeModelVideo = () => {
        this.setState({ selectedModelVideo: null })
    }

    toggleAdminMode = async () => {
        await this.setState({ isAdminMode: !this.state.isAdminMode })
    }

    approveAllVideos = async () => {
        this.confirmVideo(true, this.state.pendingVideos.map((vid) => vid._id))
    }

    confirmVideo = (isApproved, vids) => {
        this.apiClient.postJSON(this, "/admin-dashboard/approveOrRejectVideo", {
            ids: vids.map((vid) => vid.toString()), isApproved: isApproved
        }).then(async (res) => {
            if (res && res.status) {
                let modder = [...this.state.pendingVideos];
                if (this.state.valueTab == 1) {
                    modder = [...this.state.approvedVideos]
                }
                else if (this.state.valueTab == 2) {
                    modder = [...this.state.flaggedVideos]
                }

                for (let i = 0; i < modder.length; i++) {
                    modder[i].hasPlayed = true;
                }
                modder = modder.filter((v) => {
                    return vids.indexOf(v._id) === -1;
                });
                
                if (this.state.valueTab == 0) {
                    await this.setState({ pendingVideos: modder });
                }
                else if (this.state.valueTab == 1) {
                    await this.setState({ approvedVideos: modder });
                }
                else if (this.state.valueTab == 2) {
                    await this.setState({ flaggedVideos: modder });
                }
                // refresh the data
                this.loadFlaggedVideos();
            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        })
    }

    updateTotalItemsCount = async () => {
        let totalItemsCount = 12;
        if (this.state.valueTab == 0) {
            totalItemsCount = this.state.pendingVideosCount;
        }
        else if (this.state.valueTab == 1) {
            totalItemsCount = this.state.approvedVideosCount;
        }
        else if (this.state.valueTab == 2) {
            totalItemsCount = this.state.flaggedVideosCount;
        }
        await this.setState({ totalItemsCount: totalItemsCount })
    }

    handleChange =  async (event, newValue)  => {
        await this.setState({ valueTab: newValue, activePage: 1 })
        await this.updateTotalItemsCount();
        this.loadFlaggedVideos();
    }


    render() {

        document.title = 'Admin Dashboard';

        if (!this.state.queryData && this.state.isAdminMode) {
            return (
                <div className="admin-loader">
                    <div>Loading Admin Dashboard in a few moments...</div>
                    <div style={{ fontSize: "16px" }}>Patientez, s'il vous plaît</div>
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
            )
        }

        let mapPendingVideos = null;
        if (this.state.pendingVideos) {
            mapPendingVideos = this.state.pendingVideos.map((vid) => {
                if (vid.isHandled) {
                    return null;
                }
                else {
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", flexDirection: "column", width: "300px", marginBottom: "30px", cursor: "pointer" }}>
                                {vid.hasPlayed &&
                                    <video controls muted
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                {!vid.hasPlayed &&
                                    <video controls autoPlay muted preload='auto'
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                <div>
                                    <span className="record-btn" onClick={() => this.confirmVideo(true, [vid._id])} style={{ marginLeft: "10px", marginRight: "30px" }}>Approve Video</span>
                                    <span className="record-btn" onClick={() => this.confirmVideo(false, [vid._id])}>Reject Video</span>
                                </div>

                            </div>

                        </React.Fragment>
                    )
                }
            })
        }
      
        let mapApprovedVideos = null;
        if (this.state.approvedVideos) {
            mapApprovedVideos = this.state.approvedVideos.map((vid) => {
                if (vid.isHandled) {
                    return null;
                }
                else {
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", flexDirection: "column", width: "300px", marginBottom: "30px", cursor: "pointer" }}>
                                {vid.hasPlayed &&
                                    <video controls muted
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                {!vid.hasPlayed &&
                                    <video controls autoPlay muted preload='auto'
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                <div>
                                    <span className="record-btn" onClick={() => this.confirmVideo(false, [vid._id])} style={{ marginLeft: "75px" }}>Reject Video</span>
                                </div>

                            </div>

                        </React.Fragment>
                    )
                }
            })
        }

        let mapFlaggedVideos = null;
        if (this.state.flaggedVideos) {
            mapFlaggedVideos = this.state.flaggedVideos.map((vid) => {
                if (vid.isHandled) {
                    return null;
                }
                else {
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", flexDirection: "column", width: "300px", marginBottom: "30px", cursor: "pointer" }}>
                                {vid.hasPlayed &&
                                    <video controls muted
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                {!vid.hasPlayed &&
                                    <video controls autoPlay muted preload='auto'
                                        src={vid.convertedUrl ?
                                            disambiguateSASURL(vid.convertedUrl, process.env) : disambiguateSASURL(vid.convertedUrl, process.env)
                                        } type="video/mp4">
                                    </video>
                                }
                                <div>
                                    <span className="record-btn" onClick={() => this.confirmVideo(true, [vid._id])} style={{ marginLeft: "75px" }}>Approve Video</span>
                                </div>

                            </div>

                        </React.Fragment>
                    )
                }
            })
        }

        return (

            <React.Fragment>
                {!this.state.isAdminMode && <div className="record-btn clicker" style={{ marginTop: "30px", height: "25px" }} onClick={this.toggleAdminMode}>
                    Toggle Video View Mode
                </div>
                }
                {!this.state.isAdminMode && this.state.valueTab == 0 && <div className="record-btn clicker" style={{ marginTop: "30px", height: "25px" }} onClick={this.approveAllVideos}>
                    Approve All
                </div>
                }
                {this.state.isAdminMode && <div>
                    <div style={{ marginTop: "30px", height: "25px" }} className="record-btn clicker" onClick={this.toggleAdminMode}>
                        Toggle Approval Mode
                    </div><div className="toggle-admin-blurb">Toggle the button above to switch between video view and video approval modes</div>
                </div>
                }

                {(this.state.queryData && !this.state.isAdminMode) &&
                    <div className="mini-video-list-toggle">
                        <AppBar position="static">
                        <Tabs value={this.state.valueTab} onChange={this.handleChange} aria-label="simple tabs example">
                            <Tab label="Pending" {...this.a11yProps(0)} />
                            <Tab label="Accepted" {...this.a11yProps(1)} />
                            <Tab label="Rejected" {...this.a11yProps(2)} />
                        </Tabs>
                        </AppBar>
                        <React.Fragment>
                            <div style={{ display: this.state.valueTab == 0 ? "contents" : "none" }}>
                                {mapPendingVideos}
                            </div>
                        </React.Fragment>
                        <React.Fragment>
                            <div style={{ display: this.state.valueTab == 1 ? "contents" : "none" }}>
                                {mapApprovedVideos}
                            </div>
                        </React.Fragment>
                        <React.Fragment>
                            <div style={{ display: this.state.valueTab == 2 ? "contents" : "none" }}>
                                {mapFlaggedVideos}
                            </div>
                        </React.Fragment>
                        <div className="dashboard-pagination">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={12}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                }

                {(this.state.queryData && this.state.isAdminMode) &&
                    <div role="main" className="mainFrame">
                        <SelectSearch
                            // autocomplete="off"
                            options={this.userAccountTypes}
                            value={this.state.accountType}
                            onChange={this.handleAccountInputChange}
                        >
                        </SelectSearch>
                        <div className="dashboard-top-row">
                            <div className="top-row-item">ASL Admin Dashboard</div>
                            <div>

                            </div>
                            <div className="top-row-item">
                                <input
                                    placeholder="Search For A Video or User"
                                    className="dashboard-search"
                                    onChange={($event) => this.updateQueryResults($event.target.value)}
                                />
                            </div>
                            <div className="top-row-item">
                                <DatePicker className="dashboard-search"
                                    id="range-start"
                                    name="range-start"
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    selected={this.state.startDate}
                                    placeholderText="Select Start Date"
                                    onChange={this.handleDateStart} />
                                <DatePicker className="dashboard-search"
                                    id="range-end"
                                    name="range-end"
                                    autoComplete="off"
                                    dateFormat="yyyy/MM/dd"
                                    selected={this.state.endDate}
                                    placeholderText="Select End Date"
                                    onChange={this.handleDateEnd} />
                            </div>
                        </div>
                        {this.state.isLoading &&
                            <div style={loaderStyle}>
                                <Loader
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                                Loading Admin Panel
                            </div>
                        }
                        {!this.state.isLoading && <div className="dashboard-panel">
                            <div className="dashboard-search-col">
                                <div className="filter-by-row">
                                    <div className="filter-by-label"><span>Group By : </span></div>
                                    <button className="filter-by-btn"
                                        onClick={() => this.selectGroupByOption("none")}
                                        style={{ background: this.state.selectedGroup === "none" ? "rgb(204, 204, 204)" : "#E8E8E8" }}
                                    >None</button>
                                    <button className="filter-by-btn"
                                        onClick={() => this.selectGroupByOption("user")}
                                        style={{ background: this.state.selectedGroup === "user" ? "rgb(204, 204, 204)" : "#E8E8E8" }}
                                    >User</button>
                                    <button className="filter-by-btn"
                                        onClick={() => this.selectGroupByOption("sign")}
                                        style={{ background: this.state.selectedGroup === "sign" ? "rgb(204, 204, 204)" : "#E8E8E8" }}
                                    >Sign</button>
                                    {/* <button className="filter-by-btn"
                                        onClick={() => this.selectGroupByOption("type")}
                                        style={{ background: this.state.selectedGroup === "type" ? "rgb(204, 204, 204)" : "#E8E8E8" }}
                                    >Type</button>
                                    <button className="filter-by-btn"
                                        onClick={() => this.selectGroupByOption("date")}
                                        style={{ background: this.state.selectedGroup === "date" ? "rgb(204, 204, 204)" : "#E8E8E8" }}
                                    >Date</button> */}
                                </div>
                                <div className="results-list">
                                    <div className="video-type-header">User Videos</div>
                                    <div className="data-display-row headers">
                                        {true &&
                                            <div className="display-row-item">Sign</div>
                                        }
                                        {false &&
                                            <div className="display-row-item">Sentence</div>
                                        }
                                        <div className="display-row-item" style={{ marginLeft: "-5px" }}>Created By</div>
                                        <div className="display-row-item" style={{ marginLeft: "-5px" }}>Date</div>
                                        <div className="display-row-item">ID</div>
                                    </div>
                                    <div className="results-body">
                                        {this.state.uvids}
                                    </div>
                                </div>
                                <div className="results-list">
                                    <div className="video-type-header">
                                        <input
                                            placeholder="Search For A Model Video"
                                            className="dashboard-search"
                                            onChange={($event) => this.updateModelQueryResults($event.target.value)}
                                        />
                                        <span className="model-video-lbl">Model Videos</span>
                                    </div>
                                    {this.state.mvids}
                                </div>
                            </div>
                            <div className="dashboard-display-col">
                                <div className="selected-video"  >
                                    {this.state.selectedVideo &&
                                        <div className="video-row">
                                            {this.state.selectedVideo &&
                                                <div>
                                                    <div className="video-lbl">USER</div>
                                                    <video controls autoPlay muted preload='auto'
                                                        className="admin-video-selected" src={this.state.selectedVideo.convertedUrl ?
                                                            disambiguateSASURL(this.state.selectedVideo.convertedUrl, process.env) : disambiguateSASURL(this.state.selectedVideo.convertedUrl, process.env)
                                                        } type="video/mp4">
                                                    </video>
                                                </div>
                                            }
                                            {(this.state.selectedVideo.modelVideo && this.state.selectedVideo.modelVideo.convertedUrl) &&
                                                <div className="close-modal" onClick={() => this.closeModelVideo()}>
                                                    <HighlightOffRoundedIcon className="close-modal-btn" />
                                                </div>
                                            }
                                            {this.state.selectedVideo.modelVideo &&
                                                <div>
                                                    <div className="video-lbl">MODEL</div>
                                                    <video controls autoPlay muted preload='auto'
                                                        className="admin-video-selected" src={disambiguateSASURL(this.state.selectedVideo.modelVideo.convertedUrl, process.env)} type="video/mp4">
                                                    </video>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {this.state.selectedVideo &&
                                        <div>
                                            <div className="selected-data-row">
                                                <div><span className="selected-video-data-item">Word :</span> <span className="factoid">{this.state.selectedVideo.name ? this.state.selectedVideo.name : this.state.selectedVideo.sentence}</span></div>
                                                <div><span className="selected-video-data-item">User :</span> <span className="factoid">{this.state.selectedVideo.uName}</span></div>
                                            </div>
                                            <div className="selected-data-row">
                                                <div><span className="selected-video-data-item">ID :</span> <span className="factoid  highlight">{this.state.selectedVideo.id}</span></div>
                                            </div>
                                        </div>
                                    }
                                </div>


                                {/* <div className="mini-video-list">
                                    {this.state.videoListing}
                                </div> */}
                            </div>
                        </div>}
                    </div>
                }
            </React.Fragment>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const AdminDashboard = connect(

    mapDispatchToProps
)(AdminDashboardContainer);

export default AdminDashboard
