import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
// import Switch from "react-switch";
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Select from 'react-select';
import ApiClient from "../../api/ApiClient";
import Player from './Player';
import { playerRecorderControls, playerRecorderControlText, playerRecorderControlSmall } from '../../styles/playerRecorderStyles';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import ReactPlayer from 'react-player';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import "../../styles/video.css";
import coins from '../../resources/coins.png';
import { setPlayProgress, pausePlayProgress } from "../../services/video/videoUtils";
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';


class PlayerWikiLayer extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            upVotes: 0,
            player: null,
            mode: true,
            // Tracks when a video recording or playback ready
            isRecorderLoaded: false,
            videoPlayback: false,
            recordTransitionValue: false,
            selectedControlButtons: [...this.readModeButtons],
            selectedControlsName: "record",
            downVotes: 0,
            tempCount: 0,
            isPlaying: false,
            topicTextUserList: [],
            stayOpen: false,
            selectedOption: null,
            currentVideoTimeProgress: 0,
            currRecording: null,
            autoReadMode: true,
            playbackRate: 1.0
        }
        this.recorder = null;

        this.video = React.createRef();
        this.buttonSubmit = React.createRef();
        // this.handleChange = this.handleChange.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
    }


    playbackEnded() {
        this.props.playbackEnded(this.props.sentence);
    }


    async componentDidMount() {

    }

    selectStyles = {
        control: styles => ({ ...styles, width: "260px", minWidth: "260px" })
    }


    // handleSelect(option) {
    //     this.setState({ selectedOption: option.value });
    // }

    // handleChange(checked) {
    //     this.setState({ checked: checked });
    //     this.props.recordingMode(checked);
    // }



    playPauseTimeout = null;
    progressBarInterval = null;

    playLock = null;

    resetPlayer = () => {
        clearTimeout(this.playPauseTimeout)
        this.setState({ currentVideoTimeProgress: 0 });
    }

    readMethods = {
        decreasePlaybackSpeed: () => {
            if (this.video.playbackRate > 0.25) {
                this.video.playbackRate = this.video.playbackRate - 0.25;
                this.setState({
                    playbackRate: this.video.playbackRate
                });
                this.readMethods.pause()
                this.readMethods.play()

            }
        },
        increasePlaybackSpeed: () => {
            if (this.video.playbackRate < 2) {
                this.video.playbackRate = this.video.playbackRate + 0.25;
                this.setState({ playbackRate: this.video.playbackRate });
                this.readMethods.pause()
                this.readMethods.play()
            }
        },
        play: async () => {
            await this.setState({ pauseProgressOn: false });
            const self = this
            this.video.playbackRate = this.state.playbackRate;
            clearTimeout(self.playPauseTimeout)
            if (self.video.currentTime === self.props.currRecording.durationMS) {
                self.video.currentTime = 0;
            }
            self.progressBarInterval = setPlayProgress(this, self.video.currentTime, this.props.currRecording.durationMS);
            self.video.play();
            const rmb = [...self.state.selectedControlButtons];
            rmb[2].selectedIndex = 1;
            this.playLock = true;
            self.setState({ selectedControlButtons: rmb });
            const timeout = (self.props.currRecording.durationMS - self.video.currentTime) * 1000 * (1 / self.video.playbackRate);

            const st = setTimeout(async () => {
                const rmb = [...this.state.selectedControlButtons];
                rmb[2].selectedIndex = 0;
                this.setState({ selectedControlButtons: rmb });
                // Switch to next video
                this.resetPlayer()
                const doesNextVideoExist = this.props.doesNextRecordingExist(this.props.sectionIndex, this.props.sentenceIndex);
                if (this.state.autoReadMode && doesNextVideoExist) {
                    const isContinue = await self.props.readNext();
                }
            }, timeout)
            self.playPauseTimeout = st;
        },
        pause: () => {
            this.video.pause();
            pausePlayProgress(this);
            const rmb = [...this.state.selectedControlButtons];
            rmb[2].selectedIndex = 0;
            this.setState({ selectedControlButtons: rmb });
        },
        rewind: () => {
            this.video.currentTime = 0;
            this.setState({ currentVideoTimeProgress: 0 });
        },
        playPrev: () => {
            this.resetPlayer()
            const doesPrevVideoExist = this.props.doesPrevRecordingExist(this.props.sectionIndex, this.props.sentenceIndex)
            if (doesPrevVideoExist) {
                let self = this;
                let secI = self.props.sectionIndex;
                let senI = self.props.sentenceIndex - 1;
                if (self.props.sentenceIndex === 0 && secI > 0) {
                    secI = secI - 1;
                    senI = null;
                }
                self.props.readNext(secI, senI);
            }
        },
        playNext: () => {
            this.resetPlayer();
            this.video.playbackRate = this.state.playbackRate;
            const doesNextVideoExist = this.props.doesNextRecordingExist(this.props.sectionIndex, this.props.sentenceIndex);
            if (doesNextVideoExist) {
                this.props.readNext();
            }
        },
        startRecording: () => {
            this.recordingStart()
        }
    }

    inx = 1

    readModeButtons = [
        //playPrev: 
        {
            control: [{
                ui: (<SkipPreviousRoundedIcon onClick={() => this.readMethods.playPrev()} style={playerRecorderControls} className="control" />)
            }],
            selectedIndex: 0
        },
        //rewind:  
        {
            control: [{
                ui: (<ReplayRoundedIcon style={playerRecorderControls} className="control" onClick={this.readMethods.rewind} />)
            }],
            selectedIndex: 0
        },
        //play_pause:
        {
            control: [
                {
                    ui: (<PlayCircleFilledRoundedIcon onClick={this.readMethods.play} style={playerRecorderControls} className="control" />)
                },
                {
                    ui: (<PauseCircleFilledRoundedIcon style={playerRecorderControls} className="control" onClick={this.readMethods.pause} />)
                }
            ],
            selectedIndex: 0
        },
        //playNext: 
        {
            control: [{
                ui: (<SkipNextRoundedIcon onClick={this.readMethods.playNext} style={playerRecorderControls} className="control" />)
            }],
            selectedIndex: 0
        },
        //SpeedMinus:  
        {
            control: [{
                ui: (<IndeterminateCheckBoxRoundedIcon style={playerRecorderControlSmall} className="txt-control" onClick={this.readMethods.decreasePlaybackSpeed} />)
            }],
            selectedIndex: 0
        },
        //SpeedPlus:  
        {
            control: [{
                ui: (<AddBoxRoundedIcon style={playerRecorderControlSmall} className="txt-control" onClick={this.readMethods.increasePlaybackSpeed} />)
            }],
            selectedIndex: 0
        }
    ]

    overlays = {
        finishSectionOverlay: (
            <div className="video-transition-overlay">
                <div className="overlay-row"><img src={coins} /><span className="money">+25 XP</span></div>
                <div className="overlay-row cntr">Congratulations, you've completed a section!</div>
                <br />
                <div className="overlay-row record-again-btn">
                    <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", color: "white" }} />
                    Next Section
                </div>
            </div>
        )
    }

    video = null

    setRef = (ref) => {
        const self = this;
        self.video = ref;

        // Account for latency issues
        try {
            //self.video.style.transform = 'scale(-1, 1)';
        }
        catch (e) {

        }
    }

    async componentWillReceiveProps() {
        this.resetPlayer()
    }

    toggleAutoRead = () => {
        const self = this;
        self.setState({ autoReadMode: !this.state.autoReadMode });
    }

    render() {
        const { stayOpen } = this.state;
        return (
            <React.Fragment>
                <Player
                    videos={this.state.selectedOption ? this.props.sentenceVideos.filter((video) => video.user.userid == this.state.selectedOption) : this.props.sentenceVideos}
                    sentence={"bla"}
                    articleId={"bla"}
                    title={"bla"}
                    isRecorderLoaded={this.state.isRecorderLoaded}
                    mode={true}
                    isPlaying={this.state.isPlaying}
                    toggleAutoRead={this.toggleAutoRead}
                    autoReadMode={this.state.autoReadMode}
                    loggedInUser={this.props.loggedInUser}
                    sentenceVideos={this.props.sentenceVideos}
                    recordTransitionState={this.state.recordTransitionState}
                    setRef={this.setRef}
                    controls={this.state.selectedControlButtons}
                    initializeRecorderFunc={this.startStopRecording}
                    selectedControlsName={this.state.selectedControlsName}
                    videoTransitionOverlay={this.state.videoTransitionOverlay}
                    currentVideoTimeProgress={this.state.currentVideoTimeProgress}
                    playbackRate={this.state.playbackRate}
                    video={this.video}
                    currRecording={this.props.currRecording}
                    play={this.readMethods.play}
                />
            </React.Fragment>
        )
    }
}

export default PlayerWikiLayer;
