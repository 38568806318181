import React, { Component } from "react";
import { Row, Col, Button, Input } from 'reactstrap';
import Scoreboard from './components/gamify/Scoreboard';
import SocialIconsHeader from './components/social/SocialIconsHeader';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Tooltip } from 'reactstrap';
import ApiClient from "./api/ApiClient";
import { MdInfoOutline } from "react-icons/md";
import ReactTooltip from 'react-tooltip'
import DisappearNotification from "./modals/disappearNotification";
import { disambiguateSASURL } from "./utils/urlSAS";
import { capitalize } from "./utils/textMethods";
// import { ReactComponent as CollageSvg } from './resources/HomepageCollageSVG.svg';
// import { ReactComponent as SignsSVG } from './resources/SignsSVG.svg';

class Home extends Component {
    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.state = {
            message: '',
            throttleCount: 6,
            verifiedTotal: 0,
            recordedTotal: 0,
            loadedCount: 0,
            hasMore: false,
            isLoading: false,
            TotalRecordedVideos: 0,
            showInvisibleModal: false,
            TotalVerifiedVideos: 0,
            TotalUserVideosCount: 0,
            TotalModelVideosCount: 0,
            RecordVideoPercentage: 0,
            VerifyVideoPercentage: 0,
            tooltipOpen: false,
            cancelTooltipOpen: false,
            userLevelStatusText: "",
            userLevelName: this.props.userLevel.name,
            isLoggedIn: this.props.isLoggedIn,
            showStayInTouchModal: false,
            bannerHtml: "",
            stayInTouchEmail: ""
        }

        this.toggle = this.toggle.bind(this);
        this.toggleCancelTooltip = this.toggleCancelTooltip.bind(this);
    }

    displayInline = {
        display: "inline-block"
    }

    scoreCount = {
        display: "inline-block",
        paddingLeft: "15px",
        fontWeight: "600",
        //textDecoration:"underline",
        fontSize: "20px"
    }

    buttonStyle = {
        float: "right",
        margin: "5px"
    }

    postMessage = {
        maxHeight: "170px"
    }

    marginTopBottom = {
        marginTop: "8px",
        marginBottom: "5px"
    }

    numberLabelfontSz = {
        fontSize: "1.25rem"
    }

    componentDidMount() {
        // New accounts only
        const self = this;

        if (this.props.location.state && this.props.location.state.isNewAccount) {
            this.notify()
            this.props.loadUser()
        }

        this.loadUserScores();
    }



    notify = () => {
        this.setState({ showInvisibleModal: true })
        setTimeout(() => {
            this.setState({ showInvisibleModal: false })
        }, 3000);
    }

    componentWillReceiveProps(props) {
        // Show post-signup confirmation on new page
        this.setState({ isLoggedIn: props.isLoggedIn, signedInGroupId: props.signedInGroupId, userLevelName: props.userLevel.name });
        if (!this.state.scoresDataLoaded) {
            this.loadUserScores();
        }
    }

    loadUserScores = () => {
        this.apiClient.getJSON(this, "/getUserScores", "")
            .then((res) => {
                if (res && res.status) {
                    if (res !== null && res.data.userName) {
                        var scoresData = res.data;
                        const wikiCount = scoresData.wikiCount;

                        this.setState({
                            userName: scoresData.userName,
                            userLevel: scoresData.level,
                            pointScore: scoresData.xpPoints,
                            userSpecificLevel: scoresData.level,
                            recordedTotal: scoresData.totalRecordedVideos,
                            verifiedTotal: scoresData.totalVerifiedVideos,
                            TotalUserVideosCount: scoresData.totalUserVideos,
                            scoresDataLoaded: true
                        });
                        this.getUserLevelStatus();
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
            .catch(err => {
                this.setState({ isLoggedOut: false });
            });
    }
    stopEventPropagation = (event) => {
        event.stopPropagation();
    }

    getUserLevelStatus = () => {
        var text = "You are currently at " + this.getUserLevelDisplayText() + " level! ";
        var currentTotalCount = this.state.TotalRecordedVideos;
        if (currentTotalCount < 10) {
            var countReqForNextLevel = 10 - currentTotalCount;
            text += countReqForNextLevel + " videos required to go to pro level!";
        } else if (currentTotalCount <= 49) {
            var countReqForNextLevel1 = 50 - currentTotalCount;
            text += countReqForNextLevel1 + " videos required to go to beast level!";
        }
        this.setState({ userLevelStatusText: text });
    }
    getUserLevelDisplayText = () => {
        if (this.state.userLevelName === "Rookie") {
            return "Rookie";
        }
        return this.state.userLevelName;
    }

    generateComplexObjects(messages, flattened) {
        messages.forEach(message => {
            var replies = flattened.filter(m => m.parent === message._id);
            if (replies) {
                message.replies = replies;
                this.generateComplexObjects(replies, flattened);
            }
        });
    }


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }



    emptyMessage = (event) => {
        this.setState({ message: '' });
    }

    determineCTA = () => {
        if (this.state.TotalRecordedVideos < 50) {
            this.props.history.push("/record");
        }
        else if (this.state.TotalVerifiedVideos > 50) {
            this.props.history.push("/verify");
        }
        else {
            this.props.history.push("/wiki");
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    toggleCancelTooltip() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }
    render() {
        document.title = 'Home - The ASL Data Community';
        const submitEnabled = this.state.message.trim() !== "";
        let self = this;
        // if (this.state.userLevel) {
        //     
        // }
        //const loginText = <a href="/tpLogin">login or create an account</a>;
        return (
            <React.Fragment>
                <DisappearNotification isTransparent={false} text="Thank you for signing up!" symbol="check-mark" showInvisibleModal={this.state.showInvisibleModal}></DisappearNotification>
                <div className="home-box">
                   
                    <div className="central-cols">
                        <div className="fstcol-1">
                            <Scoreboard signedInGroupId={this.state.signedInGroupId} />
                        </div>
                        <div className="fstcol-2">
                            {/* <CollageSvg style={{ width: "100%" }} /> */}
                            <img alt="mosaic" src={process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=svgs&blobName=Mosaic_PC_Overlay.png"} />
                        </div>
                    </div>
                </div>
                <div className="home-bottom">
                    <div className="explainer-video-cont" style={{ width: "600px" }}>
                        <video key="1.1" controls loop preload='auto' style={{ width: "100%" }}>
                            <track id="enTrackWork" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                            <source src={disambiguateSASURL(process.env.REACT_APP_SERVER_URL + "/getBlob?containerName=svgs&blobName=HomepageVideo.mp4", process.env)} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                    <div className="explainer-text-cont">
                        <div className="purpose-header">Our Purpose</div>
                        <div className="purpose-txt">
                            The website is a type of citizen science site, where people participate for the purpose of advancing science. Initially, the site will primarily collect individual signs from ASL students. Once this version is released and vetted, we will proceed to collect longer utterances from fluent signers, which are essential to creating accurate language models.
                        </div>
                        <div className="btn-container">
                            <button className="record-btn" onClick={() => this.props.history.push("/explore")} style={{ marginRight: "15px" }}>Explore Videos</button>
                            {/* <button className="record-btn" onClick={() => this.props.history.push("/explore")}>
                                <svg style={{ marginRight: "7px", marginBottom: "3px" }} width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0573 0C8.1481 0 3.06904 3.32988 0.269043 8C3.06669 12.6659 8.14339 16 14.0573 16C19.9669 16 25.0455 12.6701 27.8455 8C25.0474 3.33412 19.9712 0 14.0573 0ZM20.1749 8C20.1749 11.3882 17.4455 14.1176 14.0573 14.1176C10.669 14.1176 7.93963 11.3882 7.93963 8C7.93963 4.61176 10.669 1.88235 14.0573 1.88235C17.4455 1.88235 20.1749 4.61176 20.1749 8ZM2.48081 8C3.13963 7.10588 4.73963 5.12941 7.32787 3.67059C6.52787 4.94118 6.05728 6.4 6.05728 8C6.05728 9.6 6.52787 11.0588 7.32787 12.3294C4.78669 10.8706 3.13963 8.89412 2.48081 8ZM20.7867 12.3294C21.5867 11.0588 22.0573 9.6 22.0573 8C22.0573 6.4 21.5867 4.94118 20.7867 3.67059C23.3279 5.12941 24.9749 7.10588 25.6337 8C24.9749 8.89412 23.3279 10.8706 20.7867 12.3294ZM11.2337 8C11.2337 6.44706 12.5043 5.17647 14.0573 5.17647C15.6102 5.17647 16.8808 6.44706 16.8808 8C16.8808 9.55294 15.6102 10.8235 14.0573 10.8235C12.5043 10.8235 11.2337 9.55294 11.2337 8Z" fill="white" />
                                </svg>
                                Explore
                            </button> */}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default Home;
