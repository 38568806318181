import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Octicon, { Play, X } from '@githubprimer/octicons-react';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { disambiguateSASURL } from "../../utils/urlSAS";

function doMath(correct, incorrect) {
    const votes = Math.floor(incorrect + correct)
    const result = Math.floor((correct / votes) * 100)
    if ((correct + incorrect) === 0) {
        return "NA"
    }
    else { 
        return result + "%";
    }
}

class User extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    }
  

    constructor(props) {
        super(props);

        this.props = this.props ? this.props : this.props;

        this.data = [{
            id: "FEC006",
            title: "Cow",
            thumbnail: "/thumbs/COW.jpg",
            video: "/videos/COW.mp4",
            correct: 6,
            incorrect: 3,
            model: "/videos/COW.mp4"
        },
        {
            id: "2196F3",
            title: "Rooster",
            thumbnail: "/thumbs/ROOSTER.jpg",
            video: "/videos/ROOSTER.mp4",
            correct: 12,
            incorrect: 0,
            model: "/videos/ROOSTER.mp4"
        },
        {
            id: "FE5621",
            title: "Drunk",
            thumbnail: "/thumbs/DRUNK.jpg",
            video: "/videos/DRUNK.mp4",
            correct: 9,
            incorrect: 2,
            model: "/videos/DRUNK.mp4"
        },
        {
            id: "673AB3",
            title: "Pliers",
            thumbnail: "/thumbs/pliers.jpg",
            video: "/videos/pliers.mp4",
            correct: 0,
            incorrect: 0,
            model: "/videos/pliers.mp4"
        },
        {
            id: "FE5611",
            title: "Hotel",
            thumbnail: "/thumbs/HOTEL.jpg",
            video: "/videos/HOTEL.mp4",
            correct: 13,
            incorrect: 1,
            model: "/videos/HOTEL.mp4"
        },
        {
            id: "173AB7",
            title: "Hairdryer",
            thumbnail: "/thumbs/HAIRDRYER.jpg",
            video: "/videos/HAIRDRYER.mp4",
            correct: 15,
            incorrect: 2,
            model: "/videos/HAIRDRYER.mp4"
        }
        ]


        this.sortProperties = [
            { id: 'title', sortDescending: true }
        ]
        
    }


    render() {
        document.title = 'User - The ASL Data Community';
        //this.eventHandler = this.eventHandler.bind(this);

        const NewLayout = ({ Table, Pagination, Filter, SettingsWrapper }) => (
            <div>
                <div className="filterdiv">
                    <Filter />
                    <Pagination />
                </div>
                <Table />

            </div>
        );

        const CustomRowComponent = connect((state, props) => ({
            rowData: plugins.LocalPlugin.selectors.rowDataSelector(state, props)
        }))(({ rowData }) => (
            <tr className="myInfoRow" style={{
                backgroundColor: "#EDEDED",
                border: "1px solid #777",
                padding: 5,
                margin: 10,
            }}>
                <td className="videoThumbCell">
                        <div className="videoplaceholder user mb-1">
                            <video id="videoModel" controls muted preload='auto'>
                                <source src={rowData.video} type="video/mp4" />
                                <track id="enTrack" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                </td>
                <td className="video-title">
                    <h3 className="video-name h4">{rowData.title}</h3>
                </td>
                <td className="video-accuracy">                     
                    {doMath(rowData.correct, rowData.incorrect)}
                </td>
                <td className="video-viewMyVideo text-center">
                    <Button className="btn btn-primary btn-view" data-video={rowData.title} id="viewbutton"><Octicon icon={Play} className="height-1rem mr-1" /> View</Button>
                    <Button className="btn btn-danger btn-delete"><Octicon icon={X} className="height-1rem mr-1" id="deletebutton" />  Delete</Button>
                </td>
            </tr>
        ));

          return (
            <React.Fragment>
                <Row>
                      <Col md="12" className="pt-2">
                        <h1 className="pt-2 pb-1">User Information</h1>
                      </Col>
                  </Row>
                  <Row className="userinfo">
                      <Col md="3" className="pt-2 profiledeets">
                          <img className="profilephoto" src="/daniellebragg.jpg" alt="" />
                          <p className="h4">Danielle Bragg</p>
                          <p>Post-doctorate researcher</p>
                      </Col>
                      <Col md="9" className="pt-4 userstats">
                          

                          <table aria-labelledby="qstats" className="table-bordered userstatstable">
                            <caption><h2 className="h4" id="qstats">Quick stats</h2></caption>
                              <tbody>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">First contributions</th>
                                    <td className="p-1 pl-2 pr-2">January 19, 2019</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">Lastest contributions</th>
                                    <td className="p-1 pl-2 pr-2">March 2, 2019</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">Most contributions in one day</th>
                                    <td className="p-1 pl-2 pr-2">8</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">Contributions</th>
                                    <td className="p-1 pl-2 pr-2">42</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">Other user contributions verified</th>
                                    <td className="p-1 pl-2 pr-2">11</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="p-1 pl-2 pr-2">Average accuracy</th>
                                    <td className="p-1 pl-2 pr-2">92.7%</td>
                                </tr>
                              </tbody>
                          </table>
                      </Col>
                  </Row>
                <Row>
                      <Col md="12" className="pt-4">
                          <h2>My contributions</h2>
                        <div className="pt-2 pb-2 max-h-75">

                            <div className="overflow-auto bg-light border-dark border mh-100">
                                <div className="p-2 user">


                                      <div className="gridwrapper">
                                          <Griddle
                                              data={this.data}
                                              pageProperties={{
                                                  pageSize: 10
                                              }}

                                              columnMetadata={this.exampleMetadata}
                                              showFilter={false}
                                              showSettings={false}
                                              //columns={["thumbnail", "title", "accuracy", "view"]}


                                              plugins={[plugins.LocalPlugin]}
                                              sortProperties={this.sortProperties}
                                              components={{
                                                  //TableContainer: CustomTableComponent,
                                                  Row: CustomRowComponent,
                                                  Layout: NewLayout
                                              }}
                                              styleConfig={{
                                                  classNames: {
                                                      NextButton: 'btn btn-primary ml-3',
                                                      PreviousButton: 'btn btn-primary mr-3',
                                                      //TableHeading: 'd-none',
                                                      Filter: 'ml-2 mr-3 pl-1 pr-1 mb-2 filterControl d-inline-block',
                                                      Pagination: 'paginationControl d-inline-block',
                                                      TableBody: 'dataContentBody',
                                                      Table: 'myVideosTable'
                                                  }
                                              }}
                                          >
                                              <RowDefinition>
                                                  <ColumnDefinition id="thumbnail" title="Preview" sortable={false} order={1} />
                                                  <ColumnDefinition id="title" title="Title" order={2} />
                                                  <ColumnDefinition id="accuracy" title="Accuracy" sortable={false} order={3} />
                                                  <ColumnDefinition id="view" title=" " sortable={false} order={4} />
                                              </RowDefinition>
                                          </Griddle>
                                      </div>
                                                                          
                                    
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default User;
