import React, { Component } from "react";
import SocialIconsHeader from '../social/SocialIconsHeader';
import { PropTypes } from "prop-types";
import BlockUi from 'react-block-ui';
import ReportVideoButton from '../report/ReportVideoButton';
import Select from 'react-select';
import Octicon, { PrimitiveDot, PrimitiveSquare, Sync, Check, Search } from '@githubprimer/octicons-react';
import ApiClient from "../../api/ApiClient";
import ReactTooltip from 'react-tooltip';
import CookieConsent from "react-cookie-consent";
import { invisibleModalStyle, modalStyles, loaderStyle } from '../../styles/modalStyles';
import { Modal } from 'react-bootstrap';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import "../../styles/record.css";
import DisappearNotification from "../../modals/disappearNotification";
import Loader from 'react-loader-spinner';
import "../../styles/video.css";
import "../../styles/vocab.css";
import { playerRecorderControls } from '../../styles/playerRecorderStyles';
import { capitalize } from "../../utils/textMethods";
import { disambiguateSASURL } from "../../utils/urlSAS";
import { tempMirrorFirefox } from "../../utils/utilMethods";
import WebRecorder from './../player-recorder/WebRecorder';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as rdd from 'react-device-detect';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import { connect } from 'react-redux';
import { updateScore, populateStore, requestFailedModal } from '../../actions';


class RecordContainer extends Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.video = React.createRef();
        this.state = {


            updateUserLevel: this.props.updateUserLevel,
            signedInGroupId: this.props.signedInGroupId,
            showHavingTroubleButton: false,
            videoChoice: false,
            flagType: undefined,
            flagReason: undefined,
            // showhideLabel:this.props.location.state.showhideLabel,
            // showLabelButton:this.props.location.state.showhideLabel ? false : true,
            // hideLabelButton:this.props.location.state.showhideLabel ? true : false,

            isPullingSearchList: false,
            blockPage: false,
            video: undefined,
            isLoggedIn: this.props.isLoggedIn,
            loggedInUser: this.props.loggedInUser,
            showFlagIcon: this.props.showFlagIcon,
            recordVideos: [],
            dataFirstLoad: true,
            showInvisibleModal: false,
            searchOptionsList: [],
            stayOpen: false,
            showHavingTroubleButton: false,
            currentVideoIndex: 0,
            selectedOption: null,
            showbuttons: false,
            showVideoState: true,
            recordMode: false,
            showSelectDropdown: false,
            requiredVideoValue: '',
            showVideoLabel: true,
            showLabelButton: true,
            hideLabelButton: false,
            showStudyMessage: false,
            studyCode: this.props.studyCode,
            totalRecordings: this.props.totalRecordings,
            showVideosCount: !this.props.studyCompleted,
            searchText: "",
            newRecordedVideos: 0,
            videosLeft: []
        }
    }

    displayInline = {
        display: "inline-block",
        marginLeft: "75px"
    }

    video = {
        position: "relative",
        display: "block",
        margin: "0 auto",
        width: "450px"
    }

    btnStylingSmall = {
        fontWeight: "600",
        fontSize: "16px",
        marginRight: "20px"
    }


    displayhtml = {
        display: "inline-block"
    }

    showHavingTrouble = () => {
        this.setState({ showHavingTroubleButton: true });
    }

    click = () => this.props.history.push(`/record/${this.props.video.name}`);

    showLabel = () => {
        this.setState({ showhideLabel: true, showLabelButton: false, hideLabelButton: true });
    }

    hideLabel = () => {
        this.setState({ showhideLabel: false, hideLabelButton: false, showLabelButton: true });
    }

    heading = {
        width: "300px",
        margin: "24px auto .7rem",
        textAlign: "center"
    }

    video = {
        width: '450px',
        position: "relative",
        display: "block",
        margin: "0 auto"
    }

    btnStyling = {
        fontWeight: "800",
        fontSize: "18px"
    }

    btnStylingSmall = {
        fontWeight: "600",
        fontSize: "16px",
        marginRight: "20px"
    }

    buttonPos = {
        textAlign: "left"
    }

    displayhtml = {
        display: "inline-block"
    }

    marginLeft = {
        marginLeft: "15px"
    }

    selectStyles = {
        control: styles => ({ ...styles, width: "450px", minWidth: "450px" })
    }

    clearVideoOptions = () => {
        //this.video.srcObject = null;
    }

    clearPrevVideo = async () => {
        this.video.src = null;
        if (!this.video.srcObject) {
            var camera = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
            this.video.srcObject = camera;
        }
    }

    // var self = this;
    // this.video.srcObject = this.mountedCamera;

    recordingStart = async () => {
        tempMirrorFirefox("video-recorder", true)
        return new Promise(async (resolve, reject) => {
            this.video.muted = true;
            this.video.volume = 0;
            if (!this.video.srcObject) {
                var camera = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
                this.video.srcObject = camera;
                resolve(camera)
            }
            else {
                resolve(this.video.srcObject)
            }
        });

    }

    mountedCamera = null

    incrementRecordedCount = () => {
        this.setState({ newRecordedVideos: this.state.newRecordedVideos + 1 })
    }

    stopRecordingCallback = async (targetSRC) => {
        tempMirrorFirefox("video-recorder", false)
        let self = this
        this.video.srcObject.getTracks().forEach((track) => {
            track.stop();
        });
        this.video.muted = true;
        this.mountedCamera = { ...this.video.srcObject };
        this.video.srcObject = null;
        this.video.src = null;
        this.video.src = targetSRC;
        // this.buttonStart.current.disabled = false;
    }


    generateSearchOptions = (data) => {
        const uniqueList = {};
        data.forEach((datum) => {
            const name = datum.name;
            if (!uniqueList[name]) {
                const searchTerm = {
                    name: name,
                    value: name
                }
                uniqueList[name] = searchTerm;
            }
        });
        const resArr = Object.values(uniqueList);
        this.props.setDropdownState(resArr);
    }

    setRef = (ref) => {
        if (ref) {
            let self = this;
            self.video = ref;
            this.initializeCamera();
            self.video.style.transform = 'scale(-1, 1)';
        }
        else {
            // Gets called on unmount, oddly
            this.video.srcObject = null;
        }
    }


    stream = null;

    async componentDidMount() {


        let urlComponents = window.location.href.split("?list=");
        let listId = urlComponents[urlComponents.length - 1].replace("/", "");

        await this.setState({ listId: listId });

        //this.initializeCamera();
        //this.loadSearchOptions();
        this.props.updateCount(this.state.loggedInUser);
        this.setState({ showSelectDropdown: false });
        if (this.state.studyCode !== '') {
            this.setState({ showStudyMessage: true });
        }
        if (!this.props.isLoggedIn) {
            this.setState({ showbuttons: false, showVideoState: true, showSelectDropdown: false });
        }
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.videoChoice === "randomVideo") {
                this.setState({ showVideoState: true, showbuttons: false });
            } else if (this.props.location.state.videoChoice === "myChoiceVideo") {
                this.setState({ showSelectDropdown: true, showbuttons: false, showVideoState: false });
            }

        }

        this.cameraCleanup = this.props.history.listen((location, action) => {
            try {

                this.video.srcObject.getTracks().forEach((track) => {
                    track.stop();
                });

            }
            catch (e) {

            }
        });

    }

    componentWillUnmount() {
        this.cameraCleanup();
    }



    componentWillReceiveProps(props) {

        if (this.state.searchOptionsList.length < 1 && !this.state.isPullingSearchList) {
            this.loadSearchOptions();
        }
        if (props.signedInGroupId) {
            this.setState({ signedInGroupId: props.signedInGroupId });
        }
        if (this.state.searchText !== props.searchText) {
            this.searchForSign(props.searchText);
        }
        this.setState({ showSelectDropdown: false, searchText: props.searchText, totalRecordings: props.totalRecordings, showVideosCount: !props.studyCompleted });
        if (props.isLoggedIn) {
            this.setState({ showbuttons: false, showVideoState: true, showSelectDropdown: false });
            if ((this.state.dataFirstLoad)) {
                var self = this;
                this.getRecordVideos();
                // this.getVerificationCount().then(res => {
                //     this.setState({ totalVerifiedVideos: res.totalVerificationsCount, showVideosCount: !res.studyCompleted });
                // });
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    searchForSign = (sign) => {

        if (sign !== null && sign !== "") {
            this.apiClient.getJSON(this, "/getUserSelectedVideo/signQuery", "?signQuery=" + sign)
                .then(res => {
                    if (res && res.status) {
                        if (res.status === 200) {
                            const tempVids = [res.data, ...this.state.recordVideos];
                            this.setState({ video: res.data, showVideoState: true, recordVideos: tempVids })
                        }
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                })
        } else {
            this.setState({ requiredVideoValue: 'Video name is required.' });
        }
    }

    skip = async () => {
        // const target = this.state.recordVideos[this.state.currentVideoIndex];
        // const tid = target._id;
        // target.completed = true;
        // var body = { userid: this.props.userId, videoid: tid };
        // var consideredVideos = this.state.recordVideos.filter((uv) => !uv.completed);
        // let newIndx = this.state.currentVideoIndex + 1;
        // if (consideredVideos.length < 1) {
        //     await this.setState({ recordVideos: [] })
        //     this.videosDefined = false;
        //     newIndx = 0;
        //     await this.getRecordVideos();
        // }
        // this.setState({ currentVideoIndex: newIndx, video: this.state.recordVideos[newIndx] });


        // this.revertToOriginalState();
        // this.apiClient.postJSON(this,'/getVerifyVideos/skipVerify', body).then((res) => {
        //         this.nextVideo()
        // });

        var self = this;
        const target = this.state.recordVideos[this.state.currentVideoIndex];

        const tid = target._id;

        target.completed = true;
        var body = { userid: this.props.loggedInUser, modelid: tid };

        // this.revertToOriginalState();
        this.apiClient.postJSON(this, '/getRecordVideos/skipRecord', body).then(async (res) => {
            if (res && res.status) {
                var consideredVideos = this.state.recordVideos.filter((uv) => !uv.completed);
                let newIndx = this.state.currentVideoIndex + 1;
                if (consideredVideos.length < 1) {
                    await this.setState({ recordVideos: [] })
                    this.videosDefined = false;
                    newIndx = 0;
                    await this.getRecordVideos();
                }
                this.setState({ currentVideoIndex: newIndx, video: this.state.recordVideos[newIndx] });

            }
            else if (res && res.httpServerError) {
                // throw "Something Went Wrong With User Action";
            }
        });






    }

    loadSearchOptions = async () => {
        await this.setState({ isPullingSearchList: true });;
        this.apiClient.getJSON(this, "/getUserSelectedVideo/load-all-model-seeds", "")
            .then(async (res) => {
                if (res && res.status) {
                    await this.setState({ searchOptionsList: res.data });
                    await this.setState({ isPullingSearchList: false })
                    this.generateSearchOptions(res.data);
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            })
    }

    getSelectedVideo = () => {
        var videoId = this.state.selectedOption;
        if (videoId !== null && videoId !== "") {
            this.apiClient.getJSON(this, "/getUserSelectedVideo", "?videoId=" + videoId.value)
                .then(res => {
                    if (res && res.status) {
                        this.setState({ video: res.data, showVideoState: true });
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                })
        } else {
            this.setState({ requiredVideoValue: 'Video name is required.' });
        }
    }

    onClick = async () => {
        // this.props.history.push({ pathname: `/webcam/${this.state.video.name}`, state: { video: this.state.video, videoChoice: (this.state.randomVideo === true ? "randomVideo" : "myChoiceVideo"), showhideLabel: this.state.showVideoLabel } });
        this.setState({ recordMode: true, videoChoice: this.state.randomVideo === true ? "randomVideo" : "myChoiceVideo" });
        tempMirrorFirefox("video-recorder", true);
    }

    playRandomVideo = () => {
        this.setState({ showVideoState: true, showbuttons: false });
    }

    playMyChoiceVideo = () => {
        this.setState({ showSelectDropdown: true, showbuttons: false, showLabelButton: false });
    }

    showHavingTrouble = () => {
        this.setState({ showHavingTroubleButton: true });
    }

    showLabel = () => {
        this.setState({ showVideoLabel: true });
    }

    notify = () => {
        this.setState({ showInvisibleModal: true })
    }

    revertToOriginalState = async () => {
        this.state.recordVideos[this.state.currentVideoIndex].completed = true;
        let newIndx = this.state.currentVideoIndex + 1;
        var consideredVideos = this.state.recordVideos.filter((uv) => !uv.completed);
        await this.setState({ videosLeft: consideredVideos.length });
        if (consideredVideos.length < 1) {
            this.notify()
            await this.setState({ recordVideos: [] })
            this.videosDefined = false;
            newIndx = 0;
            await this.getRecordVideos();
        }
        this.setState({ recordMode: false, currentVideoIndex: newIndx, video: this.state.recordVideos[newIndx] });
    }

    videosDefined = false;

    getRecordVideos = async () => {
        let self = this;
        return await new Promise((resolve, reject) => {
            this.setState({ dataFirstLoad: false })
            if (!this.videosDefined) {
                this.videosDefined = true;
                let urlComponents = window.location.href.split("?list=");
                let listId = urlComponents[urlComponents.length - 1];
                this.apiClient.getJSON(this, "/vocabList/record", "?listId=" + listId.replace("/", "")).then(async (res) => {
                    if (res && res.status) {
                        if (!res.data) {
                            this.setState({ noContent: true });
                            return;
                        }
                        await this.setState({ videosLeft: res.data.length })
                        this.setState({ recordVideos: res.data, ready: true, isUserStudy: false, video: res.data[0], currentVideoIndex: 0 });
                        resolve(true);

                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }

                })
            }
        })
    }

    initializeCamera = () => {
        // setTimeout(() => {
        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((camera) => {
            let self = this;
            self.video.srcObject = camera;
            var device = camera.getTracks()[0].label;
            var deviceinfo = rdd.deviceDetect();
        }).catch(function (error) {
            // eslint-disable-next-line
            alert('Unable to capture your camera. Please check console logs. Record.js');
            // eslint-disable-next-line
            console.error(error);
        });
        // },4000);



    }


    hideLabel = () => {
        this.setState({ showVideoLabel: false });
    }
    handleClose = () => {
        this.setState({ showStudyMessage: false });
    }

    // onFlagVideo = async () => {
    //     var self = this;

    //     const target = this.state.recordVideos[this.state.currentVideoIndex];
    //     const tid = target._id;

    //     var data = { userId: this.props.userId, userVideoId: tid, 
    //         convertedUrl: target.convertedUrl, 
    //         flagType: this.state.flagType, 
    //         flagReason: this.state.flagReason
    //      };
    //     var response = await this.apiClient.post("/flagVideo", data);
    //     if (response.status === 200) {
    //         this.setState({ showPopup: false, showConfirmDialog: true });
    //         if (this.props.explorePage) {
    //             this.props.updateVideoList(this.props.isFluent, this.props.currentPage, this.props.searchValue);
    //         } else {
    //             this.props.updateVideoList(this.props.currentPage, this.props.searchValue, this.props.userId);
    //         }
    //     }
    //     else {
    //         alert("Unable to flag video");
    //     }
    // };

    render() {
        // var carouselItems = [];
        var consideredVideos = [];

        if (this.state.recordVideos.length > 0) {
            consideredVideos = this.state.recordVideos.filter((uv) => !uv.completed);
        }

        var carouselItems = consideredVideos.map((uv, i) => {
            return (
                <React.Fragment>
                    {(i !== 0) &&
                        <div className="carousel-column">
                            <div className="carousel-item-rest ci">
                                {this.state.showVideoLabel && uv.name}
                                {!this.state.showVideoLabel &&
                                    <PlayCircleFilledWhiteIcon style={{ color: "#697777", background: "white", borderRadius: "50%" }} />
                                }

                            </div>
                        </div>
                    }
                    {(i === 0) &&
                        (
                            <div className="col-first-c">
                                <div className="carousel-item-first  ci">
                                    <PlayCircleFilledWhiteIcon style={{ color: "#127BC2", background: "white", borderRadius: "50%" }} />
                                    <span className="name-cont">{this.state.showVideoLabel && uv.name}</span>
                                </div>
                            </div>
                        )
                    }
                </React.Fragment>


            )
        });


        const { stayOpen } = this.state;
        if (this.state.video === null) return null
        document.title = 'Record - The ASL Data Community';
        const showVideoLabel = this.state.videoChoice === "myChoiceVideo" ? true : false;
        if (!this.props.loggedInUser) {
            return (
                <div>
                    <div style={loaderStyle}>
                        Please login to access this view.
                    </div>
                </div>
            )
        }
        if (!this.state.video) {
            return (
                <div style={loaderStyle}>
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                    Loading recording view...
                </div>
            )
        }
        else {
            return (

                <React.Fragment>
                    <DisappearNotification height="250px" background="#fff" isTransparent={false} symbol="check-mark" text="You have completed recording this list, you can go again or view your new videos from your profile." showInvisibleModal={this.state.showInvisibleModal}></DisappearNotification>
                    <div className="no-header-route-top">
                        <div className="section-top-row" >
                            <div className="description-second lower-col-1" style={{ width: "50%" }}>
                                When you're ready, please click "Record" to record yourself executing the sign.
                            </div>
                            <div className="lower-col-2" style={{ width: "30%" }}>
                                <div className="num-recordings-label"><VideocamRoundedIcon className="video-record-icon" />
                                    This vocabulary list has {this.state.videosLeft} words left</div>
                            </div>
                            <div className="lower-col-3">
                                {this.state.showVideoLabel && <div onClick={this.hideLabel} className="show-label-cont">
                                    <VisibilityOffIcon style={{ marginRight: "5px", color: "#127BC2", marginBottom: "3px" }} />
                                    <span className="show-label" >Hide Label</span>
                                </div>}
                                {!this.state.showVideoLabel && <div onClick={this.showLabel} className="show-label-cont">
                                    <VisibilityIcon style={{ marginRight: "5px", color: "#127BC2", marginBottom: "3px" }} />
                                    <span className="show-label" >Show Label</span>
                                </div>}
                                <div className="having-trouble" style={{ position: "absolute", right: "30px", top: "5px" }}>
                                    <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbRx1qYFM3N0FCo6HgdDr2DB1UMkJZRTFWSzJJR0VNSjBYU05CQldRTldWWi4u">Having trouble?</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: this.state.recordMode ? null : 'none' }}>
                        <div sm="12" className="pt-2 pb-2 record-yourself">
                            <h1 className="clipped">Record yourself signing the word {this.state.video.name}</h1>
                            <div className="text-center mt-4">
                                <div>
                                    <div xs="4">
                                        {(this.state.showhideLabel) && <div><h2 style={this.displayhtml} className="h3">{this.state.video.name}</h2><a style={this.displayhtml} href="#" data-for="userLevelTip" data-tip ></a>
                                            <ReactTooltip id="userLevelTip">
                                                <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                            </ReactTooltip>
                                        </div>}
                                    </div>
                                    <div xs="4">
                                        {/* show only for random video type */}
                                        {/* {(this.state.showLabelButton && !showVideoLabel) && <div>
                                        <button className="btn btn-primary mb-2" onClick={this.showLabel}>Show Label</button>
                                </div>}
                                {(this.state.hideLabelButton && !showVideoLabel) && <div>
                                        <button className="btn btn-primary mb-2" onClick={this.hideLabel}>Hide Label</button>
                                </div>} */}
                                    </div>
                                </div>

                                <WebRecorder setRef={this.setRef} video={this.video} incrementRecordedCount={this.incrementRecordedCount}
                                    clearPrevVideo={this.clearPrevVideo}
                                    vocabUserVideoId={this.state.video.modelVideo ? this.state.video._id.toString() : undefined}
                                    modelVideoId={this.state.video.modelVideo ? this.state.video.modelVideo : this.state.video._id.toString()}
                                    initializeCamera={this.initializeCamera} showVideoLabel={this.state.showVideoLabel}
                                    videoName={this.state.video.name} changeRecordState={this.revertToOriginalState}
                                    signedInGroupId={this.state.signedInGroupId}
                                    updateUserLevel={this.state.updateUserLevel} history={this.props.history}
                                    video={this.state.video} userName={this.props.userName}
                                    userid={this.props.loggedInUser} newVideo={this.props.newVideo}
                                    videoChoice={this.state.videoChoice} stopRecordingCallback={this.stopRecordingCallback}
                                    clearVideoOptions={this.clearVideoOptions} recordingStart={this.recordingStart}
                                />
                            </div>
                        </div>
                    </div>

                    {!this.state.recordMode &&

                        <React.Fragment>

                            <div role="main">

                                {this.state.showbuttons && <div>
                                    <p style={{ paddingBottom: "75px" }}>Please click to play any random video or a video of your choice.</p>
                                    <div>
                                        <div xs="3" className="pt-2">
                                        </div>
                                        <div xs="2" className="pt-2">
                                            <button style={this.btnStyling} className="btn btn-primary mt-3 mr-2" onClick={this.playRandomVideo}>RANDOM</button>
                                        </div>
                                        <div xs="1" className="pt-2">
                                            <p className="mt-3 mr-2 pt-2">OR</p>
                                        </div>
                                        <div xs="2" className="pt-2">
                                            <button style={this.btnStyling} className="btn btn-primary mt-3 mr-2" onClick={this.playMyChoiceVideo}>MY CHOICE</button>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(this.props.userLevel && this.props.userLevel.record && this.state.showSelectDropdown) && <div>
                                    <div>
                                        <div xs="5" style={this.displayhtml}>
                                            <Select
                                                closeOnSelect={!stayOpen}
                                                onChange={this.handleChange}
                                                value={this.state.selectedOption}
                                                options={this.state.searchOptionsList}
                                                styles={this.selectStyles}
                                            />
                                        </div>
                                        <span style={this.displayhtml} className="mar-left-10"><button className="btn btn-primary mr-2" onClick={this.getSelectedVideo}><Octicon icon={Search} size="medium" className="height-125rem" /></button></span>
                                    </div>
                                    <div>
                                        <p style={this.marginLeft} className="errorMsg">{this.state.requiredVideoValue}</p>
                                    </div>
                                </div>
                                }


                                {this.state.showVideoState && <div>
                                    <BlockUi loader={<span></span>} tag='div' blocking={!this.props.userLevel.record} message="Please login to your account to record videos." style={{ width: "100%" }} className="text-center">
                                        <div aria-hidden={!this.props.userLevel.record} >

                                            <div className="video-container-r" style={{ position: "relative", width: "650px" }}>
                                                <div className="carousel" style={{ width: "650px" }} >
                                                    {carouselItems}
                                                    {/* <div className="carousel-right-boundary" onClick={() => this.skip()}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.46967 4.21967C8.17678 4.51256 8.17678 4.98744 8.46967 5.28033L15.1893 12L8.46967 18.7197C8.17678 19.0126 8.17678 19.4874 8.46967 19.7803C8.76256 20.0732 9.23744 20.0732 9.53033 19.7803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L9.53033 4.21967C9.23744 3.92678 8.76256 3.92678 8.46967 4.21967Z" fill="#212121" />
                                                        </svg>
                                                    </div> */}
                                                </div>
                                                <div className="video-background" style={{ position: "relative" }}>
                                                    {/* {((this.state.video.avertJobId && this.state.video.avertJobStatus === "InProgress") || !this.state.video.verifiedCVS) &&
                                                        <div className="video-warning">
                                                            <span>
                                                                WARNING: This video is currently under review.
                                                      
                                                            </span>
                                                        </div>
                                                    } */}
                                                    <video className="video-r" muted key={this.state.video.convertedUrl} controls autoPlay loop preload='auto' >
                                                        <track id="enTrack" src="/videos/cc.vtt" label="English" kind="subtitles" srcLang="en" />
                                                        {/* eslint-disable-next-line */}
                                                        <source src={disambiguateSASURL(this.state.video.convertedUrl, process.env)} />
                                                        Your browser does not support HTML5 video.
                                                    </video>
                                                </div>

                                            </div>



                                        </div>
                                    </BlockUi>
                                </div>
                                }


                            </div>

                            <div className="video-bottom-dv-r">
                                <div className="r-controls-row">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {/* {this.state.showLabelButton && <span className="show-label" onClick={this.showLabel}>Show Label</span>}
                                        {this.state.hideLabelButton && <span className="show-label" onClick={this.hideLabel}>Hide Label</span>} */}
                                        {(this.state.showVideoLabel || this.state.myChoiceVideo) && <span style={{ marginLeft: "20px" }}
                                            className="reveal-word-display">{this.state.video.name}
                                            <a style={this.displayhtml} href="#" aria-hidden="false" data-for="userLevelTip" data-tip >
                                            </a>
                                            <ReactTooltip id="userLevelTip">
                                                <p>This English gloss is NOT intended to serve as a translation of the sign. It is simply a label to help with referencing.</p>
                                            </ReactTooltip></span>}
                                    </div>
                                    <div className="record-buttons">
                                        <div className="overlay-row record-again-btn-vocab" style={{ marginRight: "105px", height: "30px !important", marginTop: "7px", background: "#7A22D", cursor: "pointer" }} onClick={this.onClick} >
                                            <VideocamRoundedIcon style={{ ...playerRecorderControls, border: "none", paddingBottom: "3px", color: "white" }} />
                                            Record
                                        </div>
                                        {/* <div className="overlay-row" >

                                            <button style={{
                                                marginTop: "7px", marginRight: "30px",
                                                border: "1px solid #7A22D"
                                            }}
                                                className="confirm-btn skip r"
                                                onClick={() => this.skip()}> Skip
                                                </button>

                                        </div> */}
                                        <div style={{ top: "8px", position: "absolute", right: "-5px" }}>
                                            {/* onClick={() => this.onFlagVideo()} */}
                                            <ReportVideoButton skipToNewVideo={() => this.skip()} data={this.state.video} loggedInUserId={this.props.loggedInUser} />
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </React.Fragment>
                    }




                    <Modal show={this.state.showStudyMessage} onHide={this.handleClose} backdrop="static" style={{ top: "30%" }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Thanks for recording!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Congratulations! You have finished recording 60 signs. If you are participating in our user study, please enter the following code in the survey: {this.state.studyCode}</Modal.Body>
                    </Modal>
                </React.Fragment>


            )
        }
    }
}

RecordContainer.propTypes = {
    history: PropTypes.object,
    video: PropTypes.object,
    newVideo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const Record = connect(
    
    mapDispatchToProps
)(RecordContainer);

export default Record;
