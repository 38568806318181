import { connect } from 'react-redux'
import PointsTotal from './PointsTotal'

const mapStateToProps = state => {
  return({
    user: state.populateStore.user,
    userXPIncrement: (state.incrementXP.userXPIncrement)
  })
}

export default connect(mapStateToProps)(PointsTotal)