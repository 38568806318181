import React from "react";

class ViewBlocker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        if (!this.props.isDisplayBlocked) {
            return null;
        }
        else {
            return (
                // <div className="view-blocker-background" style={{height: "100vh"}}>
                    <div className="view-blocker-modal">
                        <div className="close-modal">X</div>
                        <div className="blocker-text">You have to be signed in to record videos.</div>
                        <div className="login-btn bmodal">
                            <div onClick={() => this.props.history.push("/tpLogin")} aria-label="Login/Signup Link">
                                Sign in
                            </div>
                        </div>
                    </div>
                // </div>
            )                   
        }
       
    }
}

export default ViewBlocker
